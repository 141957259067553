import { useEffect, useMemo } from "react";
import { useLazyQuery } from "@apollo/client";
import { gqlGetWaitList } from "../../../apollo/operations/waitList";

const useGetWaitList = ({ pageSize, page }) => {
  const [getWaitList, { data, loading, error }] = useLazyQuery(gqlGetWaitList);
  useEffect(() => {
    getWaitList({
      variables: {
        waitListRequest: {
          filter: {
            status: "ALL",
          },
          pagination: {
            limit: pageSize,
            offset: page * pageSize,
          },
        },
      },
    });
  }, [pageSize, page]);
  const waitListData = useMemo(() => data?.getWaitList?.data, [loading]);

  return { waitListData };
};

export default useGetWaitList;
