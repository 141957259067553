import { gql } from "@apollo/client";

export const addFutureScan = gql`
  mutation AddFutureScanDetails($emailList: [FutureScanEmailList]) {
    addFutureScanDetails(emailList: $emailList) {
      data
      error
      message
      statusCode
    }
  }
`;

export const getFutureScanDetails = gql`
  query GetFutureScanDetails {
    getFutureScanDetails {
      statusCode
      message
      error
      data
    }
  }
`;
