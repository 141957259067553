import { makeStyles } from "@material-ui/styles";
import {
  largeTabletMediaStyle,
  mobileMediaStyle,
  tabMediaStyle,
  smallTabMediaStyle,
  smallMobileMediaStyle,
  largerThanTabletMediaStyle,
} from "../../components/helpers/ResponsiveStyleHelper";
import ContactUsImage from "../../assets/images/faqWallpaper.png";

export const useStyles = makeStyles({
  plusIcon: { "& > svg >path": { stroke: "black" } },

  contactUsDiv: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-around",
    justifyContent: "center",
    columnGap: "30px",
    margin: "5vh 0vw",
    // height: "75vh",
  },
  rightSection: {
    display: "flex",
    flexDirection: "column",
    width: "45vw",
  },

  imageDiv: {
    backgroundImage: `url(${ContactUsImage})`,
    backgroundSize: "100% 100%",
    // height: "100%",
    width: "35vw",
    maxWidth: "100%",
    maxHeight: "100%",
    backgroundPosition: "center",
    boxShadow: "black 0px 0px 3px"
  },

  ...tabMediaStyle({
    contactUsDiv: {
      display: "grid",
      flexDirection: "column",
      justifyContent: "center",
      margin: "30px 0",
      height: "unset",
    },
    subContactUsDiv: {
      display: "grid",
      flexDirection: "column",
      justifyContent: "center",
    },
    contactText: {
      margin: "15px 0 ",
      // width: "70vw",
    },
    formCard: {
      // margin: "80px"
      marginTop: "25px",
    },
    imageDiv: {
      // margin: "0 40px",
      width: "unset",
      height: "50vh",
    },
    rightSection: {
      width: "unset",
    },
  }),
  ...smallTabMediaStyle({
    contactText: {
      fontSize: "14px",
      width: "unset",
      margin: "10px 20px",
    },
    title: {
      fontSize: "20px",
    },
    formCard: {
      margin: "20px",
    },
    imageDiv: {
      margin: "0 20px",
    },
  }),
  ...mobileMediaStyle({
    contactUsDiv: {
      margin: "0px",
    },
    formCard: {
      margin: "20px",
    },
    contactText: {
      fontSize: "14px",
      width: "unset",
      margin: "10px 20px",
    },
    imageDiv: {
      margin: "16px 20px",
    },
    title: {
      marginTop: "10px",
    },
  }),
  ...smallMobileMediaStyle({
    title: {
      fontSize: "15px",
    },
    contactText: {
      fontSize: "14px",
      width: "unset",
    },
    formCard: {
      margin: "20px 0px",
    },
  }),
});
