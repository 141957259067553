import { makeStyles } from "@material-ui/styles";
import {
  tabMediaStyle,
  smallTabMediaStyle,
  mobileMediaStyle,
} from "../../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  videoTourTiltle: { paddingBottom: "10px" },
  videoTourBox: { display: "grid", gridRow: "2px" },
  videoTourSubTiltle: {
    fontSize: "16px",
    fontFamily: "var(--primaryHeaderFont)",
    fontWeight: "700",
    lineHeight: "20px",
    letterSpacing: "0.48px",
    paddingBottom: "5px",
    paddingTop: "8px",
  },
  videoTourText: {
    fontWeight: 300,
    letterSpacing: "0.48px",
    paddingBottom: "5px",
  },
  uploadFileBody: {
    width: "100%",
    padding: "30px 60px",
    boxSizing: "border-box",
    fontFamily: "var(--primaryRegularFont)",
  },

  uploadFileHeader: {
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "28px",
    paddingBottom: "25px",
  },

  uploadImageBody: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "40px",
  },
  uploadSection: {
    width: "50%",
    "&, &:after": {
      content: "",
      paddingBottom: "50%",
      position: "relative",
    },
  },

  parentImagesContainer: {
    width: "100%",
    "&, &:after": {
      content: "",
      paddingBottom: "calc( 24.5% + 116px )",
      position: "relative",
    },
  },

  uploadImageSection: {
    width: "32%",
  },

  uploadImageContainer: {
    height: "100%",
    width: "100%",
    border: "1px solid #000000",
    borderRadius: "5px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    position: "absolute",
  },

  icon: {
    "& > svg": {
      height: "60px",
      width: "60px",
    },
  },

  uploadUrlWrapper: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #000000",
    borderRadius: "10px",
  },

  uploadUrl: {
    height: "52px",
    width: "100%",
    borderRight: "1px solid #000000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
  },

  goButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    height: "50px",
    width: "50px",
    cursor: "pointer",
    transform: "rotate(180deg)",
    "& > svg": {
      height: "35px",
      width: "35px",
      "& > path": {
        stroke: "#FB5B01",
      },
    },
  },
  previewImageWrapper: {
    position: "absolute",
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
    border: "1px solid black",
    borderRadius: "5px",
  },

  previewImage: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
    boxSizing: "border-box",
    borderRadius: "5px",
  },

  inputUrl: {
    border: "none",
    outline: "none",
    width: "100%",
    height: "100%",
    fontSize: "20px",
    lineHeight: "20px",
    padding: "0 5px 0 18px",
    borderRadius: "10px",
    textOverflow: "ellipsis",
  },

  text: {
    margin: "20px 0",
    paddingLeft: "calc( 25% - 10px )",
    width: "100%",
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: "400",
    letterSpacing: "0.42px",
    boxSizing: "border-box",
  },

  label: {
    height: "60px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: "400",
  },

  detailsBody: {
    display: "grid",
    columnGap: "2%",
    gridTemplateColumns: "repeat(3, 1fr)",
  },

  inputLabel: {
    fontFamily: "var(--primaryRegularFont)",
    fontSize: "16px",
    marginBottom: "8px",
    "& > span": {
      color: "#ff0000",
      fontWeight: "bold",
    },
    "& > div > span": {
      color: "#ff0000",
      fontWeight: "bold",
    },
  },

  inputWrapper: {
    height: "54px",
    width: "100%",
    border: "1px solid #000000",
    marginBottom: "16px",
    boxSizing: "border-box",
    borderRadius: "10px",
  },

  textInput: {
    border: "none",
    outline: "none",
    width: "100%",
    height: "100%",
    fontSize: "18px",
    fontFamily: "var(--primaryRegularFont)",
    padding: "0 10px 0 18px",
    borderRadius: "inherit",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    boxSizing: "border-box",
  },

  selectInputWrapper: {
    height: "54px",
    width: "100%",
    border: "1px solid #000000",
    marginBottom: "16px",
    boxSizing: "border-box",
    borderRadius: "10px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },

    "& .MuiInputBase-formControl": {
      fontSize: "18px !important",
    },

    "& .MuiOutlinedInput-input": {
      padding: "13.5px 18px !important",
    },
  },

  buttonContainer: {
    width: "100%",
    paddingTop: "25px",
    display: "flex",
    justifyContent: "end",
  },

  button: {
    width: "137px",
    height: "56px",
    color: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "20px",
    lineHeight: "23px",
    cursor: "pointer",
    borderRadius: "6px",
  },

  enabledButton: {
    backgroundColor: "#FB5B01",
  },

  disabledButton: {
    backgroundColor: "#CACACA",
  },

  infoContainer: {
    display: "flex",
    justifyContent: "space-between",
    "& > a > i > svg": {
      height: "20px",
      width: "20px",
    },
  },

  imagesOptions: {
    width: "66%",
  },

  imagesContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    overflowY: "auto",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
    flexWrap: "wrap",
    gap: "10px",
    padding: "5px",
    boxSizing: "border-box",

    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#c1c1c1",
      borderRadius: "10px",
    },
  },

  imageFrame: {
    height: "160px",
    width: "100%",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  selectedFrame: {
    boxShadow: "0 0 4px #798bff",
  },

  normalFrame: {
    boxShadow: "0 0 3px #b9b9b9",
  },

  images: {
    maxHeight: "225px",
    height: "100%",
    minHeight: "144px",
    width: "100%",
    objectFit: "contain",
  },

  labelText: {
    width: "100%",
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    fontSize: "24px",
    lineHeight: "26px",
    fontWeight: "400",
    paddingBottom: "25px",
  },

  sliderContainer: {
    "& .slick-slide": {
      "& > div": {
        width: "64px !important",
      },
    },
    "& .slick-slider": {
      transform: "translate3d(28px,0,0)",
    },
    "& .slick-list": {
      height: "98px !important",
      width: "64px !important",
    },
    "& .slick-arrow::before": {
      fontSize: "18px",
      color: "#000",
    },
    "& .slick-next": {
      right: "6px !important",
    },
    "& .slick-prev": {
      left: "8px !important",
    },
  },

  sliderwrapper: {
    display: "none",
    width: "150px",
    margin: "0 auto",

    "& .slick-slider": {
      display: "flex",
      justifyContent: "center",
    },
  },

  infoWrapper: {
    display: "flex",
    justifyContent: "space-between",
    "& > a > i > svg": {
      height: "20px",
      width: "20px",
    },
  },

  additionalInfoText: {
    color: "#062EFF",
    fontFamily: "var(--primaryRegularFont)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "20px",
    letterSpacing: "0.48px",
    marginBottom: "25px",
  },

  datePicker: {
    width: "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "& .MuiInputBase-input": {
      padding: "14.5px 15px !important",
    },
  },

  ...tabMediaStyle({
    parentImagesContainer: {
      "&, &:after": {
        paddingBottom: "calc( 50% + 104px )",
      },
    },
    imagesOptions: {
      width: "49%",
    },
    imagesContainer: {
      gap: "6px",
      gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
    },
    uploadFileBody: {
      padding: "30px 40px",
    },
    uploadImageBody: {
      marginBottom: "25px",
    },
    uploadImageSection: {
      width: "49%",
    },
    text: {
      fontSize: "16px",
      lineHeight: "20px",
      margin: "15px 0",
    },
    detailsBody: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    inputUrl: {
      padding: "0 5px 0 15px",
      fontSize: "18px",
    },
    textInput: {
      fontWeight: "400",
      padding: "0 10px 0 15px",
    },
    goButton: {
      height: "36px",
      width: "36px",
      "& > svg": {
        height: "26px",
        width: "26px",
        "& > path": {
          stroke: "#FB5B01",
        },
      },
    },
  }),

  ...smallTabMediaStyle({
    imagesOptions: {
      height: "214px",
      marginLeft: "0px",
    },
    imagesContainer: {
      gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
    },
    uploadFileBody: {
      padding: "24px 28px",
    },
    uploadImageSection: {
      width: "48%",
    },
    icon: {
      "& > svg": {
        height: "30px",
        width: "30px",
      },
    },
    label: {
      fontSize: "16px",
    },
    inputUrl: {
      padding: "0 5px 0 12px",
      fontSize: "16px",
    },
  }),

  ...mobileMediaStyle({
    datePicker: {
      "& .MuiInputBase-input": {
        fontSize: "14px",
        padding: "12.8px 10px 12.8px 12px !important",
      },
    },
    imagesOptions: {
      width: "40%",
    },
    imagesContainer: {
      display: "none",
    },
    uploadFileBody: {
      padding: "24px 16px 28px",
    },
    uploadFileHeader: {
      fontSize: "16px",
      lineHeight: "19px",
      letterSpacing: "0.48px",
      paddingBottom: "16px",
    },
    uploadImageSection: {
      width: "190px",
    },
    uploadImageBody: {
      marginBottom: "20px",
    },
    icon: {},
    label: {
      fontSize: "14px",
      lineHeight: "16px",
      height: "36px",
      width: "90%",
    },
    text: {
      fontSize: "14px",
      lineHeight: "16px",
    },
    uploadUrl: {
      height: "36px",
    },
    detailsBody: {
      gridTemplateColumns: "1fr",
    },
    inputWrapper: {
      height: "48px",
      marginBottom: "12px",
    },
    inputUrl: {
      fontSize: "14px",
      padding: "0 5px 0 12px",
    },
    textInput: {
      fontSize: "14px",
      fontWeight: "400",
      padding: "0 10px 0 12px",
    },
    additionalInfoText: {
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.2px",
      marginBottom: "16px",
    },
    inputLabel: {
      fontSize: "14px",
      marginBottom: "5px",
    },
    selectInputWrapper: {
      height: "48px",
      marginBottom: "12px",

      "& .MuiInputBase-formControl": {
        fontSize: "14px !important",
        fontWeight: "400 !important",
      },

      "& .MuiOutlinedInput-input": {
        padding: "14.5px 12px !important",
      },
    },
    button: {
      height: "36px",
      width: "98px",
      fontSize: "12px",
      lineHeight: "14px",
      letterSpacing: "0.36px",
    },
    infoContainer: {
      "& > a > i > svg": {
        height: "16px",
        width: "16px",
      },
    },
    imageFrame: {
      height: "174px",
      minHeight: "150px",
    },
    images: {
      height: "98px",
      width: "65px",
    },
    labelText: {
      fontSize: "16px",
      lineHeight: "18px",
      paddingBottom: "16px",
      justifyContent: "center",
    },
    sliderwrapper: {
      display: "block",
    },
    infoWrapper: {
      "& > a > i > svg": {
        height: "16px",
        width: "16px",
      },
    },
  }),
});
