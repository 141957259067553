import { useStyles } from "./styles";
import { ReactComponent as NoItemsIcon } from "../../assets/svg/noItemIcon.svg";
export default function EmptyList(props) {
  const classes = useStyles();

  return (
    <div className={classes.errorContainer}>
      <NoItemsIcon />
      <div className={classes.errorMsg}>Sorry! No {props.title} Found Here</div>
    </div>
  );
}
