import { gql } from "@apollo/client";

export const getReceipts = gql`
query GetInboxMails($inboxRequest: inboxList!) {
    getInboxMails(inboxRequest: $inboxRequest) {
      statusCode
      message
      error
      data {
        mails {
          id
          messageId
          sourceEmail
          destinationEmail
          createdAt
          updatedAt
          sourceName
          subject
          storeName
        }
        count
      }
    }
  }
`;

export const getSearchMails = gql`
query GetSearchMails($inboxSearchRequest: searchList!) {
  getSearchMails(searchRequest: $inboxSearchRequest) {
    statusCode
      message
      error
      data {
        suggestion
        mails {
          id
          createdAt
          subject
          storeName
          messageId
        }
      }
    }
  }
`;

export const getReceiptContent = gql`
  query Query($messageId: String!) {
    getSingleMail(messageId: $messageId) {
      statusCode
      message
      error
      data {
        from
        to
        date
        subject
        html
      }
    }
  }
`;

export const deleteMails = gql`
  mutation DeleteMails($messageIds: [String]) {
    deleteMails(messageIds: $messageIds) {
      statusCode
      message
      error
    }
  }
`;
