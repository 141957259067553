import { useEffect, useState } from "react";
import { useStyles } from "./styles";
import Toggle from "../../../components/fields/toggle";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getNotificationTypes,
  addOrUpdateNotificationType,
} from "../../../apollo/operations/notificationTypes";
export default function NotificationSetting() {
  const classes = useStyles();
  const [notificationDetails, setNotificationDetails] = useState([]);
  const [createNotiType, setCreateNotiType] = useState(false)
  const [getNotificationTypesApi, { data: notificationTypesData }] =
    useLazyQuery(getNotificationTypes);
  const [addOrUpdateNotificationTypeApi, {data: notiTypeUpdateResponse}] = useMutation(
    addOrUpdateNotificationType,
  );
  const notificationType = [
    {
      name: "Likes",
      value: "likes",
      description:
        "These are the notifications when someone like your Sub Categories, Items or Curations",
      isActive: true,
    },
    {
      name: "Reminders",
      value: "reminders",
      description:
        "These are the notifications to remind you of sharing expiration date of the items you shared with someone else",
      isActive: true,
    },
    {
      name: "Tags",
      value: "tags",
      description: "These are the notifications when someone uses your created tags",
      isActive: true,
    },
    {
      name: "Adds & Remove",
      value: "adds",
      description:
        "These are the notifications you’ll receive when you add or remove your items through any method i.e. by scanning, uploading etc",
      isActive: true,
    },
    {
      name: "Picks",
      value: "picks",
      description:
        "These are the notifications you’ll receive when someone picked the dress from your closet",
      isActive: true,
    },
    {
      name: "Shares",
      value: "shares",
      description:
        "These are the notifications when someone else shares his/her closet, Items or curations with you",
      isActive: true,
    },
  ];

  useEffect(() => {
    getNotificationTypesApi();
  }, []);

  useEffect(() => {
    if(notiTypeUpdateResponse && createNotiType){
      getNotificationTypesApi()
      setCreateNotiType(false);
    }
  }, [notiTypeUpdateResponse]),

  useEffect(() => {
    if (
      notificationTypesData &&
      notificationTypesData.getNotificationTypes &&
      notificationTypesData.getNotificationTypes.data &&
      notificationTypesData.getNotificationTypes.data.length
    ) {
      const notificationData = notificationType.map((item) => {
        if (
          Object.hasOwnProperty.call(
            notificationTypesData.getNotificationTypes.data[0],
            item.value,
          )
        ) {
          item.isActive =
            notificationTypesData.getNotificationTypes.data[0][item.value];
        }
        return item;
      });
      setNotificationDetails(notificationData);
    }
     else if(notificationTypesData?.getNotificationTypes?.data === null) {
      // add the notification-type object
      addOrUpdateNotificationTypeApi({
        variables: {},
      })
      setCreateNotiType(true)
    }
  }, [notificationTypesData]);
  function handleToggle(item, index) {
    item.isActive = !item.isActive;
    let newNotification = JSON.parse(JSON.stringify(notificationDetails));
    newNotification[index] = item;
    let notificationTypeData = {};
    notificationTypeData[item.value] = item.isActive;
    addOrUpdateNotificationTypeApi({
      variables: {
        notificationTypeData,
      },
    });
    setNotificationDetails(newNotification);
  }

  return (
    <>
      <div className={classes.notificationCategoryHeader} />
      <div className={classes.notificationContainer}>
        {notificationDetails.map((item, index) => (
          <div key={`notification-${index}`}>
            <div className={classes.notificationHeading}>
              {item.name}
              <Toggle onClick={() => handleToggle(item, index)} active={item.isActive} />
            </div>
            <p className={classes.notificationDescription}>{item.description}</p>
          </div>
        ))}
      </div>
    </>
  );
}
