import styled from "styled-components";

import globalStyles from "../../styles/global";

const { colors } = globalStyles;

const RadioGroup = styled.div`
  font-family: var(--primaryRegularFont);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.03em;
  color: #000000;
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 10px;
  > div {
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;
      column-gap: 12px;

      > input[type="radio"] {
        border: none;
        width: 18px;
        height: 18px;
        cursor: pointer;
        appearance: none;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('data:image/svg+xml, <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="7.5" stroke="rgb(122, 122, 122)"/></svg>');

        &:checked {
          background-image: url('data:image/svg+xml, <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="7.5" stroke="black"/><circle cx="8" cy="8" r="5" fill="black"/></svg>');
        }
      }

      label {
        cursor: pointer;
      }

      > input:not(:checked) + label {
        color: ${colors.grey.regular};
      }
    }
  }
`;

export default function Radio({ className, labelName, onChange, id, value, options = [] }) {
  return (
    <RadioGroup className={className}>
      {labelName && <p>{labelName}</p>}
      <div>
        {Array.isArray(options) &&
          options.map((option, index) => (
            <div key={index}>
              <input
                type="radio"
                id={id + option.value}
                name={id}
                value={option.value}
                checked={option.value === value}
                onChange={onChange}
              />
              <label htmlFor={id + option.value}>{option.name}</label>
            </div>
          ))}
      </div>
    </RadioGroup>
  );
}
