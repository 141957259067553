import { useNavigate } from "react-router-dom";
import AdminDashboard from "./AdminDashboard";
import ChartBar from "./Charts/ChartBar";
import ChartPie from "./Charts/ChartPie";
import useGetChartData from "./hooks/useGetChartData";
import { useEffect } from "react";

const AdminPage = () => {
  const { ageRangeCountRes, genderCountRes, loading, error, refetch } = useGetChartData();
  const navigate = useNavigate();
  const isAdmin = localStorage.getItem("admUser");
  if (!isAdmin) {
    navigate("/admin");
  }

  const handleLogOut = () => {
    localStorage.removeItem("admUser");
    navigate("/admin");
  };

  console.log(ageRangeCountRes, genderCountRes, loading, error);

  return (
    <div>
      <div style={{ padding: "10px", display: "flex", justifyContent: "end" }}>
        <button style={{ padding: "5px", cursor: "pointer" }} onClick={handleLogOut}>
          LogOut
        </button>
      </div>
      <div style={{ display: "flex" }}>
        <ChartBar ageData={ageRangeCountRes} />
        <ChartPie genderData={genderCountRes} />
      </div>

      <AdminDashboard />
    </div>
  );
};

export default AdminPage;
