import { FormControl, MenuItem, Select } from "@mui/material";
import { useStyles } from "./styles";
import { NestedMenuItem } from "mui-nested-menu";
import { getCategoryDropDownList } from "../helpers/categoryHelper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useEffect, useRef, useState } from "react";

export default function CategoriesDropDown(props) {
  const ref = useRef(null);
  const { selectedCategory, handleOpen, handleClose, open, categoriesList, handleMenuClick } =
    props;
  const updatedCategoriesList =
    getCategoryDropDownList(categoriesList)?.filter((ele) => !ele.hidden) || [];
  const [isHiddenItems, setIsHiddenItems] = useState(false);
  const classes = useStyles();

  function loadMoreItems(e) {
    const bottom = e?.target?.scrollHeight === e?.target?.scrollTop + e?.target?.clientHeight;
    setIsHiddenItems(!bottom);
  }

  useEffect(() => {
    if (open)
      setIsHiddenItems(window?.innerHeight <= 800 ? true : window?.innerWidth < 800 ? true : false);
  }, [open]);

  useEffect(() => {
    const handleClick = (event) => {
      if (event?.target?.id === "scrollDown") {
        const element = document.getElementById("paperScroll");
        element.scrollTop = element.scrollHeight;
      }
    };

    document?.addEventListener("click", handleClick);
  });

  return (
    <FormControl fullWidth>
      <Select
        value={selectedCategory}
        open={open}
        onOpen={(event) => {
          handleOpen(event);
        }}
        onClose={handleClose}
        MenuProps={{
          classes: { list: classes.list },
          PaperProps: {
            onScroll: loadMoreItems,
            id: "paperScroll",
          },
        }}
      >
        {updatedCategoriesList?.map((category, index) =>
          category.subCategories && category.subCategories.length ? (
            <NestedMenuItem
              key={index}
              label={category.name}
              parentMenuOpen={open}
              onClick={() => handleMenuClick(category.value)}
            >
              {category.subCategories.map((subCategory, subIndex) =>
                subCategory.subCategories ? (
                  <NestedMenuItem
                    key={"subIndex" + subIndex}
                    label={subCategory.name}
                    parentMenuOpen={open}
                  >
                    {subCategory.subCategories.map((nestedSubCat, nestedIndex) => (
                      <MenuItem
                        key={"subIndex" + subIndex + "nestedIndex" + nestedIndex}
                        value={nestedSubCat.name}
                        date-value={nestedSubCat.name}
                        onClick={() => handleMenuClick(nestedSubCat.value)}
                      >
                        {nestedSubCat.name}
                      </MenuItem>
                    ))}
                  </NestedMenuItem>
                ) : (
                  <MenuItem
                    key={"subIndex" + subIndex}
                    value={subCategory.name}
                    data-value={subCategory.name}
                    onClick={() => handleMenuClick(subCategory.value)}
                  >
                    {subCategory.name}
                  </MenuItem>
                ),
              )}
            </NestedMenuItem>
          ) : (
            <MenuItem
              key={index}
              value={category.name}
              onClick={() => handleMenuClick(category.value)}
            >
              {category.name}
            </MenuItem>
          ),
        )}
        {updatedCategoriesList?.map(
          (category) =>
            category.subCategories &&
            category.subCategories.map(
              (subCategory, subIndex) =>
                subCategory.subCategories &&
                subCategory.subCategories.map((nestedSubCat, nestedIndex) => (
                  <MenuItem
                    key={subIndex + "nestedIndex" + nestedIndex}
                    value={nestedSubCat.value}
                    style={{ display: "none" }}
                  >
                    {nestedSubCat.value}
                  </MenuItem>
                )),
            ),
        )}
        {updatedCategoriesList?.map(
          (category) =>
            category.subCategories && (
              <MenuItem key={category.name} value={category.value} style={{ display: "none" }}>
                {category.value}
              </MenuItem>
            ),
        )}
        {updatedCategoriesList?.map(
          (category) =>
            category.subCategories &&
            category.subCategories.map((subCategory) => (
              <MenuItem
                key={subCategory.name}
                value={subCategory.value}
                style={{ display: "none" }}
              >
                {subCategory.value}
              </MenuItem>
            )),
        )}
        {isHiddenItems ? (
          <div ref={ref} id="scrollDown" className={classes.isHiddenItem}>
            <KeyboardArrowDownIcon id="scrollDown" />
          </div>
        ) : (
          <div> </div>
        )}
      </Select>
    </FormControl>
  );
}
