import { makeStyles } from "@material-ui/styles";
import { mobileMediaStyle, tabMediaStyle } from "../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  table: {
    fontFamily: "var(--secondaryHeaderFont)",

    borderCollapse: "collapse",
    width: "100%",
  },

  tdth: {
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  tr: {
    backgroundColor: "#dddddd",
  },
  arrowLeft: {
    padding: "0px 5px",
    rotate: "90deg",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#D9D9D9",
    },
  },
  arrowRight: {
    padding: "0px 5px",
    rotate: "-90deg",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#D9D9D9",
    },
  },
  paggination: {
    padding: "10px 0 10px",
    justifyContent: "right",
    display: "flex",
    fontSize: ".75rem",
    letterSpacing: "normal",
    color: "#5e5e5e",
  },
  statusFilter: {
    display: "flex",
    justifyContent: "flex-end",
    fontFamily: "var(--secondaryHeaderFont)",
    padding: "10px 0 10px",
    fontSize: ".75rem",
    color: "#5e5e5e",
  },

  ...tabMediaStyle({}),

  ...mobileMediaStyle({}, "@media (max-width:600px)"),
});
