import { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { Link, NavLink, useNavigate } from "react-router-dom";

import globalStyles from "../../styles/global";
import IconComponent from "../fields/icon";
import { useSelector } from "react-redux";
import useNotification from "../../hooks/useNotification";

const { colors, mediaQueries } = globalStyles;

const NavContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;

  @media screen and (${mediaQueries.tablet}) {
    box-shadow: 0 4px 4px 0 rgba(197, 197, 197, 0.25);
  }
`;

const MainNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: ${colors.white};
  padding: 26px 60px;
  position: relative;

  @media screen and (${mediaQueries.tablet}) {
    padding: 12px 20px;
  }

  @media screen and (${mediaQueries.mobile}) {
    padding: 0px 15px;
    height: 56px;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
  }
`;

const Title = styled.div`
  display: flex;
  column-gap: 5px;

  > i {
    display: none;
  }

  > p {
    font-family: var(--primaryHeaderFont);
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    color: ${colors.black.regular};
    letter-spacing: 9px;

    @media (${mediaQueries.mobile}) {
      letter-spacing: 5px;
    }
  }

  @media screen and (${mediaQueries.tablet}) {
    > p {
      font-size: 24px;
      line-height: 32px;
    }

    > i {
      display: flex;
      cursor: pointer;
      > svg {
        width: auto;
        height: 28px;
      }
    }
  }

  @media screen and (${mediaQueries.mobile}) {
    > i {
      > svg {
        height: 16px;
      }
    }
    > p {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

const Options = styled.div`
  display: flex;
  column-gap: 30px;

  .menuItem {
    position: relative;

    > div:first-of-type {
      width: 100%;
    }
  }

  .unread-message {
  }

  @media screen and (${mediaQueries.tablet}) {
    column-gap: 15px;
    svg {
      width: 18px;
      height: 24px;
    }
  }

  @media screen and (${mediaQueries.mobile}) {
    column-gap: 10px;
    svg {
      width: 16px;
    }
  }
`;

const SubMenu = styled.div`
  position: absolute;
  right: 0;
  margin-top: 5px;
  background: #fafafa;
  box-shadow: 0px 4px 4px rgba(198, 198, 198, 0.25);
  padding: 5px 0px;
  width: 220px;
  display: none;
  z-index: 10;

  > div {
    display: flex;
    flex-direction: column;

    > a {
      padding: 8px 16px;
      font-family: var(--primaryRegularFont);
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      text-decoration: none;
      color: ${colors.black.regular};
      letter-spacing: 0.03em;
      :hover {
        font-weight: 400;
        background-color: ${colors.blue.light};
      }
    }

    .line {
      margin: 0px 12px;
      border-top: 1px solid black;
    }
  }
`;

const MobileNav = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  height: 56px;
  background-color: ${colors.white};
  letter-spacing: 0.03em;
  height: 100%;
  width: 290px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: -292px;
  transition: all 0.5s;

  @media screen and (${mediaQueries.tablet}) {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &.active {
      left: 0;
    }
  }
`;

const SubNav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  background-color: ${colors.black.regular};
  letter-spacing: 0.03em;

  @media screen and (${mediaQueries.tablet}) {
    height: 48px;
  }

 

  @media screen and (${mediaQueries.mobile}) {
    height: 36px;
  }
`;

const List = styled.ul`
  display: flex;
  list-style: none;
  column-gap: 50px;
  height: 90%;

  > li {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > a {
      height: 100%;
      display: flex;
      align-items: center;
      text-decoration: none;
      text-transform: uppercase;
      font-family: var(--primaryRegularFont);
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 3px;
      position: relative;
      color: ${colors.white};
      > span {
        color: ${colors.orange.light};
        position: absolute;
        bottom: 3px;
        font-size: 10px;
        line-height: 12px;
        font-weight: 600;
        font-style: italic;
        letter-spacing: 0.2px;
        white-space: nowrap;
        text-transform: none;
        left: 50%;
        transform: translateX(-50%);

        
      }
    }

    .active {
      font-weight: 700;
      color: ${colors.orange.light};
    }
  }

    @media screen and (${mediaQueries.mediumScreen}) {
       column-gap: 40px;
      }
    @media screen and (${mediaQueries.ipadScreen}) {
       column-gap: 29px;
      }


  @media screen and (${mediaQueries.tablet}) {
    column-gap: 25px;

    > li {
      > a {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 2px;
        text-align: center;
        > span {
          font-size: 8px;
          font-weight: 500;
        }
      }
    }
  }


    @media screen and (${mediaQueries.smallIpadScreen}) {
       column-gap: 18px;
       > li {
      > a {
        letter-spacing: 1px;
          }
        }
      }




  @media screen and (${mediaQueries.smallTablet}) {
  column-gap: 15px;
    > li {
      > a {
        letter-spacing: 1px;
        font-size: 10px;
        line-height: 14px;
      }
    }
  }

 @media screen and (${mediaQueries.largerMobile}) {
  column-gap: 15px;
    > li {
      > a {
           letter-spacing: 0.05em;
    font-size: 9px;
    font-weight: 300;
    line-height: 10px;
      }
    }
  }

  @media screen and (${mediaQueries.mobile}) {
    column-gap: 13px;

    > li {
         > a {
        letter-spacing: 0.04em;
        font-size: 8px;
        font-weight: 300;
        line-height: 9px;
        > span {
          font-size: 6px;
          bottom:2px;
        }
      }
      .active {
        font-weight: 500;
      }
    }
  }

    @media screen and (${mediaQueries.smallMobileScreen}) {
    column-gap: 10px;

     > li {
         > a {
        letter-spacing: 0em;
             }
          }
        }
`;

const NestedList = styled.ul`
  display: flex;
  list-style: none;
  column-gap: 50px;

  > li {
    display: none;
    > a {
      text-decoration: none;
      text-transform: uppercase;
      font-family: var(--primaryRegularFont);
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 19px;
      letter-spacing: 3px;
      color: ${colors.white};
    }

    .active {
      font-weight: 700;
      color: ${colors.orange.light};
    }
  }

  .active {
    display: flex;
  }

  @media screen and (${mediaQueries.tablet}) {
    flex-direction: column;
    width: 100%;

    > li {
      border-top: 1px solid #d9d9d9;
      padding: 18px 16px;
      > a {
        font-weight: 300;
        letter-spacing: 0.03em;
        color: ${colors.black.regular};
      }
      .active {
        font-weight: 500;
      }
    }
    .active {
      display: flex;
    }
  }
`;

const Overflow = styled.div`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 20;

  &.active {
    display: block;
  }
`;

const UserHeader = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000000;
  column-gap: 12px;

  > div {
    width: 28px;
    height: 28px;
    border: 1px solid white;
    border-radius: 24px;
  }

  > p {
    font-family: var(--primaryRegularFont);
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #ffffff;
  }

  @media screen and (${mediaQueries.mobile}) {
    height: 56px;
    > div {
      width: 24px;
      height: 24px;
    }
    > p {
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

const menuList = [
  { name: "home", to: "/dashboard" },
  { name: "curate me", to: "/curateme" },
  { name: "add your style", to: "/addyourstyle" },
  { name: "style me", to: "/styleme" },
  { name: "share me", to: "/shareme", notice: "Coming Soon" },
  { name: "sell me", to: "/sellme", notice: "Coming Soon" },
  { name: "vrTRY ME", to: "/vrtryme", notice: "Coming Soon" },
];

const mobileMenuList = [
  { name: "home", id: "home", to: "/dashboard" },
  { name: "curate me", id: "curateMe", to: "/curateme" },
  {
    name: "add your style",
    id: "addYourStyle",
    to: "/addyourstyle",
    subMenu: [
      { name: "Scan Email", to: "/addyourstyle/scanemail" },
      { name: "Upload File", to: "/addyourstyle/uploadfile" },
      { name: "Scan UPC", to: "/addyourstyle/scanupccode" },
    ],
  },
  { name: "style me", id: "styleMe", to: "/styleme" },
  { name: "share me", id: "shareMe", to: "/shareme" },
  { name: "sell me", id: "sellMe", to: "/sellme" },
  { name: "vrTRY me", to: "/vrtryme", notice: "Coming Soon" },
];

const Navbar = () => {
  const navigate = useNavigate();
  const handleOnClick = (event) => {
    handleOpenProfileIconMenu(event);
  };
  const { fetchNotifications } = useNotification();
  const [newNotificationCount, setNewNotificationCount] = useState(0);
  const { unreadCount, notifications } = useSelector((state) => state.socket);

  useEffect(
    () => () => {
      window.removeEventListener("click", handleOnClick);
    },
    [],
  );

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    setNewNotificationCount(unreadCount + notifications.length);
  }, [unreadCount, notifications]);

  window.addEventListener("click", handleOnClick);

  const handleOnHamClick = () => {
    const section = document.getElementById("mainSectionContainer").classList;
    const mobileNav = document.getElementById("mobileSideNavBar").classList;
    const overflowContainer = document.getElementById("overflowContainer").classList;

    if (section.contains("active")) {
      section.remove("active");
      mobileNav.remove("active");
      overflowContainer.remove("active");
    } else {
      section.add("active");
      mobileNav.add("active");
      overflowContainer.add("active");
    }
  };

  const handleOpenProfileIconMenu = (event) => {
    const profileIconMenu = document.getElementById("profileIconMenu");
    if (profileIconMenu && profileIconMenu.style.display === "block") {
      const popUp = event.target.closest("#profileIconMenu");
      !popUp && (profileIconMenu.style.display = "none");
    }
  };

  const handleProfileIconClick = (event) => {
    event.stopPropagation();
    const profileIconMenu = document.getElementById("profileIconMenu");
    profileIconMenu.style.display = "block";
  };

  const handleNotificationClick = (event) => {
    event.stopPropagation();
    navigate("/notifications");
  };

  const handleSubmenuSelect = (event) => {
    event.stopPropagation();
    const profileIconMenu = document.getElementById("profileIconMenu");
    profileIconMenu.style.display = "none";
  };
  function handleFavoriteClick(event) {
    event.stopPropagation();
    navigate("/favorites");
  }

  return (
    <>
      <NavContainer>
        <MainNav>
          <Title>
            <p>MIKLOSET</p>
          </Title>
          <Options>
            <div>
              <IconComponent iconTitle={"SearchLightIcon"} />
            </div>
            <div>
              <IconComponent iconTitle={"HeartLightIcon"} onClick={handleFavoriteClick} />
            </div>
            <div>
              <IconComponent iconTitle={"BagLightIcon"} />
            </div>
            <div>
              <IconComponent
                iconTitle={newNotificationCount ? "UnreadNotification" : "NotificationLightIcon"}
                onClick={handleNotificationClick}
              />
            </div>
            <div>
              <IconComponent iconTitle={"FollowersIcon"} />
            </div>
            <div className="menuItem">
              <div>
                <IconComponent iconTitle={"UserLightIcon"} onClick={handleProfileIconClick} />
              </div>
              <SubMenu id="profileIconMenu" onClick={handleSubmenuSelect}>
                <div>
                  <Link to="/profile">Profile</Link>
                  <Link to="">Payment</Link>
                  <Link to="">Wishlist</Link>
                  <Link to="">Recommendations</Link>
                  <Link to="/settings">Settings</Link>
                  <div className="line" />
                  <Link to="/signin">Log Out</Link>
                </div>
              </SubMenu>
            </div>
          </Options>
        </MainNav>
        <SubNav>
          <List>
            {menuList.map((ele, index) => (
              <li key={index}>
                <NavLink to={ele.to} className={({ isActive }) => (isActive ? "active" : "")}>
                  <p>{ele.name}</p>
                  {ele.notice && <span>{ele.notice}</span>}
                </NavLink>
              </li>
            ))}
          </List>
        </SubNav>
      </NavContainer>
      <Overflow id="overflowContainer" onClick={handleOnHamClick} />
    </>
  );
};

// depricating MobileSideNavBar - one common menu for every size of screen
export const MobileSideNavBar = ({ user }) => {
  function handleNavLinkClicked(event, ele) {
    if (ele.subMenu) {
      const nestedList = document.getElementById(ele.id);
      const liLists = nestedList.getElementsByClassName("subMenu");
      for (let i = 0; i < liLists.length; i++) {
        liLists[i].classList.add("active");
      }
    }
    // remove for other
    mobileMenuList.forEach((list) => {
      if (list.subMenu && list.id !== ele.id) {
        const nestedList = document.getElementById(list.id);
        const liLists = nestedList.getElementsByClassName("subMenu");
        for (let i = 0; i < liLists.length; i++) {
          liLists[i].classList.remove("active");
        }
      }
    });
  }

  return (
    <MobileNav id="mobileSideNavBar" onClick={(event) => event.stopPropagation()}>
      <UserHeader>
        <div />
        <p>Hey, {user?.firstName || "User"}</p>
      </UserHeader>
      <List>
        {mobileMenuList.map((ele, index) => (
          <Fragment key={index}>
            <li key={index}>
              <NavLink
                to={ele.to}
                className={({ isActive }) => (isActive ? "active" : "")}
                onClick={(e) => handleNavLinkClicked(e, ele)}
              >
                {ele.name}
              </NavLink>
            </li>
            {ele.subMenu && (
              <NestedList id={ele.id}>
                {ele.subMenu.map((subEle, subIndex) => (
                  <li key={"subIndex" + subIndex} className={"subMenu"}>
                    <NavLink
                      to={subEle.to}
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;{subEle.name}
                    </NavLink>
                  </li>
                ))}
              </NestedList>
            )}
          </Fragment>
        ))}
      </List>
    </MobileNav>
  );
};

export default Navbar;
