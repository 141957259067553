import { Outlet, useNavigate } from "react-router-dom";

import Navbar from "../../components/navbar";
import { OutletContainer, Section } from "../dashboard";
import Footer from "../../components/footer/footer";
import { useAuth } from "../../hooks/auth";
import { useEffect } from "react";

export default function Dashboard() {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && !user.firstName) {
      navigate("/signin");
      // navigate("/signin");
    }
  }, [user]);

  return (
    <>
      <Section id="mainSectionContainer">
        <Navbar />
        <OutletContainer> 
          <Outlet />
          <Footer />
        </OutletContainer>
      </Section>
    </>
  );
}
