import { useState } from "react";
import AvatarSelector from "../../../components/AvatarSelector";
import IconComponent from "../../../components/fields/icon";
import ModalPortal from "../../../containers/common/modalPortal";
import { useStyles } from "./styles";

export default function UserAvatar(props) {
  const { profilePicUrl, image, setImage, setRowImage } = props;
  const classes = useStyles();
  const [avatarSelector, setAvatarSelector] = useState(false);

  function handleImageSelect() {
    setAvatarSelector(true);
  }

  return (
    <>
      <div className={classes.label}>Profile Photo :</div>
      <div className={classes.userProfilePic} onClick={handleImageSelect}>
        <img
          className={classes.userAvatar}
          src={image ? image : profilePicUrl}
          onError={({ currentTarget }) => (currentTarget.style.display = "none")}
        />
        <div className={classes.uploadProfileLogo}>
          <IconComponent className={classes.cameraIcon} iconTitle={"CameraLinearIcon"} />
        </div>
      </div>
      {avatarSelector && (
        <ModalPortal>
          <AvatarSelector
            setImage={setImage}
            setRowImage={setRowImage}
            setOpenAvatarSelector={setAvatarSelector}
          />
        </ModalPortal>
      )}
    </>
  );
}
