export function checkSize(product){
    if(product.size?.length > 4){
      let lowerCaseSize = product.size.toLowerCase();
      if(lowerCaseSize === "small"){
        product.size = "S";
      }
      else if(lowerCaseSize === "x small" || lowerCaseSize === "extra small"){
        product.size = "XS";
      }
      else if(lowerCaseSize === "medium"){
        product.size = "M";
      }
      else if(lowerCaseSize === "large"){
        product.size = "L";
      }
      else if(lowerCaseSize === "x large" || lowerCaseSize === " extra large"){
        product.size = "XL";
      }
      else {
        product.size = product.size.substring(0 , 4).toUpperCase()
      }
    }
    else if(product.size){
      product.size = product.size.toUpperCase()
    }
    return product;
  }

export function checkChange(firstUser, secondUser) {
  let keys = Object.keys(firstUser);
  let isChange = false;
  for(let key of keys){
    if(firstUser[key] !== secondUser[key]){
      isChange = true;
      break;
    }
  }
  return isChange;
}

export function fetchChanges(firstUser, secondUser) {
  let keys = Object.keys(firstUser);
  let changes = {};
  for(let key of keys){
    if(firstUser[key] !== secondUser[key]){
      if(firstUser[key] === ""){
        changes[key] = null;
      }
      else 
        changes[key] = firstUser[key]
    }
  }

  return changes;
}