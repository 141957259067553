import { useMutation } from "@apollo/client";
import { useStyles } from "./styles";
import "./styles.css";
import {
  removeItemFromTempTable
} from "../../../../apollo/operations/items";
import noImageLogo from "../../../../assets/images/no-image.jpg";
import IconComponent from "../../../../components/fields/icon";
import SkeletonLoader from "./skeletonLoader";

export default function AddNewItemHome(props) {
  const { productsList, setProductsList, handleSelectItem, isTempItemsLoaded } = props;
  const [removeItem] = useMutation(removeItemFromTempTable);
  const classes = useStyles();

  function handleImageClick(item, index) {
    if(!item.isItemAdded){
      handleSelectItem({ ...JSON.parse(JSON.stringify(item)) }, index);
    }
  }

  function Selected() {
    return (
      <div className={classes.selectedVector}>
        <IconComponent className={classes.eclipseVector} iconTitle={"EclipseVector"} />
        <IconComponent className={classes.addCheckBox} iconTitle={"BoldCheckBox"} />
      </div>
    );
  }

  function handleRemoveProduct(item, index) {
    if (item.selected) {
      handleSelectItem({ ...JSON.parse(JSON.stringify(item)) }, index);
    }
    productsList.splice(index, 1);
    setProductsList([...productsList]);
    removeItem({
        variables: {
          itemId: item.id
        },
      });
  }

  return (
    <>
      <div className={classes.imageGallery}>
       {productsList.map((item, index) => (
          <div className={classes.parent} key={index} onClick={() => handleImageClick(item, index)}>
            <div
              className={`${classes.galleryImgWrapper}  ${item.isItemAdded && classes.isItemAdded} ${
                item.selected ? classes.selectedProduct : ""
              }`}
            >
              <img
                className={classes.imgBody}
                src={`${item.src}`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = noImageLogo;
                }}
              />
              <IconComponent
                iconTitle="CrossIcon"
                // className={classes.crossIcon}
                className={`${classes.crossIcon} ${classes.crossImgIcon}`}
                onClick={(event) => {
                  event.stopPropagation();
                  handleRemoveProduct(item, index);
                }}
              />
              {item.selected && <Selected />}
            </div>
          </div>
        ))}
        { !isTempItemsLoaded && <SkeletonLoader count={5} /> }
      </div>
    </>
  );
}
