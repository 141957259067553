import styled from "styled-components";
import { colors } from "../helpers/ResponsiveStyleHelper";
import { ClickAwayListener } from "@mui/material";
const MenuItemContainer = styled.div`
  position: absolute;
  right: 0;
  background: #fafafa;
  box-shadow: 0px 4px 4px rgba(198, 198, 198, 0.25);
  padding: 5px 0px;
  width: ${({ width }) => width};
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 17px 0;
  top: 100%;
  z-index: 2;
`;

const MenuItem = styled.div`
  padding: 8px 18px;
  &:hover {
    font-weight: 400;
    background-color: ${colors.blue.light};
  }
`;
export default function ActionDropDown({
  setToggle = () => {},
  menuItemList = [],
  onValueChange = () => {},
  containerWidth = "220px",
}) {
  function handleMenuItemToggle(event, item) {
    event.stopPropagation();
    setToggle(false);
    onValueChange(item);
  }

  return (
    <ClickAwayListener onClickAway={() => setToggle(false)}>
      <MenuItemContainer width={containerWidth}>
        {menuItemList.map((item) => (
          <MenuItem key={item.id} onClick={(e) => handleMenuItemToggle(e, item)}>
            {item.name}
          </MenuItem>
        ))}
      </MenuItemContainer>
    </ClickAwayListener>
  );
}
