import { memo, useState } from "react";
import { useStyles } from "./styles";

function CurationListType({ handleCategoryName, list, onDropCuration }) {
  const classes = useStyles();
  const [selectedItem, setSectedItem] = useState("View All");

  function setType(index, item) {
    setSectedItem(item.name);
    handleCategoryName(item);
  }

  return (
    <div className={classes.leftContainer}>
      <div className={classes.leftHeader}>CATEGORY</div>
      <hr />
      <div className={classes.categoryLeftMenu}>
        <div>My Curations</div>
      </div>
      <div className={classes.subCategoryMenu}>
        {list.map((cat, index) => (
          <div
            className={`${classes.subCategory} ${cat.name === selectedItem && classes.active}`}
            key={index}
            onClick={() => setType(index, cat)}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => cat.name !== "View All" && onDropCuration(cat, e)}
            onDragEnter={(event) =>
              cat.name !== "View All" && (event.target.style.backgroundColor = "#dadada")
            }
            onDragLeave={(event) => (event.target.style.backgroundColor = "")}
          >
            {cat.name}
          </div>
        ))}
      </div>
    </div>
  );
}
export default memo(CurationListType);
