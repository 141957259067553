import { useEffect, useState } from "react";
import { mobileMaxWidth } from "../../components/helpers/ResponsiveStyleHelper";
import CurationCard from "./CurationCard";
import { useStyles } from "./style";

export default function CurationGroup({ curationGroupData, curationName }) {
  const classes = useStyles();
  const [curationDataToShow, setCurationDataToShow] = useState(curationGroupData.slice(0, 3));

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function handleResize() {
    setCurationDataToShow(JSON.parse(JSON.stringify(curationDataToShow)));
  }

  function applyBorderRadius(index) {
    if (index === 0) return curationDataToShow.length > 1 ? "10px 0 0 10px" : "10px";
    else if (index === 1)
      return curationDataToShow.length === index + 1 ? "0 10px 10px 0" : "0 10px 0 0";
    else return "0 0 10px 0";
  }

  function applyWidth(index) {
    if (window.innerWidth <= mobileMaxWidth && curationDataToShow.length === 3)
      return index === 0 ? 89 : 62;
    if (window.innerWidth > mobileMaxWidth && curationDataToShow.length === 3)
      return index === 0 ? 160 : 111;
    return "";
  }

  return (
    <div className={classes.curationGroup}>
      {curationDataToShow.map((curation, i) => (
        <CurationCard
          index={i}
          key={curation.id}
          curationList={curation.curationList}
          totalCount={curationGroupData.length}
          curationName={curationName}
          style={{
            borderRadius: applyBorderRadius(i),
            gridRow: i === 0 || curationDataToShow.length === 2 ? "span 2" : "",
            gridColumn: curationDataToShow.length === 1 ? "span 2" : "",
            width: applyWidth(i),
          }}
        />
      ))}
    </div>
  );
}
