import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showPopup: false,
  showAddyourStylePopup: null,
  notShowAYSPopup: true,
};

export const showPopupSlice = createSlice({
  name: "ShowPopup",
  initialState,
  reducers: {
    setShowPopup: (state, action) => {
      state.showPopup = action.payload;
    },
    setNotShowAYSPopup: (state, action) => {
      state.notShowAYSPopup = action.payload;
    },
    setShowAddyourStylePopup: (state, action) => {
      state.showAddyourStylePopup = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setShowPopup, setNotShowAYSPopup, setShowAddyourStylePopup } =
  showPopupSlice.actions;

export default showPopupSlice.reducer;
