import { useStyles } from "../styles";
import { useEffect, useState } from "react";
import IconComponent from "../../../components/fields/icon";
import {
  DressContainer,
  InspiredList,
  LikeIcon,
  AccessoriesContainer,
  ImageContainer,
  DressImage,
} from "../../../containers/StyleMe";
import "../recommandation.css";

export default function Inspired({handleCurationType, outfits, index, showAddCurationsPopup, addOutfits, addCurationToFavorite, isLiked = false, removeFromFavGetInspired, id, setIsInspiredData }) {
  const classes = useStyles();

  const [dress, setDress] = useState([]);
  const [accessories, setAccessories] = useState([]);

  useEffect(() => {
    const dressData = outfits.filter((item) => item.position === "left");
    const accessoriesData = outfits.filter((item) => item.position === "right");
    setAccessories(accessoriesData);

    setDress(dressData);
  }, [outfits]);

  function handlePopup(value) {
    showAddCurationsPopup(true, index);
    setIsInspiredData(true);
    addOutfits({ outfits, isLiked: value, isAdded: false, index });
    handleCurationType("getInspired")
  }

  function removeCurationFromFavorite(id, index) {
    removeFromFavGetInspired(id, index);
  }
  return (
    <div className={classes.InspiredContainer} id={index}>
      <LikeIcon
        onClick={(event) => {
          event.stopPropagation();
          isLiked ? removeCurationFromFavorite(id, index) : handlePopup(true);
        }}
      >
        <IconComponent
          className={classes.vector}
          iconTitle={isLiked ? "LikedVector" : "UnLikedVector"}
        />
      </LikeIcon>
      <InspiredList>
        <DressContainer className={`dress${dress?.length}`}>
          {dress?.map((item, index) => (
            <ImageContainer key={index}>
              <DressImage draggable={false} src={item.src} alt={`Dress ${index + 1}`} />
            </ImageContainer>
          ))}
        </DressContainer>
        <AccessoriesContainer className={`accessories${accessories?.length}`}>
        {accessories?.map((item, index) => (
          <ImageContainer key={index}>
            <DressImage draggable={false} src={item.src} alt={`Dress ${index + 1}`} />
          </ImageContainer>
        ))}
        </AccessoriesContainer>
      </InspiredList>
    </div>
  );
}
