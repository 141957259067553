import { gql } from "@apollo/client";

export default gql`
  query Me {
    me {
      User {
        firstName
        lastName
        email
        gender
        mobile
        category
        profilePicUrl
        showPopup
        newPurchasePopup
        addYourStylePopup
        id
      }
    }
  }
`;

export const signInUser = gql`
  mutation SigninUser($userSignin: UserSigninInput!) {
    signinUser(userSignin: $userSignin) {
      statusCode
      message
      error
      data
    }
  }
`;

export const signUpUser = gql`
  mutation SignupUser($userNew: UserInput!) {
    signupUser(userNew: $userNew) {
      statusCode
      message
      error
      data
    }
  }
`;

export const updateSubCategories = gql`
  mutation AddSubCategory($category: Category!) {
    addSubCategory(category: $category) {
      statusCode
      message
      error
      data
    }
  }
`;

export const addUpdateCardImage = gql`
  mutation AddUpdateCardImage($key: String!) {
    addUpdateCardImage(key: $key) {
      statusCode
      message
      error
      data
    }
  }
`;

export const getUserProfile = gql`
  query GetUserDetails {
    getUserDetails {
      statusCode
      message
      error
      data {
        firstName
        lastName
        email
        userName
        profilePicUrl
        bio
        mobile
        gender
        country
        state
        address
        zipcode
        language
      }
    }
  }
`;

export const getUserProfileData = gql`
  query GetUserDetails {
    getUserDetails {
      statusCode
      message
      error
      data {
        firstName
        lastName
        email
        profilePicUrl
        bio
      }
    }
  }
`;

export const updateUserProfile = gql`
  mutation Mutation($userDetails: UpdateDetail) {
    updateUserDetails(userDetails: $userDetails) {
      statusCode
      message
      error
      data
    }
  }
`;

export const requestResetPassword = gql`
  mutation RequestResetPassword($userInfo: String!) {
    requestResetPassword(userInfo: $userInfo) {
      data
      error
      message
      statusCode
    }
  }
`;

export const resetPassword = gql`
  mutation ResetPassword($resetDetails: ResetDTO!) {
    resetPassword(resetDetails: $resetDetails) {
      statusCode
      message
      error
      data
    }
  }
`;
