import { makeStyles } from "@material-ui/styles";
import { mobileMediaStyle, tabMediaStyle } from "../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  miklosetTitle: {
    padding: "26px 60px",
    fontFamily: "var(--primaryHeaderFont)",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "45px",
    color: "rgb(0, 0, 0)",
    letterSpacing: "9px",
  },
  parentContainer: {
    padding: "40px 10%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "30px",
    columnGap: "100px",
    
  },
  bodyContainer: {
    width: "50%",
    height: "100%",
  },
  title: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "28px",
    lineHeight: "40px",
    color: "#000000",
    textAlign: "center",
    letterSpacing: "6.5px",
    display: "flex",
    flexDirection: "row",
    fontFamily: "var(--primaryHeaderFont)",
    justifyContent: "center",
    paddingBottom: "50px",
  },
  // title: {
  //   fontSize: "32px",
  //   fontFamily: "var(--primaryHeaderFont)",
  //   fontWeight: "600",

  //   textAlign: "center",
  //   fontStyle: "normal",
  //   color: "#000",
  // },

  content: {
    fontFamily: "Arial, 'Helvetica Neue', Helvetica, sans-serif",
    fontSize: "25px",
    textAlign: "left",
    lineHeight: "40px",
    color: "#000",
    fontStyle: "normal",
    fontWeight: "400",
  },

  ...tabMediaStyle({
    parentContainer: {
      padding: "30px 40px",
      gap: "40px",
      flexDirection: "column",
    },
    title: {
      fontSize: "30px",
      paddingBottom: "20px",
    },

    bodyContainer: {
      width: "80%",
      height: "100%",
    },

    content: {
      fontSize: "22px",
      textAlign: "center",
    },
  }),

  ...mobileMediaStyle(
    {
      parentContainer: {
        padding: "16px 20px",
        gap: "30px",
      },

      title: {
        fontSize: "24px",
        paddingBottom: "20px",
      },
      content: {
        fontSize: "16px",
      },
    },
    "@media (max-width: 600px)",
  ),
});
