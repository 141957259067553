export const maleCategories = [
  {
    name: "Activewear",
    subCategories: [
      { value: "Activewear", name: "All Activewear"},
      { value: "Active Pants", name: "Active Pants" },
      { value: "Active Tops", name: "Active Tops" },
      { value: "Sports Shorts", name: "Sports Shorts" },
      { value: "Yoga", name: "Yoga" },
    ],
  },
  {
    value: "Accessories", name: "Accessories",
    subCategories: [
      { value: "Accessories", name: "All Accessories"},
      { value: "Belts", name: "Belts" },
      { value: "Glasses", name: "Glasses" },
      { value: "Gloves", name: "Gloves", },
      { value: "Hats", name: "Hats", },
      { value: "Jewelry", name: "Jewelry", 
        subCategories: [
          {value: "Jewelry", name: "All Jewelry"},
          {value: "ring", name: "ring" }
        ]
      },
      { value: "Scarf", name :"Scarf" },
      { value: "Ties", name: "Ties" },
      { value: "Wallets", name:"Wallets" },
    ],
  },
  {
    value: "Beauty", name: "Beauty",
    subCategories: [
      { value: "Beauty", name: "All Beauty"},
      { value: "Cologne", name: "Cologne" },
      { value: "Face Cream", name: "Face Cream" },
      { value: "Shaver", name: "Shaver" },
    ],
  },
  { value: "Beachwear", name: "Beachwear",
    subCategories: [
      { value: "Beachwear", name: "All Beachwear"},
      { value: "Swim Shorts",name: "Swim Shorts" }
    ]
  },
  { name: "Denim", value: "Denim" },
  { name: "Miscellaneous", value: "Miscellaneous" },
  {
    name: "Outerwear",
    subCategories: [
      { value: "Outerwear", name: "All Outerwear"},
      { name: "Jackets & Coats", value: "Jackets & Coats" },
      { name: "Winter Jackets", value: "Winter Jackets" },
      { name: "Blazers", value: "Blazers" }
    ],
  },
  { value: "Pants", name: "Pants" },
  { value: "Shirts", name: "Shirts" },
  {
    name: "Shoes",
    subCategories: [
      { value: "Shoes", name: "All Shoes"},
      { value: "Boots", name: "Boots" },
      { name: "Flats", value: "Flats" },
      { name: "Oxfords & Loafers", value: "Oxfords & Loafers" },
      { name: "Sneakers & Athletic", value: "Sneakers & Athletic" },
    ],
  },
  { name: "Shorts", value: "Shorts" },
  { name: "Suits", value: "Suits" },
];

export const femaleCategories = [
  {
    value: "", name: "Activewear",
    subCategories: [
      { value: "", name: "Active Dresses" },
      { value: "", name: "Active Pants" },
      { value: "", name: "Active Tops" },
      { value: "", name: "Sports Bras & Shape" },
      { value: "", name: "Sports Shorts & Skirt" },
      { value: "", name: "Yoga" },
    ],
  },
  {
    value: "Accessories", name: "Accessories",
    subCategories: [
      { value: "Accessories", name: "All Accessories"},
      {
        value: "Jewelry", name: "Jewelry",
        subCategories: [
          { value: "Jewelry", name: "All Jewelry"},
          { value: "Ring", name: "Ring" },
          { value: "Necklace", name: "Necklace" },
          { value: "Earrings", name: "Earrings" },
        ],
      },
      { value: "Belts", name: "Belts" },
      { value: "Handbags", name: "Handbags" },
      { value: "Glasses", name: "Glasses" },
      { value: "Hats", name: "Hats" },
      { value: "Gloves", name: "Gloves" },
      { value: "Scarf", name: "Scarf" },
    ],
  },
  { value: "Beauty", name: "Beauty" },
  { value: "Beachwear", name: "Beachwear",
    subCategories: [
      { value: "Beachwear", name: "All Beachwear"},
      { value: "Swim Suits", name: "Swim Suits" },
      { value: "Cover-Ups", name: "Cover-Ups" },
    ]
  },
  { value: "Denim", name: "Denim" },
  {
    value: "Dresses", name: "Dresses",
    subCategories: [
      { value: "Dresses", name: "All Dresses"},
      { value: "A-Line Dresses", name: "A-Line Dresses" },
      { value: "Bodycon Dresses", name: "Bodycon Dresses" },
      { value: "Maxi Dresses", name: "Maxi Dresses" },
      { value: "Mini Dresses", name: "Mini Dresses" },
      { value: "Off the shoulder", name: "Off the shoulder" },
      { value: "Formal Dresses", name: "Formal Dresses" },
    ],
  },
  { value: "Jumpsuit", name: "Jumpsuit" },
  { value: "Lingerie & Sleepwear", name: "Lingerie & Sleepwear" },
  { value: "Loungewear", name: "Loungewear" },
  { value: "Miscellaneous", name: "Miscellaneous" },
  {
    value: "Outerwear", name: "Outerwear",
    subCategories: [
      { value: "Outerwear", name: "Outerwear" },
      { value: "Jackets & Coats", name: "Jackets & Coats" },
      { value: "Winter Jackets", name: "Winter Jackets" },
      { value: "Blazers", name: "Blazers" },
    ],
  },
  { value: "Pants", name: "Pants" },
  { value: "Rompers", name: "Rompers" },
  {
    value: "Shoes", name: "Shoes",
    subCategories: [
      { value: "Shoes", name: "All Shoes"},
      { value: "Boots", name: "Boots" },
      { value: "Flats", name: "Flats" },
      { value: "Heels", name: "Heels" },
      { value: "Sneakers & Athletic", name: "Sneakers & Athletic" },
      { value: "Wedges", name: "Wedges" },
    ],
  },
  { value: "Shorts", name: "Shorts" },
  { value: "Skirts", name: "Skirts" },
 
  { value: "Tops", name: "Tops",
    subCategories: [
      { value: "Sweaters", name: "Sweaters" },
    ] 
  },
];

//need to update when figma got any update

export const neutralCategories = [
  {
    value: "Activewear", name: "Activewear",
    subCategories: [
      { value: "Activewear", name: "All Activewear"},
      { value: "Active Dresses", name: "Active Dresses" },
      { value: "Active Pants", name: "Active Pants" },
      { value: "Active Tops", name: "Active Tops" },
      { value: "Sports Shorts", name: "Sports Shorts" },
      { value: "Yoga", name: "Yoga" },
    ],
  },
  {
    value: "Accessories", name: "Accessories",
    subCategories: [
      { value: "Accessories", name: "All Accessories"},
      { value: "Belts", name: "Belts" },
      { value: "Glasses", name: "Glasses" },
      { value: "Gloves", name: "Gloves" },
      { value: "Handbags", name: "Handbags" },
      { value: "Hats", name: "Hats" },
      { value: "Jewelry", name: "Jewelry", 
        subCategories: [
          { value: "Jewelry", name: "All Jewelry"},
          { value: "Ring", name: "Ring" },
        ] 
      },
      { value: "Scarf", name: "Scarf" },
      { value: "Ties", name: "Ties" },
      { value: "Wallets", name: "Wallets" },
    ],
  },
  {
    value: "Beauty", name: "Beauty",
    subCategories: [
      { value: "Beauty", name: "All Beauty"},
      { value: "Cologne", name: "Cologne" },
      { value: "Face Cream", name: "Face Cream" },
      { value: "Shaver", name: "Shaver" },
    ],
  },
  { 
    value: "Beachwear", name: "Beachwear",
    subCategories: [
      { value: "Beachwear", name: "All Beachwear"},
      { value: "Swim Shorts", name: "Swim Shorts" },
    ]
  },
  { value: "Denim", name: "Denim" },
  { value: "Miscellaneous", name: "Miscellaneous" },
  {
    value: "Outerwear", name: "Outerwear",
    subCategories: [
      { value: "Outerwear", name: "All Outerwear" },
      { value: "Jackets & Coats", name: "Jackets & Coats" },
      { value: "Winter Jackets", name: "Winter Jackets" },
      { value: "Blazers", name: "Blazers" },
    ],
  },
  { value: "Pants", name: "Pants" },
  { value: "Shirts", name: "Shirts" },
  {
    value: "Shoes", name: "Shoes",
    subCategories: [
      { value: "Shoes", name: "All Shoes"},
      { value: "Boots", name: "Boots" },
      { value: "Flats", name: "Flats" },
      { value: "Oxfords & Loafers", name: "Oxfords & Loafers" },
      { value: "Sneakers & Athletic", name: "Sneakers & Athletic" },
    ],
  },
  { value: "Shorts", name: "Shorts" },
  { value: "Suits", name: "Suits" },
];

// left nav bar options  for settings screen 
export const LeftNavOptions = [
  { id: 1, name: "Account", value: "account", isActive: false },
  { id: 2, name: "Notifications", value: "notificationSetting", isActive: false },
  { id: 3, name: "Hidden Category", value: "hiddenCategory", isActive: false },
  { id: 4, name: "Payment Methods", value: "paymentMethods", isActive: false },
  { id: 5, name: "Scan Email", value: "scanemails", isActive: false },
  { id: 6, name: "Receipts", value: "receipts", isActive: false },
  {
    id: 7,
    name: "Order History",
    value: "orderHistory",
    isActive: false,
    subNavOption: [
      { id: 8, name: "My Orders", value: "myOrders", isActive: false },
      { id: 9, name: "Received Orders", value: "receivedOrder", isActive: false },
    ],
  },
];

// left nav bar options for notifications screen
export const NotificationNavOptions = [
  {id: 1, name: "View All", value: "viewAll", isActive: true },
  {id: 2, name: "Item Added", value: "AddItem", isActive: false},
  {id: 3, name: "Item Removed", value: "RemoveItem", isActive: false},
  {id: 4, name: "Email Scanned", value: "scheduledScan", isActive: false },
]
