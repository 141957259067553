import { useEffect, useState } from "react";
import { useStyles } from "./styles";
import IconComponent from "../../../components/fields/icon";
import ModalPortal from "../../../containers/common/modalPortal";
import AddCategoryPopup from "./addCategoryPopup";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { updateSubCategories } from "../../../apollo/operations/user";
import { setCategory } from "../../../store/categorySlice";
import { openToast } from "../../../components/toast";
import UpdateCategoryPopup from "./updateCategoryPopup";
import Modal from "../../../containers/common/modalPortal/modal";
import DropDownToggle from "../../../components/MobieViewDropDown/DropDownToggle";

export default function LeftCategories(props) {
  const { activeCategory, setActiveCategory, subCategories, header, onDropItem } = props;
  const classes = useStyles();
  const [categories, setCategories] = useState(JSON.parse(JSON.stringify(subCategories)));
  const categoriesList = useSelector((state) => state.category.category);
  const dispatch = useDispatch();
  const [displayAddCategoryPopup, setDisplayAddCategoryPopup] = useState(false);
  const [displayUpdateCategoryPopup, setDisplayUpdateCategoryPopup] = useState(false);
  const [addNewSubCategory, { data: addSubCategoryResponse }] = useMutation(updateSubCategories);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  // adding Veiw All at the top of subCategoriesArray
  useEffect(() => {
    setCategories([{ name: "View All", isActive: true }, ...subCategories]);
  }, [subCategories]);

  useEffect(() => {
    if (addSubCategoryResponse) {
      if (addSubCategoryResponse.addSubCategory.statusCode === "200") {
        if (displayUpdateCategoryPopup) {
          openToast("success", "Successful", "successfully updated");
          setDisplayUpdateCategoryPopup(false);
          subCategorySelect("View All");
        } else if (showDeleteConfirmation) {
          openToast("success", "Successful", "successfully deleted");
          setShowDeleteConfirmation(false);
          subCategorySelect("View All");
        } else {
          openToast("success", "Successful", "New sub-category successfully added");
        }
      } else {
        if (displayUpdateCategoryPopup) {
          openToast("error", "Failed!", "Failed to update sub-categories");
          setDisplayUpdateCategoryPopup(false);
          subCategorySelect("View All");
        } else if (showDeleteConfirmation) {
          openToast("error", "Failed!", "Failed to delete sub-categories");
          setShowDeleteConfirmation(false);
          subCategorySelect("View All");
        } else {
          openToast("error", "Failed!", "Failed to add new sub-category");
        }
      }
    }
  }, [addSubCategoryResponse]);

  function subCategorySelect(subCat) {
    // set isActive true for selected category
    setCategories(
      JSON.parse(JSON.stringify(categories)).map((category) => {
        category.isActive = false;
        if (category.name === subCat) {
          category.isActive = true;
          return category;
        } else if (category.subCategories) {
          category.subCategories = category.subCategories.map((innerCat) => {
            innerCat.isActive = false;
            if (innerCat.name === subCat) {
              innerCat.isActive = true;
              category.isActive = true;
            }
            return innerCat;
          });
          return category;
        }
        return category;
      }),
    );
    setActiveCategory(subCat);
  }

  function handleAddCategory() {
    // open add subcategory popup modal
    setDisplayAddCategoryPopup(true);
  }

  function handleUpdateCategory() {
    // open update subcategory popup
    setDisplayUpdateCategoryPopup(true);
  }

  function onAddCategoryResponse(value, newCategoryName = "") {
    if (value) {
      const tempList = JSON.parse(JSON.stringify(categoriesList));
      const subCat = tempList.find((ele) => ele.name === header);
      if (subCat.subCategories) {
        subCat.subCategories.push({ name: newCategoryName });

        // sorting
        subCat.subCategories.sort((a, b) => {
          let fa = a.name.toLowerCase(),
            fb = b.name.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
      } else {
        subCat.subCategories = [{ name: newCategoryName }];
      }

      addNewSubCategory({
        variables: {
          category: {
            subCategory: tempList,
          },
        },
      });
      dispatch(setCategory(tempList));
    }
    setDisplayAddCategoryPopup(false);
  }

  function onupdateCategoryResponse(value, newSubCategoryList) {
    if (value) {
      const tempList = JSON.parse(JSON.stringify(categoriesList));
      const subCat = tempList.find((ele) => ele.name === header);
      subCat.subCategories = newSubCategoryList;

      const category = { subCategory: tempList };

      const updateSubCategory = [];
      for (let index = 0; index < newSubCategoryList.length; index++) {
        if (newSubCategoryList[index].name !== subCategories[index].name)
          updateSubCategory.push({
            oldName: subCategories[index].name,
            newName: newSubCategoryList[index].name,
          });
      }

      if (updateSubCategory.length > 0) {
        category.updateSubCategory = updateSubCategory;
      }

      addNewSubCategory({
        variables: {
          category,
        },
      });
      dispatch(setCategory(tempList));
    }
    setDisplayUpdateCategoryPopup(value);
  }

  function confirmDeleteSubCategory(value, deleteCategoryIndex) {
    setDisplayUpdateCategoryPopup(false);
    setDeleteIndex(deleteCategoryIndex);
    setShowDeleteConfirmation(value);
  }

  function onDeleteSubCategory(value) {
    if (value) {
      const tempList = JSON.parse(JSON.stringify(categoriesList));
      const subCat = tempList.find((ele) => ele.name === header);
      subCat.subCategories.splice(deleteIndex, 1);

      // check subCategories length and remove this if length == 0 after splice
      if (subCat.subCategories.length === 0) {
        delete subCat.subCategories;
      }

      const deleteList = [subCategories[deleteIndex].name];
      if (subCategories[deleteIndex].subCategories) {
        subCategories[deleteIndex].subCategories.forEach((ele) => deleteList.push(ele.name));
      }

      addNewSubCategory({
        variables: {
          category: {
            subCategory: tempList,
            deleteSubCategory: deleteList,
          },
        },
      });
      dispatch(setCategory(tempList));
    } else {
      setShowDeleteConfirmation(false);
      setDisplayUpdateCategoryPopup(true);
      setDeleteIndex(null);
    }
  }

  function setSelectedList(selectedItem) {
    subCategorySelect(selectedItem.name);
  }

  function SubCategories(props) {
    const { list } = props;
    const displayList = list.filter((ele) => !ele.hidden);
    return (
      <>
        {displayList.map((subCat, index) => (
          <div key={index}>
            <div
              className={`${subCat.isActive ? classes.active : ""} ${classes.subCategory}`}
              key={index}
              onClick={() => subCategorySelect(subCat.name)}
              onDragOver={(event) => event.preventDefault()}
              onDrop={(event) => {
                event.target.style.backgroundColor = "";
                onDropItem(subCat);
              }}
              onDragEnter={(event) =>
                subCat.name !== "View All" && (event.target.style.backgroundColor = "#dadada")
              }
              onDragLeave={(event) => (event.target.style.backgroundColor = "")}
            >
              {subCat.name}
              {subCat.subCategories && (
                <div className={classes.subCategoryArrow}>
                  <IconComponent iconTitle={subCat.isActive ? "UpArrow" : "DownArrow"} />
                </div>
              )}
            </div>
            {subCat.subCategories &&
              subCat.subCategories.map((nestedSubCat, index) => (
                <div
                  className={`${subCat.isActive && classes.nestedActive} ${
                    nestedSubCat.isActive && classes.active
                  } ${classes.nestedSubCategory}`}
                  key={index}
                  onClick={() => subCategorySelect(nestedSubCat.name)}
                  onDragOver={(event) => event.preventDefault()}
                  onDrop={(event) => {
                    event.target.style.backgroundColor = "";
                    onDropItem(nestedSubCat);
                  }}
                  onDragEnter={(event) =>
                    nestedSubCat.name !== "View All" && (event.target.style.backgroundColor = "#dadada")
                  }
                  onDragLeave={(event) => (event.target.style.backgroundColor = "")}
                >
                  {nestedSubCat.name}
                </div>
              ))}
          </div>
        ))}
      </>
    );
  }

  return (
    <>
      <div className={classes.leftContainer}>
        <div className={classes.leftHeader}>Category</div>
        <div className={classes.subCategoryMenu}>
          <SubCategories list={categories} />
        </div>
        <div className={classes.addCategory} onClick={handleAddCategory}>
          <IconComponent iconTitle={"AddCircle"} />
          <div className={classes.addCategoryText}>{"Add New Sub Category"}</div>
        </div>
        <div className={classes.addCategory} onClick={handleUpdateCategory}>
          <IconComponent iconTitle={"EditBoldIcon"} height={"18px"} width={"18px"} />
          <div className={classes.addCategoryText}>{"Modify Sub Category"}</div>
        </div>
        {displayAddCategoryPopup && (
          <ModalPortal>
            <AddCategoryPopup modalResponse={onAddCategoryResponse} />
          </ModalPortal>
        )}
        {displayUpdateCategoryPopup && (
          <ModalPortal>
            <UpdateCategoryPopup
              categories={subCategories}
              modalResponse={onupdateCategoryResponse}
              confirmDeleteSubCategory={confirmDeleteSubCategory}
            />
          </ModalPortal>
        )}
        {showDeleteConfirmation && (
          <ModalPortal>
            <Modal
              type={"deleteSubCategory"}
              handleButtonClick={onDeleteSubCategory}
              svgType="circle"
            />
          </ModalPortal>
        )}
      </div>
      <DropDownToggle
        dropDownList={categories}
        lookName={activeCategory}
        defaultName={header}
        subHeader
        actionValues={[
          {
            displayName: "Add New Sub Category",
            iconTitle: "AddCircle",
            iconClick: handleAddCategory,
          },
          {
            displayName: "Modify Sub Category",
            iconTitle: "EditBoldIcon",
            iconClick: handleUpdateCategory,
          },
        ]}
        setSelectedList={setSelectedList}
      />
    </>
  );
}
