import { EmailShareButton, FacebookShareButton, TwitterShareButton, XIcon } from "react-share";
import { useMutation } from "@apollo/client";
import { gqlConfirmEmail } from "../../apollo/operations/waitList.js";

import {
  Section,
  SubSection,
  Container,
  ImgContainer,
  SubContainer,
  BackContainer,
} from "../../containers/signin";
import { H2, Title } from "../../components/signin";

import WaitlistImage from "../../assets/images/waitlistJoined.png";
import { useStyles } from "./styles";
import Icon from "../../components/fields/icon";
import { useState, useEffect } from "react";

const WaitListJoined = ({ pageType }) => {
  const [confirmEmailGql, { data, loading, error }] = useMutation(gqlConfirmEmail);
  const [isCopied, setIsCopied] = useState(false);

  // const sharelink = "https://dev.mikloset.com";
  const sharelink = window.location.origin.includes("www")
    ? "https://www.mikloset.com"
    : "https://dev.mikloset.com";
  const classes = useStyles();

  useEffect(() => {
    if (pageType) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const id = urlSearchParams.get("id");
      const signature = urlSearchParams.get("signature");

      try {
        confirmEmailGql({
          variables: {
            confirmEmailReq: {
              signature: signature,
              userId: id,
            },
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  const handleShareLink = () => {
    navigator.clipboard.writeText(sharelink);
    navigator
      .share({
        url: sharelink,
      })
      .then(() => console.log("Successful share"))
      .catch((err) => console.error(err));
    setIsCopied(true);
  };

  return (
    <>
      <Section>
        <SubSection>
          <ImgContainer img={WaitlistImage} />
          <Container style={{ zoom: "85%" }} img={WaitlistImage}>
            <BackContainer>
              <SubContainer style={{ zoom: "95%" }}>
                <Title className="signUpTitle">MIKLOSET</Title>
                <H2 className="signUpH2" style={{ margin: "0px 0px 30px" }}>
                  WAITLIST
                </H2>
                <p className={classes.subText}>
                  Congratulations!
                  {pageType ? " Your email is confirmed" : " You're on the waitlist"}. Thank you for
                  joining our journey.
                </p>
                <p className={classes.subTextCopy}>Share with friends by using the link below:</p>
                <div className={classes.inputBox}>
                  <input className={classes.inputLink} type="text" value={sharelink} readOnly />
                  {isCopied ? (
                    <Icon
                      width="65px"
                      height="65px"
                      fill="white"
                      iconTitle="TickColorSquare"
                      onClick={() => {
                        navigator.clipboard.writeText(sharelink);
                        setIsCopied(true);
                      }}
                    />
                  ) : (
                    <button className={classes.copyButton} id="sharelink" onClick={handleShareLink}>
                      Copy
                    </button>
                  )}
                </div>

                <div className={classes.iconDiv}>
                  <div
                    onClick={() => {
                      handleShareLink();
                    }}
                  >
                    <Icon width="32px" height="32px" iconTitle={"InstagramIcon"} />
                  </div>

                  <div>
                    <FacebookShareButton url={sharelink}>
                      <Icon width="32px" height="32px" iconTitle={"FacebookIcon"} />
                    </FacebookShareButton>
                  </div>
                  <TwitterShareButton url={sharelink}>
                    <XIcon size={32} round={true} />
                  </TwitterShareButton>
                  <div>
                    <EmailShareButton url={sharelink}>
                      <Icon width="32px" height="32px" iconTitle={"Email"} />
                    </EmailShareButton>
                  </div>
                </div>
              </SubContainer>
            </BackContainer>
          </Container>
        </SubSection>
      </Section>
    </>
  );
};

export default WaitListJoined;
