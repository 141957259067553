import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  scannedEmail: {},
}

export const scannedEmailSlice = createSlice({
  name: "ScannedEmail",
  initialState,
  reducers: {
    setScannedEmail: (state, action) => {
      state.scannedEmail = action.payload; 
    },
    clearScannedEmail: (state) => {
        state.scannedEmail = {};
    }
  },
})

// Action creators are generated for each case reducer function
export const { setScannedEmail, clearScannedEmail } = scannedEmailSlice.actions

export default scannedEmailSlice.reducer