import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { LeftNavOptions } from "../../components/common/constants";
import LeftNavBar from "./leftNavBar";
import { useStyles } from "./styles";
import DropDown from "../../components/MobieViewDropDown/DropDown";

export default function Settings() {
  const classes = useStyles();
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const currentPage = currentLocation.pathname.split("/").pop();
  const [activeOption, setActiveOption] = useState(
    currentPage === "settings" ? LeftNavOptions[0].value : currentPage,
  );
  const [lookName, setLookName] = useState(LeftNavOptions[0].name);
  const [options, setOptions] = useState(JSON.parse(JSON.stringify(LeftNavOptions)));

  console.log(
    LeftNavOptions[0].name,
    lookName,
    activeOption,
    currentLocation.pathname,
    currentPage,
  );

  useEffect(() => {
    if (currentLocation.pathname === "/settings") navigate(`/settings/${activeOption}`);
  }, [currentLocation]);

  useEffect(() => {
    navigate(`/settings/${activeOption}`);
  }, [activeOption]);

  return (
    <div className={classes.settingsBody}>
      <div className={classes.header}>SETTINGS</div>
      <div className={classes.BodyWrapper}>
        <LeftNavBar
          setActiveOption={setActiveOption}
          options={options}
          setOptions={setOptions}
          activeOption={activeOption}
        />
        <DropDown
          defaultName={"Settings"}
          dropDownList={options}
          setDropDownList={setOptions}
          setLookName={setLookName}
          handleSelection={(item) => setActiveOption(item.value)}
        />
        <div className={classes.rightBody}>
          <div className={classes.sectionHeader}>{lookName}</div>
          <hr className={classes.sectionHr} />
          <Outlet />
        </div>
      </div>
    </div>
  );
}
