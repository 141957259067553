
import { configureStore } from "@reduxjs/toolkit";
import scanPeriodReducer from "./scanPeriodSlice";
import categoryReducer from "./categorySlice";
import curationReducer from "./curationSlice";
import socketReducer from "./socketSlice";
import showPopupReducer from "./showPopupSlice";
import scannedEmailReducer from "./scannedEmailSlice";
import tempItemsReducer from "./tempItemsSlice";
import isTempItemsLoadedReducer from "./isTempItemsLoadedSlice";
import isPreviousScanSliceReducer from "./isPreviousScanSlice";

// import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";

// const persistConfig = {
//   key: "root",
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, showPopupReducer);

export const store = configureStore({
  reducer: {
    scanPeriod: scanPeriodReducer,
    category: categoryReducer,
    curation: curationReducer,
    socket: socketReducer,
    showPopup: showPopupReducer,
    scannedEmail: scannedEmailReducer,
    tempItems: tempItemsReducer,
    isTempItemsLoaded: isTempItemsLoadedReducer,
    isPreviousScan: isPreviousScanSliceReducer
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
