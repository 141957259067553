import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import buttonStyles from "../../styles/button";
import GlobalStyles from "../../styles/global";

const { bg, sizes } = buttonStyles;
const { mediaQueries } = GlobalStyles;

export const buttonCSS = css`
  // font-family: var(--primaryRegularFont);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.03em;
  box-sizing: border-box;
  cursor: pointer;
  ${({ buttontype, size, fontFamily }) => `background: ${bg.default[buttontype].background};
    border: 2px solid ${bg.default[buttontype].borderColor};
    color: ${bg.default[buttontype].color};
    ${
      size &&
      `width: ${sizes.default[size].width};
    height: ${sizes.default[size].height};`
    }
    font-family: ${fontFamily && fontFamily === "karla" ? "var(--secondaryRegularFont)" : "var(--primaryRegularFont)"}
    `
  };

  &:disabled {
    cursor: default;
    ${({ buttontype }) => `background: ${bg.disabled[buttontype].background};
  border: 4px solid ${bg.disabled[buttontype].borderColor};`}
  }

  @media screen and (${mediaQueries.tablet}) {
    ${({ buttontype, size }) => `background: ${bg.mobile[buttontype].background};
      border: 2px solid ${bg.mobile[buttontype].borderColor};
      color: ${bg.mobile[buttontype].color};
      ${
        size &&
        `width: ${sizes.tablet[size].width};
        height: ${sizes.tablet[size].height};`
      }`
    };
  }
  @media screen and (${mediaQueries.mobile}) {
    font-size: 14px;
    line-height: 16px;
    
    ${({ buttontype, size }) => `background: ${bg.mobile[buttontype].background};
      border: 2px solid ${bg.mobile[buttontype].borderColor};
      color: ${bg.mobile[buttontype].color};
      ${
        size &&
        `width: ${sizes.mobile[size].width};
        height: ${sizes.mobile[size].height};`
      }`
    };
  }
`;

const Btn = styled.button`
  ${buttonCSS}
`;

export const AnchorButton = styled.a`
  text-decoration: none;
  ${buttonCSS}
`;

export const ButtonLink = styled(Link)`
  text-decoration: none;
  ${buttonCSS}
`;

const propTypes = {
  children: PropTypes.any.isRequired,
  buttontype: PropTypes.oneOf(Object.keys(bg.default)).isRequired,
  size: PropTypes.oneOf(Object.keys(sizes.default)),
  fontFamily: PropTypes.string,
  disabled: PropTypes.bool,
};

Btn.propTypes = propTypes;
ButtonLink.propTypes = propTypes;
AnchorButton.propTypes = propTypes;

export default Btn;
