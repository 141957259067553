export const curationCategoryList = [
  { name: "View All", isActive: true },
  { name: "Date Night", isActive: false },
  { name: "Wine Country", isActive: false },
  { name: "Night Out", isActive: false },
  { name: "Custom Look", isActive: false },
  { name: "Night Out - Casual", isActive: false },
  { name: "Get Away", isActive: false },
  { name: "Travel Casual", isActive: false },
  { name: "Business Formal", isActive: false },
  { name: "Business Casual", isActive: false },
  { name: "Cocktail Attire", isActive: false },
  { name: "Black Tie", isActive: false },
  { name: "Beach Day", isActive: false },
  { name: "Brunch Time", isActive: false },
  { name: "Outdoor Adventure", isActive: false },
  { name: "Summer - Dressy", isActive: false },
  { name: "Summer - Casual", isActive: false },
  { name: "Winter Wonderland", isActive: false },
  { name: "Special Event", isActive: false },
  { name: "Everyday Look", isActive: false },
  { name: "Boho Chic", isActive: false },
  { name: "Autumn Cozy", isActive: false },
  { name: "Autumn Chic", isActive: false },
  { name: "Fit Life", isActive: false },
  { name: "Sportswear", isActive: false },
];
