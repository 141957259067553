import styled from "styled-components";

import { H2, Para } from "../signin";
import globalStyle from "../../styles/global";
import { AnchorButton } from "../fields/button";

const { colors, mediaQueries } = globalStyle;

export const Heading = styled(H2)`
  margin-bottom: 10px;
`;

export const Text = styled(Para)`
  margin-bottom: 35px;
  color: ${colors.black.light};

  @media screen and (${mediaQueries.mobile}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const Span = styled.span`
  font-weight: 500;
  color: ${colors.orange.light};
`;

export const OpenMailButton = styled(AnchorButton)`
  width: 100%;
  min-height: 64px;
  font-size: 20px;
  line-height: 23px;
  margin-top: auto;
  margin-bottom: 24px;

  @media screen and (${mediaQueries.mobile}) {
    min-height: 48px;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 12px;
  }
`;
