export const modules = [
    {
      id: 1,
      title: "Scan Email",
      value: "ScanEmail",
      summary: "Add items to your closet by scanning your email. Click here to learn how.",
    },
    {
      id: 2,
      title: "Upload Image",
      value: "UploadImage",
      summary: "Add items by uploading an image. Click here to learn how.",
    },
    {
      id: 3,
      title: "Upload URL",
      value: "UploadURL",
      summary: "Easily add items using a URL. Click here to learn how.",
    },
    {
      id: 3,
      title: "Style Me",
      value: "StyleMe",
      summary: "Want different styling options from your closet? Click here to learn how to use Style Me.",
    },
    {
      id: 4,
      title: "Share Me",
      value: "ShareMe",
      summary: "Want to share items for your closet? Click here and find out how.",
    },
    {
      id: 5,
      title: "Sell Me",
      value: "SellMe",
      summary: "Want to sell items you no longer want? Click here to find out how.",
    },
  ];


export const moduleDetails = {
  UploadImage: {
    title: "Upload Image",
    description: "Use this to add items by uploading a picture of them",
    steps: [
      {
        stepCount: "Step 01",
        stepDescription: [
          { type: "p", content: "In the"},
          { type: "highlight", content: "\"Add Your Style\" " },
          { type: "p", content: "section, click on " },
          { type: "highlight", content: "\"Upload File\" " },
          { type: "p", content: ". Then click the "},
          { type: "highlight", content: "\"Upload Your Image\" "},
          { type: "p", content: "box to "},
          { type: "highlight", content: "upload an image of the item " },
          { type: "p", content: "you want to add to your closet. " },
          {
            type: "italic",
            content:
              "(For example, let's say you purchased a shirt from Macy's. You can take a picture of it and upload the picture to add it to your closet).",
          },
        ],
      },
      {
        stepCount: "Step 02",
        stepDescription: [
          {
            type: "p",
            content:
              "Once the image has been loaded, edit the fields as needed and " },
          { type: "highlight", content: "enter the category " },
          { type: "p", content: "where the item should be listed." },
        ],
      },
      {
        stepCount: "Step 03",
        stepDescription: [
          { type: "p", content: "Enter any "},
          { type: "highlight", content: "Tags " },
          { type: "p", content: "that may make searching for the item easier in the future. (For example, you may want to tag an item \"Hat for Hawaii\" so that when you are packing for your Hawaii trip, the items purchased for the trip appear when \"Hawaii\" is entered in the search box). " },
          {
            type: "italic",
            content:
              "Hint: Adding multiple tags will make the item easier to search for later.",
          },
        ],
      },
      {
        stepCount: "Step 04",
        stepDescription: [
          { type: "p", content: "Click " },
          { type: "highlight", content: "\"Add\" " },
          { type: "p", content: "when ready to add the item to your closet." },
        ],
      },
      {
        stepCount: "Step 05",
        stepDescription: [
          { type: "p", content: "Your item(s) will be " },
          { type: "highlight", content: "added to your closet " },
          { type: "p", content: "and found under the " },
          { type: "highlight", content: "specified category" },
        ],
      },
    ],
  },

  UploadURL: {
    title: "Upload URL",
    description: "Use this to add items using the item's URL",
    steps: [
      {
        stepCount: "Step 01",
        stepDescription: [
          { type: "p", content: "In the"},
          { type: "highlight", content: "\"Add Your Style\" " },
          { type: "p", content: "section, click on " },
          { type: "highlight", content: "\"Upload File,\" " },
          { type: "p", content: "and in the URL box, enter the " },
          { type: "highlight", content: "URL of the item " },
          { type: "p", content: "you would like to add to your closet. "},
          {
            type: "italic",
            content:
              "(For example, let's say you purchased a shirt from Macy's. You can find the shirt on the Macy's website and paste the URL to add it to your closet).",
          },
        ],
      },
      {
        stepCount: "Step 02",
        stepDescription: [
          {
            type: "p",
            content:
              "Once the item has been loaded, edit the fields as needed and " },
          { type: "highlight", content: "enter the category " },
          { type: "p", content: "where the item should be listed." },
        ],
      },
      {
        stepCount: "Step 03",
        stepDescription: [
          { type: "p", content: "Enter any "},
          { type: "highlight", content: "Tags " },
          { type: "p", content: "that may make searching for the item easier in the future. (For example, you may want to tag an item \"Hat for Hawaii\" so that when you are packing for your Hawaii trip, the items purchased for the trip appear when \"Hawaii\" is entered in the search box). " },
          {
            type: "italic",
            content:
              "Hint: Adding multiple tags will make the item easier to search for later.",
          },
        ],
      },
      {
        stepCount: "Step 04",
        stepDescription: [
          { type: "p", content: "Click " },
          { type: "highlight", content: "\"Add\" " },
          { type: "p", content: "when ready to add the item to your closet." },
        ],
      },
      {
        stepCount: "Step 05",
        stepDescription: [
          { type: "p", content: "Your item(s) will be " },
          { type: "highlight", content: "added to your closet " },
          { type: "p", content: "and found under the " },
          { type: "highlight", content: "specified category" },
        ],
      },
    ],
  },

  ScanEmail: {
    title: "Scan Email",
    description: "Use this to add items from receipts emailed to you",
    steps: [
      {
        stepCount: "Step 01",
        stepDescription: [
          { type: "p", content: "Add or Select the " },
          { type: "highlight", content: "Email Address " },
          { type: "p", content: "you want to scan."}
        ],
      },
      {
        stepCount: "Step 02",
        stepDescription: [
          { type: "p", content: "A Privacy Info popup will appear. Read, and if you agree, click " },
          { type: "highlight", content: "\"Yes\"" },
        ],
      },
      {
        stepCount: "Step 03",
        stepDescription: [
          { type: "p", content: "Either " },
          { type: "highlight", content: "Enter a Scanning Period " },
          { type: "italic", content: "(one-time scan) " },
          { type: "p", content: "or Select a recurring scanning time frame " },
          { type: "italic", content: "(automatically scan the selected Email on a scheduled basis)." },
        ],
      },
      {
        stepCount: "Step 04",
        stepDescription: [
          { type: "p", content: "Once the Scanning completes, a list of items found in your invoices will appear." },
        ],
      },
      {
        stepCount: "Step 05",
        stepDescription: [
          { type: "highlight", content: "Select the items " },
          { type: "p", content: "you wish to add to your closet." },
        ],
      },
      {
        stepCount: "Step 06",
        stepDescription: [
          { type: "p", content: "Edit the fields under the items" },
        ],
      },
      {
        stepCount: "Step 07",
        stepDescription: [
          { type: "highlight", content: "Enter the Category " },
          { type: "p", content: "the items should be listed under." },
        ],
      },
      {
        stepCount: "Step 08",
        stepDescription: [
          { type: "p", content: "Enter any " },
          { type: "highlight", content: "Tags " },
          { type: "p", content: "that may make searching for the item easier in the future. (For example, you may want to tag an item \"Hat_for_Hawaii\" so that when you are packing for your Hawaii trip, the items purchased for the trip appear when \"Hawaii\" is entered in the search box)." },
          { type: "italic", content: "Hint: Adding multiple tags will make the item easier to search for later." },
        ],
      },
      {
        stepCount: "Step 09",
        stepDescription: [
          { type: "p", content: "Once all the fields have been entered, " },
          { type: "highlight", content: "Select \"Add\" to add the item " },
          { type: "p", content: "to your closet." },
        ],
      },
      {
        stepCount: "Step 10",
        stepDescription: [
          { type: "p", content: "Your items(s) will be " },
          { type: "highlight", content: "added to your closet " },
          { type: "p", content: "and found under the " },
          { type: "highlight", content: "specified category." },
        ],
      },
    ],
  },

  SellMe: {
    title: "Sell Me",
    description: "What is SellMe? SellMe gives you the option to sell your clothes that you no longer want or wear",
    steps: [
      {
        stepCount: "Step 01",
        stepDescription: [
          { type: "p", content: "To " },
          { type: "bold", content: "sell " },
          { type: "p", content: "something from your closet, simply "},
          { type: "highlight", content: "\"click the \"edit\" button\" " },
          { type: "p", content: "of the item."}
        ],
      },
      {
        stepCount: "Step 02",
        stepDescription: [
          { type: "p", content: "An edit box will appear, scroll down to the " },
          { type: "highlight", content: "\"Sell this item\" " },
          { type: "bold", content: "toggle button. " },
          { type: "p", content: "Click the toggle button to activate. " },
        ],
      },
      {
        stepCount: "Step 03",
        stepDescription: [
          { type: "p", content: "Once Sell Me is activated, a form will be displayed where you can " },
          { type: "bold", content: "enter the name of the item, a brief description " },
          { type: "p", content: "and " },
          { type: "bold", content: "set the price." },
        ],
      },
      {
        stepCount: "Step 04",
        stepDescription: [
          { type: "p", content: "Click " },
          { type: "highlight", content: "\"Save\" " },
        ],
      },
      {
        stepCount: "Step 05",
        stepDescription: [
          { type: "p", content: "To check/maintain the items you are selling, simply go to the " },
          { type: "highlight", content: "\"Sell Me\" " },
          { type: "p", content: "option in the " },
          { type: "bold", content: "toolbar." },
        ],
      },
      {
        stepCount: "",
        stepDescription:[
            {type: "subTitle", content: "You can edit your items' price or description here as well. You can also add items to sell from the \"Sell Me\" option in the toolbar."}
        ]
      },
      {
        stepCount: "Step 01",
        stepDescription: [
          { type: "p", content: "When " },
          { type: "bold", content: "Sell Me is selected in the toolbar, " },
          { type: "p", content: "you will see " },
          { type: "highlight", content: "\"My Items on Sale\" " },
          { type: "p", content: ". Click "},
          { type: "highlight", content: "\"Add items to Sell\""}
        ],
      },
      {
        stepCount: "Step 02",
        stepDescription: [
          { type: "p", content: "Here you can " },
          { type: "highlight", content: "\"Upload your image\" " },
          { type: "p", content: "of the items you wish to sell, or " },
          { type: "highlight", content: "\"Select an Item\" " },
          { type: "p", content: "from your closet. "},
        ],
      },
      {
        stepCount: "Step 03",
        stepDescription: [
          { type: "p", content: "Once Sell Me is activated, a form will be displayed where you can " },
          { type: "bold", content: "enter the name of the item, a brief description " },
          { type: "p", content: "and " },
          { type: "bold", content: "set the price." },
        ],
      },
      {
        stepCount: "Step 04",
        stepDescription: [
          { type: "p", content: "Click " },
          { type: "highlight", content: "\"Save\" " },
        ],
      },
      {
        stepCount: "Step 05",
        stepDescription: [
          { type: "p", content: "Your items will appear in the " },
          { type: "highlight", content: "\"My Items on Sale.\" " },
        ],
      },
    ],
  },

  StyleMe: {
    title: "Style Me",
    description: "What's StyleMe? Let's say you have an event and are unsure what to wear. Maybe the new leather pants or a denim mini-skirt? Not Sure? Don't worry! Let our smart styling engine guide you." ,
    steps: [
      {
        stepCount: "Step 01",
        stepDescription: [
          { type: "p", content: "Scroll through the different categories, click on the"},
          { type: "highlight", content: " checkbox " },
          { type: "p", content: "of the items you are considering, and click on the "},
          { type: "highlight", content: "\"Style Me\" " },
          { type: "p", content: "button."}
        ],
      },
      {
        stepCount: "Step 02",
        stepDescription: [
          { type: "p", content: "Once you have selected all your items, click " },
          { type: "highlight", content: "\"Style Me\" " },
          { type: "p", content: "in the toolbar. " },
        ],
      },
      {
        stepCount: "Step 03",
        stepDescription: [
          { type: "p", content: "In the " },
          { type: "highlight", content: "\"Style Me\" " },
          { type: "p", content: "option in the toolbar, you will see all your selected items above your curated style cards under "},
          { type: "highlight", content: "\"My Curations\"" },
          { type: "p", content: "."}
        ],
      },
      {
        stepCount: "Step 04",
        stepDescription: [
          { type: "p", content: "Our AI tool will automatically curate style from your selected items and display them under " },
          { type: "highlight", content: "\"My Curations\"" },
          { type: "p", content: "."}
        ],
      },
      {
        stepCount: "Step 05",
        stepDescription: [
          { type: "p", content: "Want to add more items to the style? " },
          { type: "highlight", content: "\"Click the + icon\" " },
          { type: "p", content: "to add more items." },
        ],
      },
      {
        stepCount: "Step 06",
        stepDescription: [
          { type: "p", content: "Want to make changes to the curations? Just drag/drop new items in or out to create your curation." },
        ],
      },
      {
        stepCount: "Step 07",
        stepDescription: [
          { type: "p", content: "Still not feeling inspired? Check out the " },
          { type: "highlight", content: "\"Get Inspired\" " },
          { type: "p", content: "section at the bottom to see the different styling options from your closet." },
        ],
      },
      {
        stepCount: "Step 08",
        stepDescription: [
          { type: "p", content: "Love the look of a style card, but want to save it for another day? Click the " },
          { type: "highlight", content: "\"Heart\" " },
          { type: "p", content: "icon and the style card will be saved to your favorites for future reference." },
        ]
      }
    ]
  },

  ShareMe: {
    title: "Share Me",
    description: "Let's say your friend has a gala and needs a black dress. She asks if you have any she can borrow. Of course, you have a black dress... you have several! But how do you share those with her?",
    steps: [
      {
        stepCount: "Step 01",
        stepDescription: [
          { type: "p", content: "Scroll through the different categories and click on the " },
          { type: "highlight", content: " checkbox " },
          { type: "p", content: "of the items you want to " },
          { type: "highlight", content: "share "},
          { type: "p", content: "or select an entire subcategory."},
        ],
      },
      {
        stepCount: "Step 02",
        stepDescription: [
          { type: "p", content: "Once you have selected all your items, click the " },
          { type: "highlight", content: "\"Share Me\" " },
          { type: "p", content: "button on the top right." },
        ],
      },
      {
        stepCount: "Step 03",
        stepDescription: [
          { type: "p", content: "A popup will appear asking who you wish to share your items with and for how long." },
        ],
      },
      {
        stepCount: "Step 04",
        stepDescription: [
          { type: "p", content: "Select the follower you wish to share with and the duration you wish to share for and click " },
          { type: "highlight", content: "\"Share Now.\" " },
        ],
      },
      {
        stepCount: "Step 05",
        stepDescription: [
          { type: "p", content: "To check/maintain who you have shared with and who is sharing items with you, simply go to the " },
          { type: "highlight", content: "\"Share Me\" " },
          { type: "p", content: "option in the toolbar. You can extend or stop sharing items here as well." },
        ]
      },
    ],
  },

  ScanUpcCode: {
    title: "Scan UPC Code",
    description: "Use this to add items by scanning their UPC barcode on the price tag. This option is only available on mobile.",
    steps: [
      {
        stepCount: "Step 01",
        stepDescription: [
          { type: "p", content: "Scan the UPC using a scanner on your mobile device (can be the camera app or code scanner app)." },
        ],
      },
      {
        stepCount: "Step 02",
        stepDescription: [
          { type: "p", content: "A list of fields will appear once the image is scanned successfully. Edit the fields as needed and enter the category where the item should be listed." },
        ],
      },
      {
        stepCount: "Step 03",
        stepDescription: [
          { type: "p", content: "Enter any tags that may make searching for the item easier in the future. (For example, you may want to tag an item “Hat for Hawaii” so that when you are packing for your Hawaii trip, the items purchased for the trip appear when “Hawaii” is entered in the search box)" },
        ],
      },
      {
        stepCount: "Step 04",
        stepDescription: [
          { type: "p", content: " Click “Add” when ready to add the item to your closet." },
        ],
      },
      {
        stepCount: "Step 05",
        stepDescription: [
          { type: "p", content: "Your item will be added to your closet and found under the specified category." },
        ]
      },
    ],
  }
}
