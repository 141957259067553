import { useEffect, useState } from "react";

import {
  WomenCategories,
  MenCategories,
  NeutralCategories,
} from "../../components/dashboard/constants";
import { CategoryTitle, CategoryCard, CategoryGrid, CardOptions, CategoryContainer } from ".";
import IconComponent from "../../components/fields/icon";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { updateSubCategories, addUpdateCardImage } from "../../apollo/operations/user";
import { setCategory } from "../../store/categorySlice";
import ModalPortal from "../common/modalPortal";
import Modal from "../common/modalPortal/modal";
import EditCategoryPopup from "./editCategoryPopup";
import { openToast } from "../../components/toast";

export default function Categories({ gender, userID }) {
  const dispatch = useDispatch();
  const [openEditCard, setCard] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const categoriesList = useSelector((state) => state.category.category);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [displayHideCategoryPopup, setDisplayHideCategoryPopup] = useState(false);
  const [displayEditCategoryPopup, setDisplayEditCategoryPopup] = useState(false);
  const navigate = useNavigate();
  const [updateCategory, { data: updateCatResponse }] = useMutation(updateSubCategories);
  const [updateCardImage, { data: updateCardImageResponse }] = useMutation(addUpdateCardImage);

  const handleOnClick = (event) => {
    const cardEle = document.getElementsByClassName("categoryEditCard")[0];
    if (cardEle) {
      const popUp = event.target.closest(".categoryEditCard");
      !popUp && setCard("");
    }
  };

  useEffect(() => {
    if (
      updateCardImageResponse &&
      updateCardImageResponse?.addUpdateCardImage?.statusCode === "200"
    ) {
      if (updateCardImageResponse?.addUpdateCardImage?.data) {
        const imageitem =
          updateCardImageResponse?.addUpdateCardImage?.data?.category[selectedCategory.name] +
          "?" +
          Number(new Date());
        const tempCategoryList = structuredClone(categoriesList);
        const subCate = tempCategoryList.find(
          (categoryEle) => categoryEle.name === selectedCategory.name,
        );

        if (subCate) {
          subCate.imageLink = imageitem;
        }

        dispatch(setCategory(tempCategoryList));
        setDisplayEditCategoryPopup(false);
        openToast("success", "Successful!", "Category Image is updated Successfully");
      }
    } else if (updateCardImageResponse) {
      openToast("error", "Error", "There is an Error, try again later");
    }
  }, [updateCardImageResponse]);

  useEffect(() => {
    if (updateCatResponse && updateCatResponse.addSubCategory?.statusCode === "200") {
      if (updateCatResponse) {
        let targetCatagory = structuredClone(categoriesList);
        targetCatagory[selectedCategory?.index].imageLink = "";
        dispatch(setCategory(targetCatagory));
        openToast("success", "Successful!", "Category Image is updated Successfully");
        setDisplayEditCategoryPopup(false);
      } else if (displayHideCategoryPopup) {
        openToast("success", "Successful!", "Hidden successfully");
        setDisplayHideCategoryPopup(false);
      }
    } else if ((updateCatResponse && displayEditCategoryPopup) || displayHideCategoryPopup) {
      setDisplayEditCategoryPopup(false);
      setDisplayHideCategoryPopup(false);
    }
  }, [updateCatResponse]);

  useEffect(() => {
    window.addEventListener("click", handleOnClick);
    return () => window.removeEventListener("click", handleOnClick);
  }, []);

  useEffect(() => {
    if (gender && categoriesList) {
      let category =
        gender === "male"
          ? MenCategories
          : gender === "female"
          ? WomenCategories
          : NeutralCategories;
      category.forEach((ele, index) => {
        if (!categoriesList[index]) {
          // adding new category to DB
          // updateCategory({
          //   variables: {
          //     category: {
          //       subCategory: category,
          //     },
          //   },
          // });
        }
        ele.name = categoriesList[index] ? categoriesList[index].name : ele.name;
        ele.imageLink = categoriesList[index] ? categoriesList[index].imageLink : "";
      });
      category.forEach((ele) => {
        let tempCategory = categoriesList?.find((cate) => cate.name === ele.name);
        if (tempCategory) ele.hidden = tempCategory.hidden ?? false;
      });

      setCategoryList(structuredClone(category));
    }
  }, [gender, categoriesList]);

  const handleOpenCard = (card) => {
    setCard(card);
  };

  function categoryCardClicked(ele) {
    navigate(`category/${ele.name}`);
  }

  function handleHideCategory(e, ele) {
    e.stopPropagation();
    setSelectedCategory(ele);
    setDisplayHideCategoryPopup(true);
    setCard("");
  }

  function handleEditCategory(e, ele) {
    e.stopPropagation();
    setSelectedCategory(ele);
    setDisplayEditCategoryPopup(true);
    setCard("");
  }

  function HideCategoryResponse(value) {
    if (value) {
      const tempCategoryList = structuredClone(categoriesList);
      const subCate = tempCategoryList.find(
        (categoryEle) => categoryEle.name === selectedCategory.name,
      );
      if (subCate) {
        subCate.hidden = true;
      }
      // calling Api to store updated category list of user
      updateCategory({
        variables: {
          category: {
            subCategory: tempCategoryList,
          },
        },
      });
      dispatch(setCategory(tempCategoryList));
    }
    setDisplayHideCategoryPopup(false);
  }

  function EditCategoryResponse(value, imageKey = "") {
    
    if (value) {
      if (imageKey?.defaultImage && value) {
        const targetCatagory = structuredClone(categoriesList);
        targetCatagory[selectedCategory?.index].imageLink = "";
        updateCategory({
          variables: {
            category: {
              subCategory: targetCatagory,
            },
          },
        });
      } else {
        updateCardImage({
          variables: {
            key: imageKey,
          },
        });
      }
    }
    setDisplayEditCategoryPopup(false);
  }

  return (
    <CategoryContainer>
      <CategoryTitle>FEATURED CATEGORIES</CategoryTitle>
      <CategoryGrid>
        {categoryList.map((ele, index) => {
          ele.index = index;
          if (!ele.hidden) {
            return (
              <CategoryCard
                key={index}
                img={ele.imageLink ? ele?.imageLink : ele.img}
                onClick={() => categoryCardClicked(ele)}
                style={{ boxShadow: "0 0 3px black" }}
              >
                <div>
                  <div style={{ paddingLeft: "13%" }}>
                    <p>{ele.name}</p>
                  </div>
                  <div style={{ marginRight: "4%" }}>
                    <IconComponent
                      iconTitle={"ThreeDotsIcon"}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleOpenCard(ele.name);
                      }}
                    />
                  </div>

                  {ele.name === openEditCard ? (
                    <CardOptions className="categoryEditCard">
                      <p onClick={(e) => handleEditCategory(e, ele)}>Edit</p>
                      <p onClick={(e) => handleHideCategory(e, ele)}>Hide</p>
                    </CardOptions>
                  ) : (
                    ""
                  )}
                </div>
              </CategoryCard>
            );
          }
        })}
      </CategoryGrid>
      {displayHideCategoryPopup && (
        <ModalPortal>
          <Modal type={"hideCategory"} handleButtonClick={HideCategoryResponse} svgType="circle" />
        </ModalPortal>
      )}
      {displayEditCategoryPopup && (
        <ModalPortal>
          <EditCategoryPopup
            modalResponse={EditCategoryResponse}
            category={structuredClone(selectedCategory)}
            userID={userID}
          />
        </ModalPortal>
      )}
    </CategoryContainer>
  );
}
