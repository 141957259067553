import styled from "styled-components";

import globalStyles from "../../styles/global";
import Btn from "../fields/button";

const { colors, mediaQueries } = globalStyles;

export const H4 = styled.h4`
  font-family: var(--primaryRegularFont);
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 4.5px;
  text-align: center;
  color: ${colors.black.regular};

  margin-bottom: 12px;

  @media screen and (${mediaQueries.tablet}) {
    font-size: 22px;
    color: ${colors.white};
  }

  @media screen and (${mediaQueries.mobile}) {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
  }
`;

export const H1 = styled.h1`
  font-family: "HeadlandOne";
  font-style: normal;
  font-weight: 400;
  font-size: 54px;
  line-height: 68px;
  text-align: center;
  color: ${colors.black.regular};

  @media screen and (${mediaQueries.tablet}) {
    font-size: 40px;
    line-height: 54px;
    color: ${colors.white};
  }

  @media screen and (${mediaQueries.mobile}) {
    font-size: 28px;
    line-height: 38px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-top: 30px;

  @media screen and (${mediaQueries.tablet}) {
    margin-top: 40px;
    row-gap: 10px;

    & > button {
      font-size: 18px;
      width: 196px;
      height: 60px;
    }
  }

  @media screen and (${mediaQueries.mobile}) {
    margin-top: 32px;
    row-gap: 8px;

    & > button {
      font-size: 16px;
      width: 176px;
      height: 44px;
    }
  }
`;

export const Button = styled(Btn)`
  width: 219px;
  height: 60px;
`;
