export const validateEmail = (email) => email?.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/);

export const validateMobile = (mobile) => mobile?.match(/^(\+\d{1,3}[- ]?)?\d{10}$/);

// Minimum eight characters, at least one letter, one number and one special character
export const validatePassword = (password) =>
  password?.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-_@$!%*#?&()'~;:^+\/\\=]).{8,}$/)

export const validateNameLength = (name) => name.match(/^[A-Za-z]{3,}$/);

export const validateTags = (value) => {
  const regex1 = /^[^#]/g; //check # present at start of the tags
  const regex2 = /\s[^#]/g; //check # present or not after every /b (whitespace)
  const regex3 = /\s+#|\s\s+/g; // regex for check space sequence

  if (value.match(regex2)) {
    value = value.replace(regex3, " ");
    value = value.replace(/\s/g, " #");
  }
  if (value.match(regex1)) {
    value = "#" + value;
  }
  return value;
};

export const validatePrice = (value) => {
  const regex  = /^\$(\d+)(\.\d{0,2})?$|\$$/g;
  return value.match(regex);
}
