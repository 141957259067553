import { gql } from "@apollo/client";

export const getNotifications = gql`
  query GetNotificationDetails($type: String) {
    getNotificationDetails(type: $type) {
      statusCode
      message
      error
      data
    }
  }
`;

export const updateUnreadNotifications = gql`
  mutation Mutation {
    updateUnreadNotifications {
      error
      message
      statusCode
    }
  }
`;

export const updateNotificationStatus = gql`
  mutation UpdateNotificationDetails(
    $notificationId: Int!
    $isDeleted: Boolean
    $isRead: Boolean
    $isReply: Boolean
  ) {
    updateNotificationDetails(
      notificationId: $notificationId
      isDeleted: $isDeleted
      isRead: $isRead
      isReply: $isReply
    ) {
      statusCode
      message
      error
      data
    }
  }
`;
