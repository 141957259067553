import styled from "styled-components";
import { Link } from "react-router-dom";

import Input from "../fields/input";
import Radio from "../fields/radio";
import Btn from "../fields/button";
import globalStyle from "../../styles/global";

const { colors, mediaQueries } = globalStyle;

export const H2 = styled.h2`
  font-family: var(--primaryHeaderFont);
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  color: #000000;
  text-align: center;
  margin-bottom: 40px;
  letter-spacing: 6.5px;

  &.signUpH2 {
    margin-bottom: 0px;
  }

  @media screen and (${mediaQueries.tablet}) {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 5px;
  }

  @media screen and (${mediaQueries.mobile}) {
    margin-bottom: 40px;
  }
`;

export const InputField = styled(Input)`
  margin-bottom: 10px;

  @media screen and (max-height: 900px) {
    &.signUp {
      margin-bottom: 10px;
      > p {
        margin-bottom: 0px;
      }
      > div:not(.errorText) {
        height: 52px;
      }
    }
  }

  @media screen and (${mediaQueries.mobile}) {

    > p {
      font-size: 14px;
      white-space: nowrap;
      line-height: 20px;
      margin-bottom: 6px;
    }

    > div:not(.errorText) {
      height: 48px;
      padding: 5px 16px;

      > input {
        font-size: 14px;
        line-height: 24px;
      }

      > i > svg {
        width: 20px;
      }
    }
  }
`;

export const SubmitButton = styled(Btn)`
  width: 100%;
  min-height: 64px;
  font-size: 20px;
  line-height: 23px;
  margin-top: auto;
  margin-bottom: 24px;
  user-select: none;
  border-radius: 10px;

  &.signUp {
    margin-bottom: 12px;
  }

  @media screen and (${mediaQueries.tablet}){
    margin-top: 60px;
  }

  @media screen and (${mediaQueries.mobile}) {
    min-height: 48px;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 12px;
  }
`;

export const InputFlex = styled.div`
  display: flex;
  column-gap: 24px;
  width: 100%;
  justify-content: flex-start;

  @media screen and (${mediaQueries.tablet}){
    column-gap: 18px;
  }

  @media screen and (${mediaQueries.mobile}) {
    column-gap: 10px;
  }
`;

export const Para = styled.p`
  font-family: var(--primaryRegularFont);
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.03em;
  font-style: normal;
  text-align: center;
  user-select: none;
  ${({ weight, color }) => `font-weight: ${weight ? weight : 300};
  color: ${color ? colors.orange.light : colors.black.regular};`}

  @media screen and (${mediaQueries.mobile}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const Hyper = styled(Link)`
  ${({ underline }) => `font-family: ${underline ? "var(--primaryRegularItalicFont)" : "var(--primaryRegularFont)"};
  text-decoration-line: ${underline ? "underline" : "none"};
  ${underline ? "margin-left: auto;" : ""}
`}

  font-weight:bold;
  user-select:none;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: ${colors.orange.light};

  @media screen and (${mediaQueries.mobile}) {
    font-size: 12px;
    line-height: 20px;
  }
`;

export const Title = styled.p`
  position: absolute;
  top: -90px;
  left: 15px;
  font-family: var(--primaryHeaderFont);
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 36px;
  color: ${colors.black.regular};
  letter-spacing: 6px;
  
  &.signUpTitle {
    top: -55px
  }

  @media screen and (${mediaQueries.tablet}) {
    position: unset;
    margin-bottom: 15px;
  }

  @media screen and (${mediaQueries.mobile}) {
    font-size: 32px;
  }

  @media screen and (max-height: 800px) {
    top: -55px
  }

  @media screen and (max-height: 600px) {
    top: 20px
  }
`;

export const UploadIcon = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 30px;
  column-gap: 24px;
  user-select: none;

  .circle {
    cursor: pointer;
    width: 56px;
    height: 56px;
    background: ${colors.grey.light};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
  }

  @media screen and (${mediaQueries.mobile}) {
    margin-bottom: 24px;
    column-gap: 12px;

    .circle {
      width: 48px;
      height: 48px;

      > svg {
        width: 18px;
      }
    }
  }
`;

export const RadioGroup = styled(Radio)`
  margin-bottom: 8px;

  @media screen and (${mediaQueries.mobile}) {
    > div > div {
      column-gap: 8px;

      input[type="radio"] {
        width: 16px;
        height: 16px;
      }

      label {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
`;
