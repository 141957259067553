import { createSlice } from "@reduxjs/toolkit";

export const tempItemsSlice = createSlice({
  name: "tempItems",
  initialState: [],
  reducers: {
    setTempItems: (state, action) => action.payload, // Simplified arrow function
  },
});

export const { setTempItems } = tempItemsSlice.actions;

export default tempItemsSlice.reducer;
