import { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";

const ChartBar = ({ ageData }) => {
  const ageRange = ["<18", "18-24", "25-34", "35-44", "45-54", "<=55", "none"];
  const [sortData, setSortData] = useState([]);

  useEffect(() => {
    if (ageData?.length) {
      let tempData = structuredClone(ageData).sort(
        (a, b) => ageRange.indexOf(a.ageRange) - ageRange.indexOf(b.ageRange),
      );
      tempData = tempData.map((obj) => {
        let temp = {};
        temp[obj.ageRange] = obj.count;
        temp.ageRange = obj.ageRange;
        temp.count = obj.count;
        return { ...temp };
      });
      setSortData(tempData);
      console.log(tempData);
    }
  }, [ageData]);

  return (
    <div style={{ height: "300px", width: "50%" }}>
      <h3
        style={{
          justifyContent: "center",
          display: "flex",
          fontFamily: "var(--secondaryHeaderFont)",
        }}
      >
        Age BarChart
      </h3>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={sortData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="ageRange" />
          <YAxis />
          <Tooltip />
          <Legend />
          {/* <Bar dataKey="pv" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} /> */}
          {/* {sortData.map((obj, index) => (
            <Bar
              key={`bar${index}`}
              dataKey={Object.keys(obj)[0]}
              fill="#82ca9d"
              activeBar={<Rectangle fill="gold" stroke="purple" />}
            />
          ))} */}
          <Bar
            dataKey="count"
            fill="#82ca9d"
            activeBar={<Rectangle fill="gold" stroke="purple" />}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartBar;
