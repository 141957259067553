import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import { useStyles } from "./styles"
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Button from "../../../components/fields/button";
import { ReactComponent as DateSync } from "../../../assets/svg/dateSync.svg";
import { ReactComponent as Scan } from "../../../assets/svg/scan.svg";

export default function ScanPeriodModal(props) {
    const classes = useStyles();
    const {handleClick} = props;
    const futureScanPeriod = [
        {id: 1, name: "1 Week", value: "1 Week"},
        {id: 2, name: "1 Month", value: "1 Month"},
        {id: 3, name: "3 Months", value: "3 Months"},
        {id: 4, name: "Custom", value: "Custom"},
    ];
    const [scanDates, setScanDates] = useState({fromScanDate: dayjs(new Date), toScanDate: dayjs(new Date)});
    const [futureScanDates, setFutureScanDates] = useState({fromScanDate: dayjs(new Date), toScanDate: dayjs(new Date)});
    const [futurePeriod , setFuturePeriod] = useState("");
    const [displayFutureScan, setDisplayFutureScan] = useState(false);

    function handleDateChange(value, type){
        if(type === "from"){
            setScanDates({...scanDates, fromScanDate: value});
        }
        else {
            setScanDates({...scanDates, toScanDate: value});
        }
    }

    function handleFutureScanChange(value, type) {
        if(type === "from"){
            setFutureScanDates({...scanDates, fromScanDate: value});
        }
        else {
            setFutureScanDates({...scanDates, toScanDate: value});
        }
    }

    function futurePeriodChange(period) {
        if(period.target.value === "Custom"){ 
            setDisplayFutureScan(true)
        }
        else if (period.target.value !== "Custom" && displayFutureScan){
            setDisplayFutureScan(false);
        }
        setFuturePeriod(period.target.value);
    }

    function handleButtonClick(value) {
        if(value){
            handleClick({
                scanDates: scanDates,
                futurePeriod: futurePeriod,
                futureScanDates: futureScanDates,
            })
        }
        else  handleClick(value);
    }

    return (
    <div className={classes.overflow}>
        <div className={classes.modalContainer}>
          <div className={classes.detailsContainer}>
            <div className={classes.headerSvg}>
                <Scan />
            </div>
            <div className={classes.mainText}>{"Scan Period"}</div>
            <div className={classes.subText}>
                <div className={classes.inputTitle}>Scan Period:</div>
                <div className={classes.inputValue}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className={classes.subInputValue}>
                            <label htmlFor="fromDate">From:</label>
                            <MobileDatePicker
                                className={classes.datePicker}
                                inputFormat="MM/DD/YYYY"
                                value={scanDates.fromScanDate}
                                onChange={(value) => handleDateChange(value,"from")}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </div>
                        <DateSync/>
                        <div className={classes.subInputValue}>
                            <label htmlFor="toDate">To:</label>
                            <MobileDatePicker
                                className={classes.datePicker}
                                inputFormat="MM/DD/YYYY"
                                value={scanDates.toScanDate}
                                onChange={(value) => handleDateChange(value,"to")}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </div>
                    </LocalizationProvider>
                </div>

                <div className={classes.inputTitle}>Pick your Future Scan Period:</div>
                <div className={classes.selectWrapper}>
                    <FormControl fullWidth>
                        <Select
                            className={classes.datePicker}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={futurePeriod}
                            label=""
                            onChange={futurePeriodChange}
                        >
                            {futureScanPeriod.map((period,index) => <MenuItem key={index} value={period.value}>{period.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </div>
                
                {displayFutureScan && 
                <div className={classes.inputValue}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className={classes.subInputValue}>
                            <label htmlFor="fromDate">From:</label>
                            <MobileDatePicker
                                className={classes.datePicker}
                                inputFormat="MM/DD/YYYY"
                                value={futureScanDates.fromScanDate}
                                onChange={(value) => handleFutureScanChange(value,"from")}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </div>
                        <DateSync/>
                        <div className={classes.subInputValue}>
                            <label htmlFor="toDate">To:</label>
                            <MobileDatePicker
                                className={classes.datePicker}
                                inputFormat="MM/DD/YYYY"
                                value={futureScanDates.toScanDate}
                                onChange={(value) => handleFutureScanChange(value,"to")}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </div>
                    </LocalizationProvider>
                </div>}
            </div>
          </div>
            
          <div className={classes.buttonContainer}>
            <Button buttontype="primary" size="default" onClick={() => handleButtonClick(true)}>
                {"Save"}
            </Button>
            <Button buttontype="secondary" size="default" onClick={() => handleButtonClick(false)}>
                {"Cancel"}
            </Button>
          </div>
        </div>
    </div>)
}