import styled from "styled-components";
import globalStyles from "../../styles/global";

const { colors, mediaQueries } = globalStyles;

export const Option = styled.div`
  width: 220px;
  height: 104px;
  box-sizing: border-box;
  top: 30px;
  right: 15px;
  position: absolute;
  display: flex;
  background: #fafafa;
  box-shadow: 0px 4px 4px rgba(198, 198, 198, 0.25);
  font-family: var(--primaryRegularFont);
  font-weight: 300;
  padding: 15px 0px;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
  letter-spacing: 0.03em;
  color: #000000;

  > p {
    width: 100%;
    box-sizing: border-box;
    padding: 9px 18px;
    cursor: pointer;
    text-align: start;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;

    :hover {
      font-weight: 400;
      background-color: ${colors.blue.light};
    }
  }

  @media screen and (${mediaQueries.tablet}) {
    width: 160px;
    height: 88px;
    padding: 12px 0px;
    top: 46px;
    right: 12px;

    > p {
      font-size: 14px;
      line-height: 18px;
      padding: 7px 15px;
    }
  }

  @media screen and (${mediaQueries.mobile}) {
    top: 15px;
    right: -5px;
    width: 120px;
    height: 58px;
    padding: 5px 0px;

    > p {
      font-size: 10px;
      line-height: 14px;
      padding: 5px 12px;
    }
  }
`;
