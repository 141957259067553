import { useQuery } from "@apollo/client";
import { getStyleMe } from "../apollo/operations/styleme";
import { useMemo } from "react";
import { addGroupData } from "../components/helpers/styleMeHelper";

export default () => {
  const { data, error, loading, refetch } = useQuery(getStyleMe);

  const styleMeData = useMemo(() => data?.getStyleMe?.data, [loading]);

  const selectedData = styleMeData?.SelectedList?.length
    ? addGroupData(
        styleMeData.SelectedList.map((item) => ({
          ...item,
          isSelected: true,
        })),
      )
    : [];

  const curationData = styleMeData?.CurationList?.length
    ? styleMeData?.CurationList?.map((item) => ({
        ...item,
        curations: item?.curations
          ?.map((cuItem) =>
            selectedData?.filter((siItem) => `${siItem.id}` === `${cuItem}`)?.flat(),
          )
          .flat(),
      }))
    : [];

  return {
    CurationDataList: curationData,
    SelectedList: selectedData.filter(
      (obj, index) => selectedData.findIndex((item) => item.id === obj.id) === index,
    ),
    error,
    loading,
    refetch,
  };
};
