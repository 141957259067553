import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  category: null,
}

export const categorySlice = createSlice({
  name: "Category",
  initialState,
  reducers: {
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    clearCategory: (state) => {
        state.category = null;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setCategory, clearCategory } = categorySlice.actions

export default categorySlice.reducer