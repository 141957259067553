import { useEffect, useState } from "react";
import {
  CircleSvg,
  MainText,
  ModalContainer,
  Overflow,
  SvgContainer,
} from "../../components/modal";
import Button from "../../components/fields/button";
import IconComponent from "../../components/fields/icon";
import { useStyles } from "./styles";
import { ReactComponent as TickColorSquare } from "../../assets/svg/tickColorSquare.svg";
import { ReactComponent as TickBlankSquare } from "../../assets/svg/tickBlankSquare.svg";

import { useDispatch, useSelector } from "react-redux";
import { setCuration, deleteCuration } from "../../store/curationSlice";

export default function AddItemToCuration(props) {
  const classes = useStyles();
  const {
    setShowCategoryPopup,
    categoryProductsList,
    categoryName,
    mainCategoryName,
    handleResize,
    setUpdateStyleMeFlag,
  } = props;
  const [closetItem, setClosetItem] = useState([]);
  const dispatch = useDispatch();
  const curationsList = useSelector((state) => state.curation.curation);

  function handleClosePopup(value) {
    setShowCategoryPopup(value);
  }

  function handleChoiceResult(value) {
    let closetItemData = [...closetItem];
    let selectedItem = closetItemData.filter((item) => item.isSelected);
    let unSelectedItem = closetItemData.filter((item) => !item.isSelected);
    if (selectedItem.length > 0) {
      if (curationsList.length > 0) {
        const uniqueCur = selectedItem.map((item) =>
          curationsList.findIndex((cur) => cur.id === item.id),
        );
        uniqueCur.filter((item, index) => {
          if (item < 0) {
            dispatch(setCuration(selectedItem[index]));
          }
        });
      } else {
        selectedItem.forEach((item) => dispatch(setCuration(item)));
      }
    }

    if (unSelectedItem.length > 0) {
      if (curationsList.length > 0) {
        const uniqueCur = unSelectedItem.map((item) =>
          curationsList.findIndex((cur) => cur.id === item.id),
        );
        uniqueCur.filter((item) => item >= 0).forEach((curInd) => dispatch(deleteCuration(curInd)));
      }
    }
    handleResize();
    setShowCategoryPopup(value);
    setUpdateStyleMeFlag(true);
  }

  useEffect(() => {
    if (categoryProductsList) {
      const tempData = categoryProductsList.map((item) => ({
        ...item,
        isSelected: curationsList.some((citem) => `${citem.id}` === `${item.id}`),
      }));
      setClosetItem(tempData);
    }
  }, [categoryProductsList]);

  const setSelectedState = (item, index) => {
    let data = {
      ...item,
      isSelected: !item.isSelected,
      mainCategory: mainCategoryName,
    };
    closetItem[index] = data;
    setClosetItem([...closetItem]);
  };

  return (
    <Overflow>
      <ModalContainer className={classes.addItemContainer}>
        <div className={`${classes.flexCenter} ${classes.alignHeader}`}>
          <SvgContainer className={classes.circle}>
            <CircleSvg>
              <IconComponent iconTitle={"Tops"} />
            </CircleSvg>
          </SvgContainer>
          <SvgContainer className={classes.cross}>
            <IconComponent
              className={classes.crossIcon}
              iconTitle={"CrossIcon"}
              onClick={() => handleClosePopup(false)}
            />
          </SvgContainer>
        </div>
        <div className={classes.flexCenter + " " + classes.alignAction}>
          <MainText className={classes.select}>{`Select your ${categoryName}`}</MainText>

          <Button
            className={classes.save}
            buttontype="primary"
            size="default"
            onClick={() => handleChoiceResult(false)}
          >
            {"Style Me"}
          </Button>
        </div>
        <div className={classes.itemsContainer}>
          {closetItem.map((item, index) => (
            <div
              className={classes.styleMeContainter}
              key={`category-name${index}`}
              onClick={(event) => {
                event.stopPropagation();
                setSelectedState(item, index);
              }}
            >
              <div className={`${classes.actionField} ${classes.selectField}`}>
                {item.isSelected ? <TickColorSquare /> : <TickBlankSquare />}
              </div>
              <div className={classes.imageContainer}>
                <img
                  className={classes.StyleMeimage}
                  src={item.src}
                  alt={item.itemName}
                  height={100}
                  width={100}
                />
              </div>
            </div>
          ))}
        </div>
      </ModalContainer>
    </Overflow>
  );
}
