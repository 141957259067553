import { makeStyles } from "@material-ui/styles";
import { mobileMediaStyle, tabMediaStyle } from "../../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  accountHeader: {
    height: "100px",
    width: "100%",
    borderBottom: "1px solid #D9D9D9",
    boxSizing: "border-box",
  },

  subHeader: {
    padding: "40px 0 20px",
    // fontSize: "20px",
    fontSize: "24px",
    lineHeight: "26px",
    fontWeight: "600",
    letterSpacing: "0.5px",
    // fontFamily: "var(--primaryRegularFont)",
    fontFamily: "var(--secondaryHeaderFont)",
  },

  label: {
    paddingBottom: "12px",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: "300",
    // fontFamily: "var(--primaryRegularFont)",
  },

  changePassword: {
    padding: "12px 0",
    fontSize: "20px",
    lineHeight: "23px",
    fontWeight: "400",
    // fontFamily: "var(--primaryRegularFont)",
    cursor: "pointer",

    "&:hover": {
      textDecoration: "underline",
    },
  },

  userProfilePic: {
    height: "200px",
    width: "200px",
    borderRadius: "50%",
    backgroundColor: "#CACACA",
    marginBottom: "40px",
    position: "relative",
    cursor: "pointer",
  },

  userAvatar: {
    height: "100%",
    width: "100%",
    borderRadius: "50%",
    objectFit: "contain",
  },

  uploadProfileLogo: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0px",
    left: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    borderRadius: "50%",
  },

  rowWrapper: {
    display: "flex",
    columnGap: "40px",
  },

  bioCharLength: {
    display: "flex",
    justifyContent: "end",
    fontSize: "12px",
  },

  passwordUpdateToggle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "50%",
    boxSizing: "border-box",
    padding: "30px 20px 0 0",
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "end",
    columnGap: "24px",
    paddingTop: "50px",
  },

  ...tabMediaStyle({
    accountHeader: {
      height: "75px",
    },
    userProfilePic: {
      height: "160px",
      width: "160px",
      marginBottom: "30px",
    },
    rowWrapper: {
      columnGap: "15px",
    },
    subHeader: {
      padding: "30px 0 20px",
    }
  }),

  ...mobileMediaStyle({
    accountHeader: {
      display: "none",
    },
    accountBody: {
      margin: "0 16px",
    },
    subHeader: {
      padding: "12px 0 16px",
      fontSize: "16px",
      fontWeight: "400",
      letterSpacing: "0.48px",
    },
    userProfilePic: {
      width: "120px",
      height: "120px",
      marginBottom: "16px",
    },
    cameraIcon: {
      width: "28px",
      height: "28px",
    },
    rowWrapper: {
      display: "block",
    },
    passwordUpdateToggle: {
      width: "unset",
      display: "block",
      padding: 0,
    },
    changePassword: {
      fontSize: "16px",
      fontWeight: "400",
      letterSpacing: "0.48px",
      lineHeight: "unset",
    },
  }, "@media (max-width:600px)"),
});
