import { useAuth } from "../../hooks/auth";
import { femaleCategories, maleCategories, neutralCategories } from "../common/constants";

// return the main Category name under which given category belongs
export function getMainCategory(categoriesList, category){
    let mainCategory = "";
    categoriesList.forEach(element => {
        if(element.name === category){
            mainCategory = element.name
        }
        else if(element.subCategories){
            let subCategory = getMainCategory(element?.subCategories, category)
            if( subCategory !== ""){
                mainCategory = element.name;
            }
        }
    });
    return mainCategory;
}

// return the categories list of logged in user
export function getCategoriesList() {
    const { user } = useAuth();
    const categoriesList = user?.gender?.toLowerCase() === "male" 
    ? maleCategories
    : user?.gender?.toLowerCase() === "female" 
        ? femaleCategories
        : neutralCategories;
    return categoriesList;
}


// return the list of all child categories of active
export function getChildCategories(categoriesList, activeCategory, header) {
    let childCategoryLists = [];
    let subCategories = categoriesList?.find((category) => category.name === header)?.subCategories ?? [];

    if(activeCategory === "View All" || activeCategory === header){
        childCategoryLists.push(header);
        subCategories.forEach((cate)=>{
            if(!cate.hidden){
                childCategoryLists.push(cate.name)
                if(cate.subCategories){
                    cate.subCategories.forEach((nestedCate)=>{
                        childCategoryLists.push(nestedCate.name);
                    })
                }
            }
        })
    }
    else {
        subCategories.forEach((cate)=>{
            if(!cate.hidden && cate.subCategories){
                if(cate.name === activeCategory){
                    childCategoryLists.push(cate.name);
                    cate.subCategories.forEach((nestedCate)=>{
                        childCategoryLists.push(nestedCate.name)
                    })
                }
                else {
                    cate.subCategories.forEach((nestedCate)=>{
                        if(nestedCate.name === activeCategory){
                            childCategoryLists.push(nestedCate.name)
                        }
                    })
                }
            } 
            else if(cate.name === activeCategory) {
                childCategoryLists.push(cate.name);
            }
        })
    }
    return childCategoryLists
}

export function getCategoryDropDownList(categoriesList) {
    let newCategoryList = [];
    for(let index = 0; index < categoriesList?.length; index++){
        if(categoriesList[index].subCategories){
            
            // check length of non hidden subCategories
            let count = 0;
            categoriesList[index].subCategories.forEach((ele)=>{
                if(!ele.hidden) count++
            })

            if(count > 0){
                newCategoryList.push(
                    {
                        ...categoriesList[index],
                        value: categoriesList[index].name,
                        subCategories: [{name: "All " + categoriesList[index].name, value: categoriesList[index].name}, ...getCategoryDropDownList(categoriesList[index].subCategories)]
                    }
                )
            }
            else{
                newCategoryList.push({
                    name: categoriesList[index].name,
                    value: categoriesList[index].name,
                })
            }
        }
        else {
            newCategoryList.push({
                ...categoriesList[index],
                value: categoriesList[index].name,
            })
        }
    }
    return newCategoryList;
}