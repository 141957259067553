import styled from "styled-components";
import globalStyles from "../../styles/global";
const { mediaQueries } = globalStyles;

export const LikeIcon = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  top: 5px;
  right: 5px;
  z-index: 1;

  @media (${mediaQueries.mobile}) {
    width: 24px;
    height: 24px;
  }
`;

export const AddIcon = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  top: 5px;
  left: 5px;
  z-index: 1;

  @media (${mediaQueries.mobile}) {
    width: 24px;
    height: 24px;
  }
`;

// from curation
export const ListItems = styled.div`
  width: 100%;
  height: 100%;
  /* gap: 10px; */
  display: -webkit-grid;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;

  @media (${mediaQueries.mobile}) {
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    gap: 5px;
  }
`;

export const List = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  align-items: center;
  max-height: 593px;
  justify-content:center;
  &:hover > i {
    display: flex;
  }

  @media (${mediaQueries.mobile}) {
    max-height: 296px;
  }
`;

// component for StyleMe-Index
export const MainContainer = styled.div`
  position: relative;
  padding: 40px 60px 0 60px;

  @media (${mediaQueries.mobile}) {
    padding: 18px 15px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  > p {
    font-family: var(--primaryHeaderFont);
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    width: 274px;
    height: 40px;
    left: 60px;
    top: 204px;
    padding-bottom: 60px;
    color: #000000;
  }

  @media (${mediaQueries.mobile}) {
    > p {
      font-size: 20px;
      padding: 0 0 24px;
      width: unset;
      height: unset;
      line-height: unset;
      font-weight: 600;
    }
  }
`;

export const ItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContainerHeader = styled.div`
  width: 100%;
  display: flex;
   align-items: center;
  margin-bottom: 20px;
  align-items: center;

  @media (${mediaQueries.smallTablet}) {
    margin-bottom: 15px;
    flex-direction:column;
  }
  
  @media (${mediaQueries.mobile}) {
    align-items: center;
    margin-bottom: 15px;
  }
`;

export const Label = styled.div`
  width: 199px;
  height: 28px;
  font-family: var(--primaryRegularFont);
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.03em;
  color: #000000;

  @media (${mediaQueries.mobile}) {
    font-size: 16px;
    line-height: unset;
    width: unset;
    height: unset;
  }
`;

export const Clear = styled.div`
  margin-left: auto;
  height: 19px;
  font-family: var(--primaryRegularFont);
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  justify-content: flex-end;
  display: flex;
  letter-spacing: 0.03em;
  text-decoration-line: underline;
  color: #fb5b01;
  cursor: pointer;
  gap:20px;

  @media (${mediaQueries.smallTablet}) {
    font-size: 14px;
  }
   
  @media (${mediaQueries.mobile}) {
    height: unset;
    line-height: unset;
    font-size: 12px;
  }
`;

export const ContainerList = styled.div`
  width: 100%;
  display: flex;
  // margin-top: 30px;
  flex-wrap: wrap;

  @media (${mediaQueries.mobile}) {
    margin: 0px;
    display: grid;
    grid-template-columns:
      calc(20% - 28px / 5) calc(20% - 28px / 5) calc(20% - 28px / 5) calc(20% - 28px / 5)
      calc(20% - 28px / 5);
    gap: 7px;
  }
`;

export const InspiredContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 10px 20px;
  gap: 100px;
  justify-content: center;

  @media (${mediaQueries.mobile}) {
    gap: 15px;
    margin: 0;
  }
`;

export const Curations = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const MenuContainer = styled.div`
  font-family: var(--primaryRegularFont);
  width: 100%;
  margin-bottom: 10px;
  > p {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.03em;
    margin-bottom: 8px;
    user-select: none;
    > span {
      color: red;
      font-weight: bold;
    }
  }

  .MuiInputBase-formControl {
    border-radius: 10px !important;
    font-size: 20px !important;
    height: 54px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #000 !important;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #000 !important;
  }

  .MuiSelect-select {
    padding: 12.65px 18px !important;
  }

  .errorText {
    display: flex;
    align-items: flex-end;
    user-select: none;
    margin-bottom: 12px;
    > p {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.03em;
      color: #ff0000;
      margin-top: 12px;
      margin-left: 12px;
    }
  }

  @media (${mediaQueries.mobile}) {
    margin-bottom: 12px;

    > p {
      margin-bottom: 6px;
      font-size: 14px;
    }

    .MuiSelect-select {
      padding: 12.65px 16px !important;
    }

    .MuiInputBase-formControl {
      border-radius: 0px !important;
      font-size: 14px !important;
      height: 48px;
    }
  }
`;

export const InspiredList = styled.div`
  display: grid;
  display: -webkit-grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  overflow: auto;
  width: 100%;
  height: 100%;
  gap:0px;
  box-sizing: content-box;
  position: absolute;
  top:0;
  left:0;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
  }

  @media (${mediaQueries.mobile}) {
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
  }
`;
export const DressContainer = styled.div`
    height: 100%;
    display: flex;
    place-items: center;
    flex-direction: column;
    width: 100%;
    justify-content: center;
`;

export const AccessoriesContainer = styled.div`
  height: 100%;
  width:100%;
  display: grid;
  gap:5px 2px;
`;

export const Container = styled.div`
  display: grid;
  display: -webkit-grid;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const DressImage = styled.img`
    max-width: 100%;
    height: 180px;
    width: 100%;
    object-fit: contain;
    object-position:center;

     @media (${mediaQueries.mobile}) {
     height: 170px;
  }
`;
export const TopAccessoriesImage = styled.img`
  max-width: 50px;
  max-height: 50px;
  margin: 3px 4px;
`;
export const AccessoriesImage = styled.img`
  max-width: 80px;
  max-height: 100px;
  margin: 5px;
`;
export const InspiredListItem = styled.div`
  display: flex;

  @media (${mediaQueries.mobile}) {
    padding: 0;
  }
`;

export const FavList = styled.div`
  display: flex;
  height: fit-content;
  max-height: 280px;

  @media (${mediaQueries.mobile}) {
    max-height: 100px;
  }
`;
