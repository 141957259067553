import { useState } from "react";
import IconComponent from "../../components/fields/icon.jsx";
import { useStyles } from "./styles.js";

const FAQ = [
  {
    Q: "Why must I forward my receipts to another email address?",

    A: "This will make it easier to track all your receipts in one place. It will also make scanning receipt data faster.",
  },

  {
    Q: " Why can’t I create additional categories?",

    A: "We have a smart algorithm to match items under Styleme with specific categories. In the future, with more AI, this will be possible.",
  },

  {
    Q: " Why can’t I change category names?",

    A: "We have a smart algorithm to match items under Styleme with specific categories. In the future, with more AI, this will be possible.",
  },

  {
    Q: "Can I add my @mikloset email address on the retail site and have receipts forwarded to Mikloset instead of forwarding them from my personal email address?",

    A: "Yes, you may. You can add your @mikloset email address to any retail site, and receipts will be directly forwarded to your @mikloset account.",
  },

  {
    Q: "What do I do if items from a store I shop at are not populating in the “Scan Email”?",
    A: "Email miklosethelp@gmail.com and provide a list of stores with the respective receipts, and we will add them to the database.",
  },

  { Q: " Where can I find my receipts?", A: "Under Profile-Settings-Receipts" },
];

export default function FaqPage() {
  const classes = useStyles();
  const [question, setQuestion] = useState("");

  const handleClickQuestion = (itemQuestion) => {
    setQuestion(itemQuestion === question ? "" : itemQuestion);
  };

  const FAQDiv = ({ itemQuestion, answer }) => (
    <div
      style={{
        border: "solid 2px gray",
        padding: "20px 10px 20px 20px",
        // marginBottom: "15px",
        lineHeight: "25px",
        display: "flex",
        justifyContent: "space-between",
        fontFamily: "var(--primaryRegularFont)",
        fontWeight: answer ? 100 : 600,
        // color: answer ? "gray" : "",
      }}
    >
      <div
        style={{
          paddingTop: "4px",
          color: itemQuestion === question && !answer ? "#ff3e00" : "",
        }}
      >
        {itemQuestion}
      </div>
      <div style={{ justifyContent: "center", display: "flex" }}>
        {answer ? (
          ""
        ) : (
          <IconComponent
            className={classes.plusIcon}
            iconTitle={itemQuestion === question ? "MinusMark" : "AddMark"}
            onClick={() => handleClickQuestion(itemQuestion)}
          />
        )}
      </div>
    </div>
  );

  return (
    <>
      <div className={classes.contactUsDiv}>
        <div className={classes.imageDiv} />
        <div className={classes.rightSection}>
          <div
          style={{display:"grid",gridRowGap:"15px"}}
          //  className={classes.formCard}
          >
            {FAQ.map((itemQuestion, index) => (
              <div key={index}>
                <FAQDiv itemQuestion={itemQuestion.Q} />
                {itemQuestion.Q === question ? (
                  <FAQDiv itemQuestion={itemQuestion.A} answer={true} />
                ) : (
                  ""
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* {loading && <Loader />} */}
    </>
  );
}
