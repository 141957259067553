import { useQuery } from "@apollo/client";
import { gqlAgeGenderReport } from "../../../apollo/operations/waitList";
import { useMemo } from "react";

const useGetChartData = () => {
  const { data, error, loading, refetch } = useQuery(gqlAgeGenderReport);

  const { ageRangeCountRes, genderCountRes } = useMemo(() => {
    if (data?.ageGenderReport?.data) {
      return {
        ageRangeCountRes: data.ageGenderReport.data.ageRangeCountRes,
        genderCountRes: data.ageGenderReport.data.genderCountRes,
      };
    }
    return { ageRangeCountRes: {}, genderCountRes: {} };
  }, [data]);

  return { ageRangeCountRes, genderCountRes, loading, error, refetch };
};

export default useGetChartData;
