import { useEffect, useRef, /* useRef,*/ useState } from "react";
import { useStyles } from "./styles";
import IconComponent from "../../../components/fields/icon";
import { getSearchMails } from "../../../apollo/operations/receipts.js";
import { useLazyQuery } from "@apollo/client";

export default function SearchBar(props) {
  const [fetchReceipts, { data: searchReceiptsData }] = useLazyQuery(getSearchMails);
  const [search, setSearch] = useState("");
  const [searchMailsArray, setSearchMailsArray] = useState([]);
  const [suggestion, setSuggestion] = useState("");
  const classes = useStyles();
  const handleSearchInput = (event) => {
    setSearch(event.target.value);
  };
  const inputRef = useRef(null);

  const options = {
    month: "short",
    day: "2-digit",
    year: "numeric",
  };

  useEffect(() => {
    // Function to handle clicks outside of the input box
    function handleClickOutside(event) {
      if (event.target.id !== "dropDownInput") {
        setSearchMailsArray([]);
        setSuggestion("");
      }
    }

    // Add the event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchReceiptsData && search.length > 1) {
      const { data } = searchReceiptsData.getSearchMails;
      // console.log(data);
      setSuggestion(data.suggestion);
      setSearchMailsArray(data.mails);
    }
    if (search.length > 1) {
      fetchReceipts({
        variables: {
          inboxSearchRequest: {
            limit: 5,
            search,
          },
        },
      });
    }
    if (search.length < 2) {
      setSearchMailsArray([]);
      setSuggestion("");
    }
  }, [searchReceiptsData, search]);

  return (
    <div>
      {/* <InputBox/> */}
      <div className={classes.search_}>
        <div style={{ display: "flex" }}>
          <input
            // onBlur={handleBlur}
            autoComplete="off"
            id="dropDownInput"
            ref={inputRef}
            className={classes.searchInput}
            type="text"
            placeholder="Search mail"
            value={search}
            onInput={handleSearchInput}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                props.handleSearchName(search);
                setSearchMailsArray([]);
              }
            }}
          />
          {search ? (
            <button
              id="dropDownInput"
              className={classes.crossButton}
              onClick={() => {
                setSearch("");
              }}
            >
              <IconComponent id="dropDownInput" iconTitle={"CrossIcon"} />
            </button>
          ) : (
            ""
          )}
          <button
            id="dropDownInput"
            className={classes.searchButton}
            onClick={() => {
              props.handleSearchName(search);
              setSearchMailsArray([]);
            }}
          >
            <IconComponent iconTitle={"SearchLightIcon"} />
          </button>
        </div>
        {searchMailsArray.length && search.length > 2 ? (
          <div className={classes.searchDropdown}>
            {suggestion ? (
              <div
                id="dropDownInput"
                className={` ${classes.suggestion}`}
                onClick={() => setSearch(suggestion)}
              >
                {suggestion}
              </div>
            ) : (
              ""
            )}
            {searchMailsArray.map((mail) => (
              <div
                id="dropDownInput"
                key={mail.id}
                className={classes.mailDropDown}
                onClick={() => props.handleReciptsId(mail.messageId)}
              >
                <div className={classes.dropDownSubject}>{mail.subject}</div>
                <div className={classes.dropDownDate}>
                  {new Date(parseInt(mail.createdAt)).toLocaleString("en-IN", options)}
                </div>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
