import { useState } from "react";
import { ButtonContainter, MainText, ModalContainer, Overflow } from "../../../components/modal";
import Button from "../../../components/fields/button";
import IconComponent from "../../../components/fields/icon";
import { useStyles } from "./styles";

export default function UpdateCategoryPopup(props) {
  const { categories, modalResponse, confirmDeleteSubCategory } = props;
  const classes = useStyles();
  const [tempCategories, setTempCategories] = useState(JSON.parse(JSON.stringify(categories)));
  const [editIndex, setEditIndex] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  function handleUpdate(value) {
    if (value) {
      if (isUpdate) {
        modalResponse(value, tempCategories);
      }
    } else modalResponse(value);
  }

  function handleChange(value, index) {
    tempCategories[index].name = value;
    setTempCategories([...tempCategories]);

    //check any change there or not
    if (anyUpdates()) {
      if (!isUpdate) {
        setIsUpdate(true);
      }
    } else {
      if (isUpdate) {
        setIsUpdate(false);
      }
    }
  }

  function anyUpdates() {
    //loop to check every subcategory must have name
    for (let index = 0; index < tempCategories.length; index++) {
      if (tempCategories[index].name === "") {
        return false;
      }
    }
    //loop to check any name is updated or hide
    for (let index = 0; index < tempCategories.length; index++) {
      if (
        tempCategories[index].name.trim() !== categories[index].name ||
        Boolean(tempCategories[index].hidden) !== Boolean(categories[index].hidden)
      ) {
        return true;
      }
    }
    return false;
  }

  function deleteCategory(index) {
    confirmDeleteSubCategory(true, index);
  }

  function editCategory(index) {
    setEditIndex(index);
  }

  function hideCategory(index) {
    if (editIndex !== null) setEditIndex(null);

    // revert the name back to original one before hiding
    tempCategories[index].name = categories[index].name;

    tempCategories[index].hidden = !tempCategories[index].hidden;
    setTempCategories([...tempCategories]);

    //check any change there or not
    if (anyUpdates()) {
      if (!isUpdate) {
        setIsUpdate(true);
      }
    } else {
      if (isUpdate) {
        setIsUpdate(false);
      }
    }
  }

  return (
    <Overflow>
      <ModalContainer>
        <MainText>{"Sub-Categories"}</MainText>
        <div className={classes.categoriesWrapper}>
          {tempCategories.map((category, index) => (
            <div
              className={`${classes.categoryRow} ${index === editIndex && classes.editActive}`}
              key={index}
            >
              {editIndex !== index ? (
                <div className={classes.label}>
                  <span
                    className={category.hidden ? classes.deactivatedLabel : classes.activeLabel}
                  >
                    {category.name}
                  </span>
                </div>
              ) : (
                <input
                  className={classes.inputField}
                  type="text"
                  value={category.name}
                  onChange={(e) => handleChange(e.target.value, index)}
                  autoFocus
                />
              )}
              <div className={classes.buttonsGroup}>
                {!category.hidden && (
                  <div className={classes.iconButton} onClick={() => editCategory(index)}>
                    <IconComponent
                      iconTitle={"EditBoldIcon"}
                      className={classes.updateCategoryIcons}
                    />
                  </div>
                )}
                <div className={classes.iconButton} onClick={() => hideCategory(index)}>
                  <IconComponent
                    iconTitle={category.hidden ? "EyeBoldIcon" : "EyeBoldCrossIcon"}
                    className={classes.updateCategoryIcons}
                  />
                </div>
                <div className={classes.iconButton} onClick={() => deleteCategory(index)}>
                  <IconComponent iconTitle={"Trash"} className={classes.updateCategoryIcons} />
                </div>
              </div>
            </div>
          ))}
        </div>

        <ButtonContainter>
          <Button buttontype="secondary" size="default" onClick={() => handleUpdate(false)}>
            {"Close"}
          </Button>
          <Button
            buttontype="primary"
            size="default"
            disabled={isUpdate ? false : true}
            onClick={() => handleUpdate(true)}
          >
            {"Update"}
          </Button>
        </ButtonContainter>
      </ModalContainer>
    </Overflow>
  );
}
