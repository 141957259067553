import { ReactComponent as EyeBoldIcon } from "./eyeBold.svg";
import { ReactComponent as EyeBoldCrossIcon } from "./eyeCrossBold.svg";
import { ReactComponent as SearchLightIcon } from "./searchLight.svg";
import { ReactComponent as CameraBoldIcon } from "./cameraBold.svg";
import { ReactComponent as HeartLightIcon } from "./heartLight.svg";
import { ReactComponent as HeartLinearIcon } from "./heartlinear.svg";
import { ReactComponent as BagLightIcon } from "./bagLight.svg";
import { ReactComponent as NotificationLightIcon } from "./notificationLight.svg";
import { ReactComponent as UserLightIcon } from "./userLight.svg";
import { ReactComponent as TickSquareLinearIcon } from "./tickSquarelinear.svg";
import { ReactComponent as EditBoldIcon } from "./editBold.svg";
import { ReactComponent as EditLigthIcon } from "./editLight.svg";
import { ReactComponent as CameraLinearIcon } from "./cameraLinear.svg";
import { ReactComponent as ArrowRightLinearIcon } from "./arrowRightLinear.svg";
import { ReactComponent as EyeLightIcon } from "./eyeLight.svg";
import { ReactComponent as EyeLightCrossIcon } from "./eyeCrossLight.svg";
import { ReactComponent as ErrorBoldIcon } from "./errorBold.svg";
import { ReactComponent as MenuIcon } from "./menulinear.svg";
import { ReactComponent as FacebookIcon } from "./facebook.svg";
import { ReactComponent as FollowersIcon } from "./followers.svg";
import { ReactComponent as InstagramIcon } from "./instagram.svg";
import { ReactComponent as TwitterIcon } from "./twitter.svg";
import { ReactComponent as TwitterXIcon } from "./twitterX.svg";
import { ReactComponent as ThreeDotsIcon } from "./threeDotsIcon.svg";
import { ReactComponent as TickCircleIcon } from "./tickCircle.svg";
import { ReactComponent as InfoCircleIcon } from "./infoCircle.svg";
import { ReactComponent as InfoIcon } from "./infoIcon.svg";
import { ReactComponent as CrossIcon } from "./cross.svg";
import { ReactComponent as CreateCloset } from "./createCloset.svg";
import { ReactComponent as PrivacyInfo } from "./privacyInfo.svg";
import { ReactComponent as Trash } from "./trash.svg";
import { ReactComponent as AddCircle } from "./addCircle.svg";
import { ReactComponent as EmptyPage } from "./emptyPage.svg";
import { ReactComponent as LightCheckBox } from "./lightCheckBox.svg";
import { ReactComponent as BoldCheckBox } from "./boldCheckBox.svg";
import { ReactComponent as UpArrow } from "./upArrow.svg";
import { ReactComponent as DownArrow } from "./downArrow.svg";
import { ReactComponent as InfoCirclePlan } from "./infoCirclePlan.svg";
import { ReactComponent as AddSquare } from "./addSquare.svg";
import { ReactComponent as AddVector } from "./addVector.svg";
import { ReactComponent as EclipseVector } from "./EllipseVector.svg";
import { ReactComponent as LikedVector } from "./likedVector.svg";
import { ReactComponent as UnLikedVector } from "./unLikedVector.svg";
import { ReactComponent as Tops } from "./tops.svg";
import { ReactComponent as TickBlankSquare } from "./tickBlankSquare.svg";
import { ReactComponent as TickColorSquare } from "./tickColorSquare.svg";
import { ReactComponent as QuestionMark } from "./questionMark.svg";
import { ReactComponent as Cart } from "./shoppingCart.svg";
import { ReactComponent as SelectedVector } from "./selectedVector.svg";
import { ReactComponent as UnSelectedVector } from "./unSelectedVector.svg";
import { ReactComponent as DateSync } from "./dateSync.svg";
import { ReactComponent as Email } from "./email.svg";
import { ReactComponent as Sms } from "./sms.svg";
import { ReactComponent as UnreadNotification } from "./unreadNotification.svg";
import { ReactComponent as GreenTick } from "./greenCorrectTick.svg";
import { ReactComponent as RedCross } from "./redCross.svg";
import { ReactComponent as Clock } from "./clock.svg";
import { ReactComponent as Success } from "./success.svg";
import { ReactComponent as NoItemIcon } from "./noItemIcon.svg";
import { ReactComponent as NoProfile } from "./noProfile.svg";
import { ReactComponent as ErrorWarning } from "./errorWarning.svg";
import { ReactComponent as ModelCrossIcon } from "./crossIcon.svg";
import { ReactComponent as AddMark } from "./addMark.svg";
import { ReactComponent as selectAll } from "./selectAll.svg";
import { ReactComponent as selectEmpty } from "./selectEmpty.svg";
import { ReactComponent as DeleteIcon } from "./deleteIcon.svg";
import { ReactComponent as LargeAddSquare } from "./largeAddSquare.svg";
import { ReactComponent as UploadImageIcon } from "./uploadImageIcon.svg";
import { ReactComponent as UploadImageIcon2 } from "./uploadImageIcon2.svg";
import { ReactComponent as UndoIcon } from "./undeoIcon.svg";
import { ReactComponent as MinusMark } from "./minusMark.svg";
import { ReactComponent as NewTrash } from "./newtrash.svg";
import { ReactComponent as NewAddCircle } from "./newaddcircle.svg";
import {ReactComponent as NewAddPlus} from "./newaddplus.svg";


export default {
  AddCircle,
  AddSquare,
  AddVector,
  ArrowRightLinearIcon,
  BagLightIcon,
  AddMark,
  BoldCheckBox,
  CameraBoldIcon,
  CameraLinearIcon,
  Cart,
  CreateCloset,
  CrossIcon,
  DeleteIcon,
  DownArrow,
  EclipseVector,
  EditBoldIcon,
  EditLigthIcon,
  EmptyPage,
  ErrorBoldIcon,
  ErrorWarning,
  EyeBoldIcon,
  EyeBoldCrossIcon,
  EyeLightCrossIcon,
  EyeLightIcon,
  FacebookIcon,
  FollowersIcon,
  HeartLightIcon,
  HeartLinearIcon,
  InfoCircleIcon,
  InfoCirclePlan,
  InfoIcon,
  InstagramIcon,
  LargeAddSquare,
  LightCheckBox,
  LikedVector,
  MenuIcon,
  MinusMark,
  NoItemIcon,
  NotificationLightIcon,
  PrivacyInfo,
  QuestionMark,
  SearchLightIcon,
  TickCircleIcon,
  TickSquareLinearIcon,
  TwitterIcon,
  TwitterXIcon,
  ThreeDotsIcon,
  Trash,
  Tops,
  TickBlankSquare,
  TickColorSquare,
  UpArrow,
  UploadImageIcon,
  UploadImageIcon2,
  UserLightIcon,
  UnSelectedVector,
  UnLikedVector,
  UndoIcon,
  DateSync,
  Email,
  Sms,
  UnreadNotification,
  GreenTick,
  RedCross,
  Clock,
  Success,
  NoProfile,
  SelectedVector,
  selectAll,
  selectEmpty,
  NewAddCircle,
  NewTrash,
  NewAddPlus,
  ModelCrossIcon
};
