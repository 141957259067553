import { Overflow, ModalContainer, MainText, ButtonContainter } from "../../../../components/modal";
import { useStyles } from "./styles";
import IconComponent from "../../../../components/fields/icon";
import Button from "../../../../components/fields/button";
import { ReactComponent as UploadImageIcon } from "../../../../assets/svg/uploadImageIcon2.svg";
import { useRef, useState } from "react";

export default function ImageUplaodPopup(props) {
  const inputRef = useRef(null);
  const classes = useStyles();
  const [urlInput, setUrlInput] = useState("");
  const [_files, setFiles] = useState("");
  const [priority, setPriority] = useState("");

  const { handleCloseModal, selectedItems, activeIndex, handleFileUpload, handleSrcUpload } = props;

  function handleSetFiles(e) {
    const { files } = e.target;
    // console.log("inside handleSetFiles", files);
    setPriority("file");
    setFiles(files);
  }
  // console.log("_files", _files);
  function handleImageUpload() {
    inputRef.current.click();
  }

  const handleurlInputChange = (event) => {
    event?.target?.value ? setPriority("url") : setPriority("");
    setFiles("");
    setUrlInput(event.target.value);
  };

  function handleSavebuttonClick() {
    if (priority === "file") {
      handleFileUpload(_files);
    } else if (priority === "url" || (priority === "file" && urlInput)) {
      handleSrcUpload(urlInput);
    }
  }

  function checkUrlInputFormat() {
    const extension = urlInput.substring(urlInput.lastIndexOf(".") + 1).toLowerCase();
    return ["jpg", "jpeg", "png", "gif", "bmp", "svg"].includes(extension);
  }

  return (
    <Overflow onClick={() => handleCloseModal(false)}>
      <ModalContainer
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <MainText>{"Upload Image"}</MainText>
        <div className={classes.iconAndUrlDiv}>
          <UploadImageIcon className={classes.alignSelf} onClick={handleImageUpload} />
          <input
            type="file"
            ref={inputRef}
            style={{ display: "none" }}
            onChange={handleSetFiles}
            accept="image/*"
          />
          <p className={`${classes.alignSelf} ${classes.ORptag}`}>OR</p>
          <div className={`${classes.alignSelf} ${classes.inputDivWrapper}`}>
            <div className={`${classes.alignSelf} ${classes.inputDiv}`}>
              <input
                placeholder="Paste Image Url"
                className={`${classes.urlInput} ${classes.alignSelf}`}
                onChange={handleurlInputChange}
                value={urlInput}
              />
            </div>
          </div>

          {/* {console.log(_files[0]?.name)} */}
          {(_files?.length || (urlInput && checkUrlInputFormat())) && priority ? (
            <img
              src={`${
                priority === "file" && _files.length
                  ? _files[0]?.name && URL.createObjectURL(_files[0])
                  : urlInput
              }`}
              alt="Image"
              className={`${classes.uploadedImageStyle} ${classes.alignSelf}`}
            />
          ) : (
            <></>
          )}
        </div>
        {/* {console.log(urlInput)} */}
        <ButtonContainter>
          <Button buttontype="primary" size="default" onClick={handleSavebuttonClick}>
            {"Save"}
          </Button>
          <Button buttontype="primary" size="default" onClick={() => handleCloseModal(false)}>
            {"Cancel"}
          </Button>
        </ButtonContainter>
      </ModalContainer>
    </Overflow>
  );
}
