import { useEffect, useState } from "react";
import ReceiptContent from "./ReceiptContent";
import { useStyles } from "./styles";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ReactComponent as Arrow } from "../../../assets/svg/arrowRightLinear.svg";
import { ReactComponent as SelectAll } from "../../../assets/svg/selectAll.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/svg/deleteIcon.svg";

import { getReceipts, deleteMails } from "../../../apollo/operations/receipts";
import Loader from "../../../components/loaderScreen";
import SearchBar from "./searchBar";
import TableMails from "./TableMails";
import { openToast } from "../../../components/toast";

export default function ReceiptsList() {
  
  const pageSize = 20;
  // const [mailsSort, setSort] = useState("");
  const [messageId, setMessageId] = useState("");
  const [mailsArray, setMailsArray] = useState(null);
  const [totalMailsCount, setTotalMails] = useState(0);
  const [page, setPage] = useState(0);
  const [fetchReceipts, { data: receiptsData }] = useLazyQuery(getReceipts);
  const [searchName, setSearchName] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [DeleteMails, { data: deleteResponse, loading: DeleteMailLoading }] =
    useMutation(deleteMails);
  const [isDeleteToggle, setIsDeleteToggle] = useState(false);

  const [isSort, setSort] = useState({
    isSubject: false,
    isStoreName: false,
    isDate: true,
  });

  const [isASC, setOrder] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  // const [storeNameASC, setStoreNameASC] = useState("");
  // const [subjectASC, setSubjectASC] = useState("");
  const handleSearchName = (searchName) => {
    setSearchName(searchName);
  };

  const handleReciptsId = (id) => {
    setMessageId(id);
  };
  const handleOffSet = (value) => {
    if (value === "back") {
      if (page !== 0) setPage(page - 1);
    } else {
      if (totalMailsCount > (page + 1) * pageSize) setPage(page + 1);
    }
  };

  const classes = useStyles();

  useEffect(() => {
    fetchReceipts({
      variables: {
        inboxRequest: {
          limit: pageSize,
          offset: page * pageSize,
          isSort,
          isASC,
          search: searchName,
        },
      },
    });
  }, [page, isSort, isASC, searchName, isDeleteToggle]);

  useEffect(() => {
    if (receiptsData) {
      const { data } = receiptsData.getInboxMails;
      setTotalMails(data.count);
      setMailsArray(data.mails);

      setIsDeleteModal(false);
      setSelectedOptions([]);
    }
  }, [receiptsData, isDeleteToggle]);

  useEffect(() => {
    if (deleteResponse) {
      if (deleteResponse.deleteMails.statusCode === "200") {
        fetchReceipts({
          variables: {
            inboxRequest: {
              limit: pageSize,
              offset: page * pageSize,
              isSort,
              isASC,
              search: searchName,
            },
          },
        });
        openToast("success", "Deleted", "Selected mails deleted successfully");
        // console.log(deleteResponse);
      } else {
        openToast("error", "Error", "Failed to delete, Try again later");
      }
    }
  }, [deleteResponse]);

  function onModalButtonsClick(state) {
    if (state) {
      // console.log("delete accepted");
      //   const temp = mailsArray.filter((mail) => !selectedOptions.includes(mail.id));
      //
      //
      DeleteMails({ variables: { messageIds: selectedOptions } });
      setMailsArray(null);
    }
    setIsDeleteToggle(!isDeleteToggle);
  }

  const handleSorting = (sort) => {
    if (sort) {
      setSort(sort);
    }
    setSort();
  };

  const reset = (key) => {
    let temp = {
      isSubject: false,
      isStoreName: false,
      isDate: false,
    };
    temp[key] = true;
    setOrder(temp[key] !== isSort[key] ? true : !isASC);
    setSort(temp);
    // setOrder(!temp[key] )
  };

  const handleAllCheckBoxChange = () => {
    // console.log({ clicked: selectedOptions });
    if (selectedOptions.length) {
      setSelectedOptions([]);
    } else {
      setSelectedOptions(mailsArray.map((mail) => mail.id));
    }
  };
  const handleCheckboxChange = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  function handleDeleteClick() {
    selectedOptions.length && setIsDeleteModal(true);
    // console.log("delete clicked");
    // const temp = mailsArray.filter((mail) => !selectedOptions.includes(mail.id));
    // DeleteMails({ variables: { messageIds: selectedOptions } });
    //  setSelectedOptions([]);
  }

  return messageId ? (
    <ReceiptContent handleReciptsId={handleReciptsId} msgId={messageId} />
  ) : (
    <>
      <div className={classes.recieptCategoryHeader} />
      {DeleteMailLoading || !mailsArray ? (
        <Loader />
      ) : (
        <>
          <div className={classes.header}>
            <div className={classes.subHeader}>Receipts</div>
            <SearchBar
              handleSearchName={handleSearchName}
              handleReciptsId={handleReciptsId}
              searchName={searchName}
            />
            <div className={classes.paggination}>
              <p>{`${totalMailsCount ? page * pageSize + 1 : page} - ${
                (page + 1) * pageSize > totalMailsCount ? totalMailsCount : (page + 1) * pageSize
              } of ${totalMailsCount}`}</p>
              <Arrow className={classes.arrowLeft} onClick={() => handleOffSet("back")} />
              <Arrow className={classes.arrowRight} onClick={() => handleOffSet("next")} />
            </div>
          </div>
          <TableMails
            isDeleteModal={isDeleteModal}
            isASC={isASC}
            isSort={isSort}
            selectedOptions={selectedOptions}
            mailsArray={mailsArray || []}
            handleReciptsId={handleReciptsId}
            handleCheckboxChange={handleCheckboxChange}
            handleSorting={handleSorting}
            reset={reset}
            onModalButtonsClick={onModalButtonsClick}
            handleAllCheckBoxChange={handleAllCheckBoxChange}
            handleDeleteClick={handleDeleteClick}
          />
        </>
      )}
    </>
  );
}
