import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  scanPeriod: {},
}

export const scanPeriodSlice = createSlice({
  name: "ScanPeriod",
  initialState,
  reducers: {
    setScanPeriod: (state, action) => {
      state.scanPeriod = action.payload; 
    },
    clearScanPeriod: (state) => {
        state.scanPeriod = {};
    }
  },
})

// Action creators are generated for each case reducer function
export const { setScanPeriod, clearScanPeriod } = scanPeriodSlice.actions

export default scanPeriodSlice.reducer