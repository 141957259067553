import styled from "styled-components";

export const Header = styled.div`
  font-family: var(--primaryHeaderFont);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  display: flex;
  align-items: center;
  padding: 0 16px 12px;
  justify-content: space-between;
  cursor: pointer;
`;

export const SubHeader = styled.div`
  font-family: var(--primaryRegularFont);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.48px;
  align-items: center;
  padding: 0 16px 12px;
`;

export const DropDownContainer = styled.div`
  position: absolute;
  width: 100%;
  background: #fff;
  z-index: 1;
  max-height: 392px;
  overflow: auto;
  box-shadow: #000 0 20px 45px -35px;
  border-radius: 0 0 8px 8px;
  display: none;

  & > div:last-child {
    border: none;
  }

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
  }
`;
