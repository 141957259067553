import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
var Buffer = require("buffer/").Buffer;

import {
  Section,
  SubSection,
  Container,
  ImgContainer,
  SubContainer,
  BackContainer,
} from "../../containers/signin";
import {
  H2,
  Hyper,
  InputField,
  InputFlex,
  SubmitButton,
  Para,
  RadioGroup,
  Title,
  UploadIcon,
} from "../../components/signin";
// import SignUpFemale from "../../assets/images/singupFemale.png";
// import SingUpMen from "../../assets/images/signupMen.png";
// import SingUpNeutral from "../../assets/images/signupNeutral.png";
import SignUpFemaleNew from "../../assets/images/singupFemale_new.png";
import SingUpMenNew from "../../assets/images/signupMen_new.png";
import SingUpNeutralNew from "../../assets/images/signupNeutral_new.jpg";
import IconComponent from "../../components/fields/icon";
import {
  validateEmail,
  validateNameLength,
  validatePassword,
} from "../../components/helpers/validate";
import { useAuth } from "../../hooks/auth";
import { signUpUser } from "../../apollo/operations/user";
import { openToast } from "../../components/toast";
import AvatarSelector from "../../components/AvatarSelector";
import ModalPortal from "../../containers/common/modalPortal";
import { uploadProfileImage } from "../../apollo/operations/items";
import { useDispatch } from "react-redux";
import { setShowPopup } from "../../store/showPopupSlice";
import Loader from "../../components/loaderScreen";

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;

const Signup = () => {
  const [formDetails, setFormDetails] = useState({ gender: "FEMALE", show: false });
  const [openAvatarSelector, setOpenAvatarSelector] = useState(false);
  const [image, setImage] = useState("");
  const [imageFileDetails, setImageFileDetails] = useState({});
  const [inputErrors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    userName: false,
  });
  const [SignUp, { data: signUpData, loading }] = useMutation(signUpUser);
  const [UploadProfile, { data: uploadResponse }] = useMutation(uploadProfileImage);
  const [apiError, setApiError] = useState("");

  const { login } = useAuth();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (signUpData && signUpData.signupUser) {
      if (signUpData.signupUser.statusCode !== "200") {
        setApiError(signUpData.signupUser);
      } else if (signUpData.signupUser.data.token) {
        login(signUpData.signupUser.data.token);
        dispatch(setShowPopup(true));
        openToast("success", "Welcome", "User Signup successful");
      }
    }
  }, [signUpData]);

  useEffect(() => {
    if (uploadResponse && uploadResponse.getUploadProfileImageURL) {
      if (uploadResponse.getUploadProfileImageURL.statusCode !== "200") {
        setApiError(uploadResponse.getUploadProfileImageURL);
      } else {
        uploadImageUsingPresignedURl(uploadResponse);
      }
    }
  }, [uploadResponse]);

  async function uploadImageUsingPresignedURl(uploadResponse) {
    let buf = Buffer.from(image.replace(/^data:image\/\w+;base64,/, ""), "base64");
    const res = await fetch(uploadResponse.getUploadProfileImageURL.data.url, {
      method: "PUT",
      headers: {
        "Content-Type": imageFileDetails.fileType,
      },
      body: buf,
    });
    if (res.status === 200) {
      const key = uploadResponse.getUploadProfileImageURL.data.key;
      submitSignupDetails(key);
    } else {
      openToast("error", "Failed!", "Try again");
    }
  }

  const handleInputChange = (event) => {
    let {
      target: { name, value },
    } = event;

    if (name !== "firstName" && name !== "lastName" && name !== "show") {
      value = value.trim();
    }

    let isValid = true;
    if (name === "firstName" || name === "lastName") {
      const regex = /[^A-Za-z ]/g;
      value = value.replace(regex, "");
      if (
        (name === "firstName" && inputErrors.firstName) ||
        (name === "lastName" && inputErrors.lastName)
      )
        isValid = !!validateNameLength(value);
    } else if (name === "email") {
      if (apiError?.message === "User already exits with email") {
        setApiError("");
      }
      if (inputErrors.email) isValid = !!validateEmail(value);
    } else if (name === "userName") {
      if (apiError?.message === "User already exits with userName") {
        setApiError("");
      }
    } else if (
      name === "confirmPassword" &&
      formDetails.password !== "" &&
      value !== formDetails.password
    ) {
      isValid = false;
    } else if (name === "mobile" && isNaN(value)) {
      value = null;
    } else if (value === "") {
      isValid = false;
    }

    setFormDetails((prevState) => (value === null ? prevState : { ...prevState, [name]: value }));

    setErrors((prevState) => {
      let val = "";
      if (name === "password") {
        val = value !== formDetails.confirmPassword;
        isValid = passwordRegex.test(value);
      }
      return name === "password"
        ? {
            ...prevState,
            [name]: !isValid,
            confirmPassword: val,
          }
        : value === null || value === ""
        ? prevState
        : {
            ...prevState,
            [name]: !isValid,
          };
    });
  };

  const handleIconClick = () => {
    handleInputChange({ target: { name: "show", value: !formDetails.show } });
  };

  const handleShowPasswordIconClick = () => {
    setShowPassword(!showPassword);
  };

  const handleOnSubmit = () => {
    let errors = {
      email: !formDetails.email || !validateEmail(formDetails.email),
      firstName: !formDetails.firstName || !validateNameLength(formDetails.firstName),
      lastName: !formDetails.lastName || !validateNameLength(formDetails.lastName),
      userName: !formDetails.userName,
      password: !formDetails.password || !validatePassword(formDetails.password),
    };
    if (Object.values(errors).filter((ele) => ele).length) {
      setErrors((prevState) => ({ ...prevState, ...errors }));
    } else {
      // upload profile image using presigned url
      if (image) {
        const userNew = { ...formDetails };
        delete userNew.show;
        delete userNew.confirmPassword;
        UploadProfile({
          variables: {
            file: {
              fileName: imageFileDetails.fileName,
              fileType: imageFileDetails.fileType,
              userNew: userNew,
            },
          },
        });
      } else {
        submitSignupDetails();
      }
    }
  };

  const submitSignupDetails = (key = "") => {
    const userNew = { ...formDetails };
    delete userNew.show;
    delete userNew.confirmPassword;

    if (key) {
      userNew.profilePicUrl = key;
    }
    SignUp({ variables: { userNew } });
  };

  const handleSelectImage = () => {
    setOpenAvatarSelector(true);
  };

  const setRowImage = (file) => {
    const parts = file.name.split(".");
    setImageFileDetails({ fileName: file.name, fileType: parts[parts.length - 1] });
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      handleOnSubmit();
    }
  };

  return (
    <>
      <Section>
        <SubSection className="signUpSubSection">
          <ImgContainer
            img={
              formDetails.gender === "MALE"
                ? SingUpMenNew
                : formDetails.gender === "FEMALE"
                ? SignUpFemaleNew
                : SingUpNeutralNew
            }
          />
          <Container
            style={{ zoom: "85%" }}
            className="signupContainer"
            img={
              formDetails.gender === "MALE"
                ? SingUpMenNew
                : formDetails.gender === "FEMALE"
                ? SignUpFemaleNew
                : SingUpNeutralNew
            }
          >
            <BackContainer>
              <SubContainer style={{ zoom: "95%" }}>
                <Title className="signUpTitle">MIKLOSET</Title>
                <H2 className="signUpH2">SIGN UP</H2>
                <UploadIcon style={{ marginBottom: "8px" }}>
                  <div className="circle" onClick={handleSelectImage}>
                    {image ? (
                      <img
                        className="circle"
                        src={image}
                        alt="preview image"
                        style={{ objectFit: "contain" }}
                      />
                    ) : (
                      <IconComponent iconTitle={"CameraBoldIcon"} />
                    )}
                  </div>
                  <Para>Upload Profile Picture</Para>
                </UploadIcon>
                <InputFlex>
                  <InputField
                    labelName={"First Name"}
                    name="firstName"
                    className="signUp"
                    value={formDetails.firstName || ""}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                    errorText={inputErrors.firstName ? "min 3 characters" : ""}
                  />
                  <InputField
                    labelName={"Last Name"}
                    name="lastName"
                    className="signUp"
                    value={formDetails.lastName || ""}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                    errorText={inputErrors.lastName ? "min 3 characters" : ""}
                  />
                </InputFlex>
                <InputFlex>
                  <InputField
                    labelName={"Email"}
                    name="email"
                    className="signUp"
                    value={formDetails.email || ""}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                    type="email"
                    errorText={
                      inputErrors.email
                        ? "Please enter valid email"
                        : apiError?.message === "User already exits with email"
                        ? "User already exits with provided email"
                        : ""
                    }
                  />
                  <InputField
                    labelName={"Phone (optional)"}
                    name="mobile"
                    className="signUp"
                    value={formDetails.mobile || ""}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                  />
                </InputFlex>

                <InputField
                  labelName={"Username"}
                  name="userName"
                  className="signUp"
                  value={formDetails.userName || ""}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyPress}
                  errorText={
                    inputErrors.userName
                      ? "Please enter valid username"
                      : apiError?.message === "User already exits with userName"
                      ? "UserName already exits"
                      : ""
                  }
                />
                <InputField
                  labelName={"Password"}
                  name="password"
                  className="signUp"
                  icon={showPassword ? "EyeBoldCrossIcon" : "EyeBoldIcon"}
                  value={formDetails.password || ""}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyPress}
                  type={showPassword ? "text" : "password"}
                  onIconClick={handleShowPasswordIconClick}
                  errorText={
                    inputErrors.password
                      ? "Password must be minimum 8 characters, including at least 1 uppercase, 1 lowercase, 1 numeric and 1 special character"
                      : ""
                  }
                />
                <InputField
                  labelName={"Confirm Password"}
                  className="signUp"
                  icon={formDetails.show ? "EyeBoldCrossIcon" : "EyeBoldIcon"}
                  name="confirmPassword"
                  value={formDetails.confirmPassword || ""}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyPress}
                  type={formDetails.show ? "text" : "password"}
                  onIconClick={handleIconClick}
                  errorText={inputErrors.confirmPassword ? "Both password need to match" : ""}
                />
                <RadioGroup
                  id={"gender"}
                  labelName="Gender"
                  onChange={handleInputChange}
                  value={formDetails.gender || ""}
                  options={[
                    { name: "Female", value: "FEMALE" },
                    { name: "Male", value: "MALE" },
                    { name: "Gender Neutral", value: "NEUTRAL" },
                  ]}
                />
                <SubmitButton
                  className="signUp"
                  onClick={handleOnSubmit}
                  buttontype="primary"
                  disabled={!!Object.values(inputErrors).filter((ele) => ele).length}
                >
                  Create Account
                </SubmitButton>
                <Para>
                  {/* Already have an account? <Hyper to={"/signin"}>Log In</Hyper> */}
                  Already have an account? <Hyper to={"/signin"}>Log In</Hyper>
                </Para>
              </SubContainer>
            </BackContainer>
          </Container>
        </SubSection>
        {openAvatarSelector && (
          <ModalPortal>
            <AvatarSelector
              setImage={setImage}
              setRowImage={setRowImage}
              setOpenAvatarSelector={setOpenAvatarSelector}
            />
          </ModalPortal>
        )}
      </Section>
      {loading && <Loader />}
    </>
  );
};

export default Signup;
