import { useQuery } from "@apollo/client";
import { useStyles } from "./styles";
import { useEffect, useState } from "react";
import { ReactComponent as LeftArrow } from "../../../assets/svg/arrowLeft.svg";
import { getReceiptContent } from "../../../apollo/operations/receipts";
import Loader from "../../../components/loaderScreen";

export default function ReceiptContent(props) {
  const { handleReciptsId, msgId } = props;
  const [mailsContent, setMailsContent] = useState([]);
  const { data, loading } = useQuery(getReceiptContent, {
    variables: { messageId: msgId },
  });

  const options = {
    month: "short",
    day: "2-digit",
    year: "numeric",
  };
  const date = new Date(parseInt(mailsContent.date)).toLocaleString("en-IN", options);
  const classes = useStyles();

  useEffect(() => {
    if (data) {
      setMailsContent(data.getSingleMail.data);
    }
  }, [data]);

  return (
    <>
      <div className={classes.recieptCategoryHeader} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.subHeader}>
            <div onClick={() => handleReciptsId("")} className={classes.backIcon}>
              <LeftArrow />
            </div>
            {mailsContent.subject}
          </div>
          <div className={classes.header}>
            <div className={classes.mailFrom}>
              {`${mailsContent?.from?.text}`}
              <div className={classes.toMe}>to me</div>
            </div>
            <div className={classes.mailDate}>{date}</div>
          </div>
          <div className={classes.mailViewer}>
            <div dangerouslySetInnerHTML={{ __html: mailsContent.html?.toString() }} />
          </div>
        </>
      )}
    </>
  );
}
