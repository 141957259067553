import { useState } from "react";
import IconComponent from "../../components/fields/icon";
import {
  ModalContainer,
  Overflow,
  ButtonContainter,
  MainText,
  SubText,
} from "../../components/modal";
import Button from "../../components/fields/button";
import { useStyles } from "./styles";
import { useDispatch } from "react-redux";
import {
  setShowAddyourStylePopup,
  setNotShowAYSPopup,
  setShowPopup,
} from "../../store/showPopupSlice";
import { useMutation } from "@apollo/client";
import { updateUserProfile } from "../../apollo/operations/user";
import { useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import ModalPortal from "../../containers/common/modalPortal";
import VideeoTour from "./VideoTour";

export default function WelcomePopup({ userData, setShowWelcomePopup }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [doNotShow, setDoNotShow] = useState(false);
  const [updateShowPopup] = useMutation(updateUserProfile);
  const [openVideoTour, setOpenVideoTour] = useState(null);

  function handleVideoModal() {
    setOpenVideoTour(!openVideoTour);
  }

  function handleOnClick(value) {
    dispatch(setShowPopup(false));
    dispatch(setNotShowAYSPopup(false));
    setShowWelcomePopup(false);
    if (doNotShow) {
      updateShowPopup({
        variables: { userDetails: { showPopup: !doNotShow } },
      });
    }
    if (userData.newPurchasePopup) {
      updateShowPopup({
        variables: { userDetails: { newPurchasePopup: false } },
      });
    }
    if (value) {
      if (userData.newPurchasePopup) navigate("/notifications");
      else navigate("/addyourstyle");
    }
  }

  return (
    <Overflow>
      <ModalContainer>
        <div className={classes.imageContainer}>
          {userData.profilePicUrl ? (
            <Avatar alt={userData?.firstName.toUpperCase()} src={userData.profilePicUrl} />
          ) : (
            <IconComponent iconTitle="NoProfile" />
          )}
        </div>
        <MainText>Hey {userData.firstName}!</MainText>

        {userData.newPurchasePopup ? (
          <SubText>{"You have new purchases. Would you like to add them now?"}</SubText>
        ) : (
          <SubText>
            {"Welcome to your curated experience."}
            <br />
            {"Would you like to add items to your closet?"}
          </SubText>
        )}
        <ButtonContainter>
          <Button buttontype="primary" size="default" onClick={() => handleOnClick(true)}>
            Add Now
          </Button>
          <Button buttontype="secondary" size="default" onClick={() => handleOnClick(false)}>
            Close
          </Button>
        </ButtonContainter>
        <div className={classes.videoTour} style={{ color: "#FB5B01" }} onClick={handleVideoModal}>
          Video Tour
        </div>
        {!userData.newPurchasePopup && (
          <div
            className={classes.checkboxWrapper}
            onClick={() => setDoNotShow((preVal) => !preVal)}
          >
            <IconComponent
              className={classes.checkboxIcon}
              iconTitle={doNotShow ? "BoldCheckBox" : "LightCheckBox"}
            />
            Do Not Show Again
          </div>
        )}
      </ModalContainer>
      {openVideoTour && (
        <ModalPortal>
          <VideeoTour modalResponse={handleVideoModal} title={"How To Mikloset"} />
        </ModalPortal>
      )}
    </Overflow>
  );
}
