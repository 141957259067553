import { createSlice } from "@reduxjs/toolkit";

export const isPreviousScanSlice = createSlice({
  name: "isPreviousScan",
  initialState: true,
  reducers: {
    setIsPreviousScan: (state, action) => action.payload,
  },
});

export const { setIsPreviousScan } = isPreviousScanSlice.actions;

export default isPreviousScanSlice.reducer;
