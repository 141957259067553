import { makeStyles } from "@material-ui/styles";
import { smallTabMediaStyle, tabMediaStyle } from "../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  notificationBody: {
    padding: "40px 60px",
    fontFamily: "var(--primaryRegularFont)",
  },
  header: {
    fontFamily: "var(--primaryHeaderFont)",
    fontSize: "32px",
    lineHeight: "40px",
    fontWeight: "500",
    paddingBottom: "12px",
  },

  BodyWrapper: {
    display: "flex",
    flexDirection: "row",
  },

  rightBody: {
    width: "82%",
    maxWidth: "calc( 100% - 300px)",
  },

  rightHeader: {
    height: "100px",
    width: "100%",
    borderBottom: "1px solid #D9D9D9",
    boxSizing: "border-box",
  },

  sectionHr: {
    display: "none",
    border: "none",
    borderTop: "0.5px solid #D9D9D9",
  },

  dateHeader: {
    fontSize: "24px",
    lineHeight: "28px",
    fontWeight: "400",
    paddingTop: "40px",
  },

  notificationWrapper: {
    display: "flex",
    paddingTop: "24px",
  },

  logo: {
    height: "50px",
    width: "50px",
    borderRadius: "25px",
    background: "rgba(0, 0, 0, 0.06)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "18px",
  },

  profileImage: {
    height: "50px",
    width: "50px",
    objectFit: "contain",
    borderRadius: "25px",
  },

  iconImage: {
    height: "25px",
    width: "25px",
    borderRadius: "24px",
  },

  productImageContainer: {
    height: "50px",
    width: "50px",
    marginRight: "12px",
    position: "relative",
  },
  productImage: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
    fontSize: "10px",
  },

  content: {
    flex: 1,
  },

  subHeader: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "2px",
  },

  subject: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
  },

  message: {
    fontSize: "16px",
    lineHeight: "18px",
  },

  creationDetails: {
    fontSize: "16px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },

  responseSection: {
    display: "flex",
    gap: "12px",
    marginRight: "26px",
  },

  responseButton: {
    width: "32px",
    height: "32px",
    cursor: "pointer",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  addButton: {
    backgroundColor: "rgba(0, 114, 255, 0.2)",

    "&:hover": {
      backgroundColor: "rgba(0, 114, 255, 0.4)",
    }
  },

  acceptButton: {
    backgroundColor: "rgba(46, 187, 85, 0.2)",

    "&:hover": {
      backgroundColor: "rgba(46, 187, 85, 0.4)",
    },
  },

  rejectButton: {
    backgroundColor: "rgba(255, 0, 0, 0.2)",

    "&:hover": {
      backgroundColor: "rgba(255, 0, 0, 0.4)",
    },
  },

  clockSvg: {
    paddingRight: "14px",
  },

  deleteSvg: {
    paddingLeft: "24px",
    cursor: "pointer",
    color: "#FF0000",
  },

  dateAndTime: {
    display: "flex",
    alignItems: "center",
  },

  emptyNotification: {
    fontSize: "24px",
    height: "200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  countClass: {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "#808080b5",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontSize: "16px",
  },
  sectionHeader: {
    display: "none",
    fontSize: "16px",
    fontWeight: "400",
    letterSpacing: "0.48px",
    margin: "0 16px",
    paddingBottom: "12px",
  },

  ...tabMediaStyle({
    notificationBody: {
      padding: "30px 40px",
    },
    header: {
      fontSize: "30px",
      lineHeight: "36px",
      paddingBottom: "8px",
    },
    rightBody: {
      maxWidth: "calc( 100% - 220px)",
    },
    rightHeader: {
      height: "75px",
    },
    dateHeader: {
      fontSize: "20px",
      lineHeight: "24px",
      paddingTop: "30px",
    },
    notificationWrapper: {
      paddingTop: "20px",
    },
    logo: {
      width: "46px",
      height: "46px",
      marginRight: "10px",
      borderRadius: "50%",
    },
    subject: {
      fontSize: "14px",
      lineHeight: "18px",
    },
    message: {
      fontSize: "14px",
      lineHeight: "16px",
    },
    creationDetails: {
      fontSize: "14px",
      lineHeight: "16px",
    },
  
    responseSection: {
      gap: "10px",
      marginRight: "10px",
    },
    clockSvg: {
      paddingRight: "10px",
    },
    deleteSvg: {
      paddingLeft: "15px",
    },
    responseButton: {
      width: "24px",
      height: "24px",
    },
  }),

  ...smallTabMediaStyle({
    rightHeader: {
      display: "none",
    },
    rightSubBody: {
      margin: "0 16px",
    },
    notificationBody: {
      padding: "18px 0",
    },
    header: {
      display: "none",
    },
    BodyWrapper: {
      flexDirection: "column",
    },
    dayWrapper: {
      display: "flex",
        flexDirection: "column",
        gap: "16px",
    },
    rightBody: {
      maxWidth: "unset",
      width: "100%",
    },
    sectionHeader: {
      display: "block",
    },
    dateHeader: {
      fontSize: "16px",
      paddingTop: "24px",
      lineHeight: "unset",
    },
    notificationWrapper: {
      padding: "0",
      alignItems: "center",
    },
    content: {
      fontSize: "12px",
      letterSpacing: "0.36px",
      width: "calc(100% - 48px)",
    },
    subject: {
      fontSize: "12px",
      lineHeight: "14px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    creationDetails: {
      fontSize: "12px",
      lineHeight: "unset",
      fontWeight: "400",
    },
    clockSvg: {
      display: "none",
    },
    dateAndTime: {
      letterSpacing: "0.3px",
    },
    logo: {
      width: "36px",
      height: "36px",
      marginRight: "12px",
    },
    iconImage: {
      width: "18px",
      height: "18px",
    },
    productImageContainer: {
      width: "30px",
      height: "30px",
      marginRight: "6px",
    },
    countClass: {
      fontSize: "10px",
    },
    deleteSvg: {
      width: "12px",
      height: "12px",
      paddingLeft: "6px",
    },
    responseSection: {
      marginRight: "8px",
      gap: "6px",
    },
    responseButton: {
      width: "18px",
      height: "18px",
      borderRadius: "50%",
    },
    
    subHeader: {
      whiteSpace: "nowrap",
    },
    sectionHr: {
      display: "block",
    },
  }),
});
