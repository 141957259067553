import { useStyles } from "./styles";
export default function MailRow(props) {
  const { email, index, onClick, onClickPrevious } = props;
  const isoToLocalTime = (startTimeISOString) => {
    var dateTime = new Date(startTimeISOString);
    dateTime = new Date(dateTime.getTime() - dateTime.getTimezoneOffset() * 60000);
    var mm = dateTime.getMonth() + 1; // getMonth() is zero-based
    var dd = dateTime.getDate();
    return `${mm}/${dd}`;
  };

  const { fromDate, toDate } = {
    fromDate: props?.previousScan ? isoToLocalTime(`${JSON.parse(props?.previousScan).from}`) : "",
    toDate: props?.previousScan ? isoToLocalTime(`${JSON.parse(props?.previousScan).to}`) : "",
  };

  const previousScanDate = props?.previousScan
    ? `Previous Scan (${fromDate} - ${toDate})`
    : "Previous Scan";
  const classes = useStyles();
  function BottomLine() {
    return <div className={classes.bottomLine} />;
  }

  return (
    <>
      {index >= 1 && <BottomLine />}
      <div className={classes.mailDetails}>
        <div className={classes.mailDetailsSub} onClick={() => onClick(email)}>
          <div className={classes.avatar}>
            <img
              className={classes.avatar}
              src={email?.profileUrl}
              onError={({ currentTarget }) => (currentTarget.style.display = "none")}
            />
          </div>
          <div className={classes.mailBody}>{email?.email}</div>
        </div>
        {email?.previousScan !== null && email?.hasAccess && (
          <div
            className={`${classes.previousButton} ${classes.activePreviousButton}`}
            onClick={() => onClickPrevious(email)}
          >
            {previousScanDate}
          </div>
        )}
      </div>
    </>
  );
}
