import { makeStyles } from "@material-ui/styles";
import { mobileMediaStyle } from "../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  modalBox: {
    width: "700px",
    padding: "35px 25px 40px 25px",
    minHeight: "fit-content",
    top: "54%",
  },
  desktopViewImage: { display: "block", width: "650px" },
  mobileViewImage: { display: "none" },
  mainText: { fontWeight: 500, fontSize: "18px", paddingBottom: "10px" },
  subText: { textAlign: "start", fontSize: "16px" },
  exampleText: { padding: "5px 0px", fontSize: 12 },
  checkboxWrapper: {
    display: "flex",
    padding: "0 0 0 8px",
    fontFamily: "var(--primaryRegularFont)",
    fontSize: "18px",
    fontWeight: "400",
    letterSpacing: "0.6",
    alignItems: "center",
    cursor: "pointer",
    gap: 15,

    justifyContent: "center",
    paddingTop: "15px",

    "@media (max-width: 900px)": {
      fontSize: "16px",
      padding: "0 0 0 17px",
    },

    "@media (max-width: 450px)": {
      fontSize: "12px",
      padding: "0 0 0 12px",
    },
  },

  checkboxIcon: {
    width: "23px",
    height: "23px",

    "@media (max-width: 900px)": {
      width: "20px",
      height: "20px",
    },

    "@media (max-width: 450px)": {
      width: "18px",
      height: "18px",
    },
  },
  ...mobileMediaStyle({
    modalBox: {
      width: "300px",
      padding: "35px 25px 40px 25px",
      minHeight: "fit-content",
      top: "50%",
    },
    mainText: { fontWeight: 500, fontSize: "14px", paddingBottom: "10px" },
    desktopViewImage: { display: "none" },
    mobileViewImage: { display: "block", fontSize: 12 },
    checkboxIcon: {
      width: 15,
      height: 15,
    },
    subText: { textAlign: "start", fontSize: "12px" },
    checkboxWrapper: {
      paddingTop: "10px",
      fontSize: 12,
      letterSpacing: 0.36,
      gap: 10,
    },
  }),
});
