import { useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { getNotifications } from "../apollo/operations/notifications";
import { useEffect } from "react";
import { setUnreadCount } from "../store/socketSlice";

const useNotification = () => {
  const dispatch = useDispatch();
  const [getNotificationAPI, { data: notificationsData }] = useLazyQuery(getNotifications);

  const fetchNotifications = (val) => {
    if(val === "viewAll"){
      getNotificationAPI();
    }
    else {
      getNotificationAPI({variables : {type: val}});
    }
  };

  useEffect(() => {
    if (notificationsData?.getNotificationDetails?.data)
      dispatch(
        setUnreadCount(
          notificationsData.getNotificationDetails.data.filter((item) => !item.isRead).length,
        ),
      );
  }, [notificationsData]);

  return {
    notificationsData,
    fetchNotifications,
  };
};

export default useNotification;
