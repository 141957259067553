import { gql } from "@apollo/client";

export const getStyleMe = gql`
  query GetStyleMe {
    getStyleMe {
      statusCode
      message
      error
      data
    }
  }
`;

export const addStyleMe = gql`
  mutation AddStyleMe($styleMeData: StyleMeData!) {
    addStyleMe(styleMeData: $styleMeData) {
      statusCode
      message
      error
      data
    }
  }
`;
