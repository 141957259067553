import { useEffect, useState } from "react";
import StyleMe from "./StyleMe";
import { useSelector } from "react-redux";
import Loader from "../../components/loaderScreen";

export default function CurateMe() {
  const categoriesList = useSelector((state) => state.category.category);
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (categoriesList?.length) {
      setShow(true);
    }
  }, [categoriesList]);

  return (
    <>
      {!show && <div> </div>}
      {show ? <StyleMe /> : <Loader />}
    </>
  );
}
