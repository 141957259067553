import { useState, useEffect } from "react";
import styled from "styled-components";

const OtpContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
  column-gap: 15px;

  > input {
    width: 100%;
    max-width: 144px;
    max-height: 120px;
    height: inherit;
    text-align: center;
    border: 1.5px solid black;
    font-family: var(--primaryHeaderFont);
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
  }
`;

const Form = styled.form`
  width: 100%;
  position: relative;
  padding-bottom: 15%;
  margin-bottom: auto;
`;

const OTPInput = ({ className, handleChange, otpLength = 4 }) => {
  const [otp, setOtp] = useState({});

  useEffect(() => {
    let value = "";
    Object.values(otp).forEach((ele) => (value += ele));
    handleChange(value);
  }, [otp]);

  const handleInputChange = (event) => {
    const {
      target: { name, value },
    } = event;

    if (!isNaN(value) || value === "") {
      setOtp((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const inputFocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else if (!isNaN(elmnt.key)) {
      const next = elmnt.target.tabIndex;
      if (next < otpLength) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  return (
    <Form className={className}>
      <OtpContainer>
        {[...Array(otpLength)].map((_, index) => (
          <input
            key={"otp" + index}
            name={"otp" + index}
            type="text"
            autoComplete="off"
            className="otpInput"
            value={otp["otp" + index] || ""}
            onChange={handleInputChange}
            tabIndex={index + 1}
            maxLength="1"
            onKeyUp={inputFocus}
          />
        ))}
      </OtpContainer>
    </Form>
  );
};

export default OTPInput;
