import { useMemo } from "react";
import { useQuery } from "@apollo/client";

import ME from "../apollo/operations/user";

export default () => {
  const { data, error, loading, refetch } = useQuery(ME);

  const currentUser = useMemo(() => data?.me?.User, [data,loading]);

  return {
    currentUser,
    error,
    loading,
    refetch,
  };
};
