import ActivewearWomen from "./activewear_women.png";
import AccessoriesWomen from "./accessories_women.png";
import BeautyWomen from "./beauty_women.png";
import BeachwearWomen from "./beachwear_women.png";
import DenimWomen from "./denim_women.png";
import DressesWomen from "./dresses_women.png";
import JumpsuitWomen from "./jumpsuit_women.png";
import LingerieWomen from "./lingerie_women.png";
import LoungewearWomen from "./loungewear_women.png";
import MiscellaneousWomen from "./miscellaneous_women.png";
import OuterwearWomen from "./outerwear_women.png";
import PantsWomen from "./pants_women.png";
import RompersWomen from "./rompers_women.png";
import ShoesWomen from "./shoes_women.png";
import ShortsWomen from "./shorts_women.png";
import SkirtsWomen from "./skirts_women.png";
import SweatersWomen from "./sweaters_women.png";
import TopsWomen from "./tops_women.png";
import HangerWomen from "./hanger_women.png";
import HangerWomenNew from "./hanger_women.jpg";
import HomeWomen from "./home_women.png";

export default {
  ActivewearWomen,
  AccessoriesWomen,
  BeautyWomen,
  BeachwearWomen,
  DenimWomen,
  DressesWomen,
  JumpsuitWomen,
  LingerieWomen,
  LoungewearWomen,
  MiscellaneousWomen,
  OuterwearWomen,
  PantsWomen,
  RompersWomen,
  ShoesWomen,
  ShortsWomen,
  SkirtsWomen,
  SweatersWomen,
  TopsWomen,
  HangerWomen,
  HangerWomenNew,
  HomeWomen,
};
