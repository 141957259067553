import { useEffect, useState } from "react";
import {
  ButtonContainter,
  CircleSvg,
  MainText,
  ModalContainer,
  Overflow,
  SvgContainer,
} from "../../../components/modal";
import Button from "../../../components/fields/button";
import IconComponent from "../../../components/fields/icon";
import { InputField } from "../../../components/signin";
import { useStyles } from "./styles";
import useFocus from "../../../hooks/useFocus";

export default function AddCategoryPopup(props) {
  const classes = useStyles();
  const { modalResponse } = props;
  const [newCategoryName, setNewCategoryName] = useState("");
  const [inputError, setInputError] = useState(false);
  const [inputRef, setFocus] = useFocus();

  useEffect(() => {
    setTimeout(() => setFocus(), 0);
  }, []);

  function handleChoiceResult(value) {
    if (value) {
      if (newCategoryName === "") {
        setInputError(true);
      } else {
        modalResponse(value, newCategoryName);
      }
    } else modalResponse(value);
  }
  function handleChange(e) {
    setNewCategoryName(e.target.value);
    if (inputError) {
      setInputError(false);
    }
  }
  function handleKeyPress(event) {
    if (event.keyCode === 13) {
      handleChoiceResult(true);
    }
  }

  return (
    <Overflow>
      <ModalContainer style={{ minHeight: "unset" }}>
        <SvgContainer className={classes.addCategorySvgCont}>
          <CircleSvg>
            <IconComponent iconTitle={"AddCircle"} />
          </CircleSvg>
        </SvgContainer>

        <MainText style={{ marginBottom: "16px" }}>{"New Sub-Category Name"}</MainText>
        <InputField
          labelName={"Name"}
          name="name"
          value={newCategoryName}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          autoFocus={true}
          type="text"
          placeholder="Sub Category"
          errorText={inputError ? "Please enter category name" : ""}
          inputRef={inputRef}
        />

        <ButtonContainter style={{ paddingTop: "14px" }}>
          <Button buttontype="primary" size="default" onClick={() => handleChoiceResult(true)}>
            {"Save"}
          </Button>
          <Button buttontype="secondary" size="default" onClick={() => handleChoiceResult(false)}>
            {"Cancel"}
          </Button>
        </ButtonContainter>
      </ModalContainer>
    </Overflow>
  );
}
