import { useEffect } from "react";
import Curation from "./Curation";
import { useDispatch } from "react-redux";
import getCurations from "../../hooks/useGetCuration";
import { setCurateData } from "../../store/curationSlice";

export default function CurateMe() {
  const { ListOfCuration, loading } = getCurations();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      // filter isDeleted items from incoming table. those items are favorate items and should not be visible in curate me.
      const filterCuration = ListOfCuration?.filter((item) => !item.isDeleted);
      dispatch(setCurateData(filterCuration));
    }
  }, [loading]);

  return <Curation loading={loading} />;
}
