import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useOutlet, useLocation } from "react-router-dom";
import ApolloClient from "../apollo/apolloClient";
import { setCategory } from "../store/categorySlice";

import useQueryMe from "./useQueryMe";
import { createSocketConnection, removeSocketConnection } from "../components/helpers/socketHelper";
import { setShowAddyourStylePopup } from "../store/showPopupSlice";

const AuthContext = createContext();
const apolloClient = ApolloClient();

export const AuthProvider = ({ children }) => {
  const [user, setUser, removeUser] = useGetUser();

  // call this function when you want to authenticate the user
  const login = setUser;

  // call this function to sign out logged in user
  const logout = removeUser;

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export const useGetUser = () => {
  const [user, setUser] = useState();
  const { currentUser, loading, refetch: refetchUser } = useQueryMe();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser) {
      dispatch(setCategory(currentUser.category));
      dispatch(setShowAddyourStylePopup(currentUser?.addYourStylePopup));
      setUser(currentUser);

      // Socket Initialization
      createSocketConnection(currentUser, dispatch);
    } else if (!loading && !currentUser) {
      setUser({});
    }
  }, [currentUser, loading]);

  useEffect(() => {
    if (
      user?.firstName &&
      // (location.pathname === "/signin" ||
      //   location.pathname === "/signup" ||
      //   location.pathname === "/")
      (location.pathname === "/signin" ||
        location.pathname === "/signup" ||
        location.pathname === "/")
    ) {
      navigate("/dashboard");
      console.log("/dashboard");
    }
  }, [user]);

  const setValue = (value) => {
    window.localStorage.setItem("User", JSON.stringify({ token: value }));
    refetchUser();
  };

  const removeValue = () => {
    try {
      window.localStorage.removeItem("User");
      window.localStorage.removeItem("addyourStylePopup");
      setUser({});
      navigate("/signin");
      // navigate("/signin");
      apolloClient.clearStore();

      // Socket Disconnection
      removeSocketConnection();
    } catch (err) {
      console.log(err);
    }
  };
  return [user, setValue, removeValue];
};

export const AuthLayout = () => {
  const outlet = useOutlet();

  return <AuthProvider>{outlet}</AuthProvider>;
};
