import { makeStyles } from "@material-ui/styles";
import { mobileMediaStyle, tabMediaStyle } from "../../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  settingCategoryHeader: {
    height: "100px",
    width: "100%",
    borderBottom: "1px solid #D9D9D9",
    boxSizing: "border-box",
  },
  subHeader: {
    padding: "40px 0 20px",
    fontSize: "20px",
    lineHeight: "23px",
    fontWeight: "400",
    // fontFamily: "var(--primaryRegularFont)",
  },
  scanEmailHeader: {
    height: "100px",
    width: "100%",
    borderBottom: "1px solid #D9D9D9",
    boxSizing: "border-box",
  },
  buttonContainer: {
    display: "flex",
    columnGap: "24px",
    justifyContent: "right",
    alignItems: "center",
    marginTop: "6rem",
    marginBottom: "1rem",
  },
  avatar: {
    height: "40px",
    width: "40px",
    backgroundColor: "#c6c6c6",
    borderRadius: "20px",

  },
  emailOuterContainer: {
    paddingTop: "0.5rem",
  },
  emailHeading: {
    paddingBottom: "0.7rem",
  },
  emailContainerBody: {
    border: "1px solid black",
    padding: "0px 5px 5px 15px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "space-between",
  },
  emailDetail: {
    display: "flex",
    gap: "0.7rem",
    alignItems: "center",
    textAlign: "center",
    fontSize: "20px",
    marginTop: "5px",
  },
  datePicker: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },

  },
  selectWrapper: {
    height: "48px",
    width: "210px",
    border: "1px solid #000000",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    marginTop: "5px",

    "@media (max-width: 450px)": {
      height: "42px",
    },

    "& .MuiSelect-icon": {
      margin: "5px 10px 0 0",
    },
    "& .MuiSelect-select": {
      textAlign: "left",
      padding: "15px 13px",
      fontSize: "16px",
    },
  },
  addButton: {
    marginTop: "1rem",
    display: "flex",
    gap: "0.5rem",
    cursor: "pointer",
    width: "fit-content",
    alignItems: "center",
  },
  subInputValue: {
    width: "40%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  fromToDate: {
    display: "flex",
    height: "46px",
    gap: "20px",
    alignItems: "center",
    width: "fit-content",
    margin: "12px 0 0 auto",
    border: "1px solid #000",
    padding: "0 19px",

    "& .MuiInputBase-input": {
      padding: "3px 3px 0",
      width: "152px",
      borderBottom: "1px solid #000",
      letterSpacing: "0.03em",
    },
    "& label": {
      fontSize: "20px",
      marginRight: "3px",
    },
  },
  scanEmailContainer: {
    display: "flex",
    flexDirection: "column",
  },
  selectWrapperMobile: {
    display: "none",
  },
  selectForm: {
    position: "absolute",
    display: "flex",
    width: "calc(100% - 26px)",
    height: "calc(100% - 30px)",
    alignItems: "center",
    justifyContent: "left",
    padding: "15px 13px",

    "& > i": {
      position: "absolute",
      right: "17px",
    },
  },

  ...tabMediaStyle({
    settingCategoryHeader: {
      height: "75px",
    },
    subHeader: {
      padding: "30px 0 10px",
    },
    subInputValue: {
      width: "46%",
    },
    emailDetail: {
      gap: "0.5rem",
      fontSize: "18px",
    },
  }),

  ...mobileMediaStyle({
    settingCategoryHeader: {
      display: "none",
    },
    subHeader: {
      padding: "12px 0 16px",
      fontSize: "16px",
    },
    scanEmailContainer: {
      margin: "0 16px",
    },
    emailOuterContainer: {
      padding: 0,
    },
    emailHeading: {
      fontSize: "14px",
      paddingBottom: "6px",
      fontWeight: 300,
    },
    emailContainerBody: {
      padding: "0 12px",
      height: "46px",
      marginBottom: "6px",
    },
    emailDetail: {
      gap: "12px",
      fontSize: "14px",
      fontWeight: 400,
    },
    selectWrapper: {
      display: "none",
    },
    selectWrapperMobile: {
      display: "flex",
      height: "46px",
      border: "1px solid #000000",
      boxSizing: "border-box",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",

      "& .MuiSelect-icon": {
        margin: "5px 10px 0 0",
      },
      "& .MuiSelect-select": {
        textAlign: "left",
        padding: "11px 13px",
        fontSize: "14px",
      },
    },
    addButton: {
      fontSize: "14px",
      gap: "5px",
      marginTop: "16px",
      lineHeight: "11px",
    },
    emailContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    },
    fromToDate: {
      margin: 0,
      padding: "0 12px",
      width: "inherit",
      justifyContent: "space-between",
      height: "44px",

      "& .MuiInputBase-input": {
        padding: "3px 3px 0",
        width: "82px",
        // borderBottom: "1px solid #000",
        borderBottom: "none",
        letterSpacing: "0.36px",
      },

      "& label": {
        fontSize: "14px",
        marginRight: "3px",
      },
    },
    selectForm: {
      fontSize: "14px",
    },
    avatar: {
      height: "24px",
      width: "24px",
      borderRadius: "12px",
    },
    datePicker: {
      "& .MuiInputBase-input": {
        padding: "2px 0px 2px 10px",
        fontSize: "12px",
      },
    },
  }, "@media (max-width:600px)"),
});
