import { makeStyles } from "@material-ui/styles";
import { mobileMediaStyle, tabMediaStyle } from "../../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  recieptCategoryHeader: {
    height: "100px",
    width: "100%",
    borderBottom: "1px solid #D9D9D9",
    boxSizing: "border-box",
  },
  subHeader: {
    padding: "40px 0 20px",
    fontSize: "20px",
    lineHeight: "23px",
    fontWeight: "400",
    display: "flex",
  },
  header: {
    // display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "0px 25% 0px 7%",
  },
  paggination: {
    marginTop: "50px",
    padding: "10px 0 10px",
    justifyContent: "right",
    display: "flex",
    fontSize: ".75rem",
    letterSpacing: "normal",
    color: "#5e5e5e",
  },
  deleteAll: {
    display: "grid",
    gridTemplateColumns: "0.1fr 0.1fr",
    position: "absolute",
    right: "-65px",
    top: "-5px",
  },
  topIcons: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr"

  },
  arrowLeft: {
    padding: "0px 5px",
    rotate: "90deg",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#D9D9D9",
    },
  },
  arrowRight: {
    padding: "0px 5px",
    rotate: "-90deg",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#D9D9D9",
    },
  },
  searchTag: {
    // margin: "0px 25% 20px 7%",
    overflow: "hidden",
    // display: "flex",
    // flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#efefef",
    // backgroundColor :"black",
    borderRadius: "25px",
    boxShadow: "0 1px 4px #8f8f8f",
    width: "60%",
    outline: "none"
  },
  search_: {
    position: "absolute",
    // margin: "0px 25% 20px 7%",
    overflow: "hidden",
    // display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#efefef",
    // backgroundColor :"black",
    borderRadius: "25px",
    boxShadow: "0 1px 4px #8f8f8f",
    width: "30%",
    zIndex: "3"
  },
  searchInput: {
    outline: "none",
    border: "none",
    backgroundColor: "#efefef",
    paddingLeft: "10px",
    width: "100%",
    borderRadius: "18px",
    fontFamily: "Roboto",
  },
  searchButton: {
    outline: "none",
    border: "none",
    backgroundColor: "#efefef",
    float: "left",
    borderRadius: "25px",
    cursor: "pointer",
    padding: "8px",
    margin: "2px",
    "&:hover": {
      backgroundColor: "#8080803b",
    },
    "&:active": {
      backgroundColor: "gray",
    },
  },
  crossButton: {
    outline: "none",
    border: "none",
    backgroundColor: "#efefef",
    float: "left",
    borderRadius: "25px",
    cursor: "pointer",
    padding: "3px",
    margin: "2px",
    "&:hover": {
      backgroundColor: "#8080803b",
    },
    "&:active": {
      backgroundColor: "gray",
    },
  },

  searchDropdown: {

    borderTop: "solid 1px gray",

    fontSize: "14px",
    zIndex: "2",
    backgroundColor: "#efefef",
    // backgroundColor: "black",
    padding: "10px 0",
    display: "flex",
    // position:"absolute",
    flexDirection: "column",
  },
  mailDropDown: {
    backgroundColor: "#efefef",
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: "#D9D9D9",
    },
    "&:active": {
      backgroundColor: "gray",
    },
  },
  suggestion: {
    fontWeight: "bold",
    fontSize: "14px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "10px",
    fontFamily: "Roboto",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "#D9D9D9",
    },
    "&:active": {
      backgroundColor: "gray",
    },
  },
  dropDownSubject: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "10px",
    fontFamily: "Roboto",
    fontSize: "14px"

  },
  dropDownDate: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "10px",
    fontFamily: "Roboto",
    fontSize: "12px"
  },
  table: {
    margin: "0px 25% 0px 7%",
  },
  dateHeader: {
    justifyContent: "right",
    display: "flex",
    cursor: "pointer",
    marginRight: "3%"
  },
  headerRows: {
    fontWeight: "bold",
    display: "grid",
    gridTemplateColumns: "0.6fr 2fr 0.65fr",
    borderBottom: "1px solid #D9D9D9",
    padding: "10px",
    backgroundColor: "#D9D9D9",
  },
  rows: {
    display: "grid",
    gridTemplateColumns: "0.6fr 2fr 0.65fr",
    cursor: "pointer",
    borderBottom: "1px solid #D9D9D9",
    padding: "10px",
    // paddingTop: "15px",
    "&:hover": {
      backgroundColor: "#D9D9D9",
    },
  },
  hideIcon: {
    visibility: "hidden"
  },
  deleteIcon: {
    justifyContent: "center",
    marginRight: "5px",
    // height: "fit-content",
    width: "20px",
    border: "none",
    transform: "translate(50%, 10%)"
  },
  dateWrapper: {
    position: "relative", 
  },
  selectIcon: {
    justifyContent: "center",
    marginRight: "5px",
    // height: "fit-content",
    width: "20px",
    border: "none",
    transform: "translate(50%, 10%)",
    position: "absolute",
    right: "-40px",
    top: "-1px",
  },
  deleteBlackIcon: {
    "& > path":{
      stroke: "#000"
    }
  },
  selectAll: {
    paddingTop: "5px"
  },
  selectAllBlack: {
    paddingTop: "5px",
    "& > path": {
      stroke: "black"
    }
  },
  radioButton: {
    marginRight: "5px",
    width: "20px",
  },
  senderName: {
    fontWeight: "bold",
    fontSize: "14px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    transform: "translateY(25%)"
  },
  subject: {
    fontSize: "14px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    transform: "translateY(25%)"
  },
  fromMail: {
    fontSize: "14px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  mailDate: {
    textAlign: "right",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: ".75rem",
    letterSpacing: "normal",
    color: "#5e5e5e",
    display: "block",
    lineHeight: "20px",
    transform: "translateY(25%)"
  },

  backIcon: {
    cursor: "pointer",
    fontSize: "15px",
    margin: "0px 16px",
  },
  mailFrom: {
    fontWeight: "bold",
    fontSize: "14px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  toMe: {
    fontSize: ".75rem",
    letterSpacing: "normal",
    color: "#5e5e5e",
    lineHeight: "20px",
  },

  mailViewer: {
    width: "100%",
    overflow: "scroll",
    margin: "0px 16px",
  },
  arrowSort: {
    marginTop: "7px",
    marginRight: "10px",
    marginLeft: "5px",
    opacity: 0,
    "&:hover": {
      opacity: 1
    },
  },

  ...tabMediaStyle({
    recieptCategoryHeader: {
      height: "75px",
    },
    subHeader: {
      padding: "30px 0 10px",
    },
    mailViewer: {
      zoom: 0.63,
    },
    paggination: {
      width: "97%"
    },
    table: {
      margin: "0px 16px",
      width: "90%"
    },
    header: {
      margin: "0px 16px",
    },
    search_: {
      width: "50%"
    }
  }),

  ...mobileMediaStyle(
    {
      recieptCategoryHeader: {
        display: "none",
      },
      subHeader: {
        padding: "12px 0 16px",
        fontSize: "16px",
      },
      paggination: {
        padding: "12px 0 16px",
        width: "86%"
      },
      mailViewer: {
        width: "100%",
        overflow: "scroll",
        zoom: 0.4,
      },
      table: {
        margin: "0px 16px",
        width: "80%"
      },
      header: {
        margin: "0px 16px",
      },
      search_: {
        width: "93%"
      }
    },
    "@media (max-width:600px)",
  ),
});
