import { gql } from "@apollo/client";

export const gqlJoinWaitList = gql`
  mutation JoinWaitList($addWaitListInfo: waitListInput!) {
    joinWaitList(addWaitListInfo: $addWaitListInfo) {
      data
      error
      message
      statusCode
    }
  }
`;

export const gqlConfirmEmail = gql`
  mutation ConfirmEmail($confirmEmailReq: confirmEmailInput!) {
    confirmEmail(confirmEmailReq: $confirmEmailReq) {
      data
      error
      message
      statusCode
    }
  }
`;

export const gqlGetWaitList = gql`
  query GetWaitList($waitListRequest: getWaitListInput!) {
    getWaitList(waitListRequest: $waitListRequest) {
      data {
        count
        waitList {
          ageRange
          createdAt
          email
          firstName
          gender
          id
          igHandle
          isMailConfirmed
          lastName
          status
          updatedAt
        }
      }
      error
      message
      statusCode
    }
  }
`;

export const gqlUpdateWaitList = gql`
  mutation UpdateWaitList($updateWaitListRes: updateWaitListInput!) {
    updateWaitList(updateWaitListRes: $updateWaitListRes) {
      data
      error
      message
      statusCode
    }
  }
`;
export const gqlAgeGenderReport = gql`
  query AgeGenderReport {
    ageGenderReport {
      statusCode
      message
      error
      data
    }
  }
`;
