export const modalTypes = {
  createCloset: {
    headerSvg: "CreateCloset",
    mainText: "Let’s Create your Closet",
    subText: "Maintain your closet and add the items you use everyday. Would you like to add now?",
    acceptButton: "Add Now",
    rejectButton: "Later",
  },

  chatItemNotFound: {
    headerSvg: "ModelCrossIcon",
    mainText : "No Items found matching your criteria.",
    subText: "Here are some similar suggestions.",
    acceptButton: "OK",
  },

  AreYouSure: {
    headerSvg: "QuestionMark",
    mainText: "User Guide",
    subText: "Random file",
    // acceptButton: "Now",
    // rejectButton: "Never",
  },

  privacyInfo: {
    headerSvg: "PrivacyInfo",
    mainText: "Privacy Info",
    subText:
      "We will scan your email for receipt information, this will not affect your privacy. Shall we proceed?",
    acceptButton: "Yes",
    rejectButton: "No",
  },

  deleteNotification: {
    headerSvg: "Trash",
    mainText: "Delete Notification",
    subText: "Are you sure to delete this notification",
    acceptButton: "Yes",
    rejectButton: "No",
  },

  hideCategory: {
    headerSvg: "EyeLightCrossIcon",
    mainText: "Hide Category",
    subText:
      "Do you really want to hide this category? You can unhide this category form settings.",
    acceptButton: "Hide",
    rejectButton: "Cancel",
  },

  unhideCategory: {
    headerSvg: "EyeLightIcon",
    mainText: "Unhide Category",
    subText: "Do you really want to unhide this category?",
    acceptButton: "Unhide",
    rejectButton: "Cancel",
  },

  deleteSubCategory: {
    headerSvg: "Trash",
    mainText: "Delete Sub-Category",
    subText:
      "Do you really want to delete this sub-category, Items under this sub-category also deleted",
    acceptButton: "Delete",
    rejectButton: "Cancel",
  },

  removeItem: {
    headerSvg: "Trash",
    mainText: "Remove",
    subText:
      "Deleting this item will remove all instances of it across the website, including Style Me and Curate Me. Are you sure you would like to delete this item?",
    acceptButton: "Remove",
    rejectButton: "Cancel",
  },

  editClosetItem: {
    headerSvg: "EditLigthIcon",
    mainText: "Edit",
    subText: "Do you really want to update this item?",
    acceptButton: "Save",
    rejectButton: "Cancel",
  },
  
  confirmAddItem: {
    headerSvg: "Cart",
    mainText: "Add New Items",
    subText: "Would you like to add new items to your closet?",
    acceptButton: "Yes",
    rejectButton: "No",
  },

  removeCuration: {
    headerSvg: "Trash",
    mainText: "Remove Curation",
    subText: "Would you like to remove selected Curation?",
    acceptButton: "Yes",
    rejectButton: "No",
  },

  clearSelected: {
    headerSvg: "Cart",
    mainText: "Clear All",
    subText: "Would you like to clear all the selected items?",
    acceptButton: "Yes",
    rejectButton: "No",
  },

  tokenExpired: {
    headerSvg: "ErrorWarning",
    mainText : "Link Expired",
    subText: "Your link has become invalid. Please visit the \"Forgot Password\" page to request a new link.",
    acceptButton: "Proceed",
    rejectButton: "Cancel",
  },
  deleteReceipts: {
    headerSvg: "ErrorWarning",
    mainText: "Delete receipts",
    subText: "Are you sure you want to delete?",
    acceptButton: "Delete",
    rejectButton: "Cancel"
  }
};
