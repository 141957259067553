import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  curation: [],
  curateMeData: [],
  styleMeCuration: [],
  flags: {},
  activeCurationLook: null,
};

export const curationSlice = createSlice({
  name: "Curation",
  initialState,
  reducers: {
    setStyleMeCuration: (state, action) => {
      state.styleMeCuration = action.payload;
    },
    setFlags: (state, action) => {
      state.flags = action.payload;
    },
    setCurateData: (state, action) => {
      state.curateMeData = action.payload;
    },
    setBulkCuration: (state, action) => {
      // console.log("action.payload here ----------------------->", action)
      state.curation = action.payload;
    },
    setCuration: (state, action) => {
      // console.log("action.payload here ----------------------->", action)
      state.curation.push(action.payload);
    },
    setActiveCurationLook: (state, action) => {
      state.activeCurationLook = action.payload;
    },
    clearCuration: (state) => {
      state.curation = [];
      state.curateMeData = [];
      // state.styleMeCuration =[];
    },
    clearStyleMeCuration: (state) => {
      state.styleMeCuration = [];
    },
    clearActiveCurationLook: (state) => {
      state.activeCurationLook = null;
    },
    deleteCuration: (state, action) => {
      // console.log("action.payload here ----------------------->", action);
      state.curation.splice(action.payload, 1);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setStyleMeCuration,
  setFlags,
  setCurateData,
  setCuration,
  setBulkCuration,
  clearCuration,
  clearStyleMeCuration,
  deleteCuration,
  setActiveCurationLook,
  clearActiveCurationLook,
} = curationSlice.actions;

export default curationSlice.reducer;
