import { useState } from "react";
import Button from "../../../components/fields/button";
import { ButtonContainter, MainText, ModalContainer, Overflow } from "../../../components/modal";
import { useStyles } from "./styles";

export default function LinkToItemPopUp({ modalResponse }) {
  const classes = useStyles();
  const [linkToItemStore, setlinkToItemStore] = useState("");
  return (
    <Overflow onClick={() => modalResponse(linkToItemStore)}>
      <ModalContainer
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{ padding: "20px", minHeight: "fit-content" }}
      >
        <div style={{ padding: "20px" }}>
          <MainText>{"Add Store Link For Item"}</MainText>
          <div className={classes.inputContainer}>
            <div className={classes.inputLabel}>{"Paste Item Store Link:"}</div>
            <div className={classes.inputWrapper}>
              <input
                className={classes.textInput}
                type="text"
                value={linkToItemStore}
                onChange={(e) => setlinkToItemStore(e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* <ButtonContainter>
          <Button
            buttontype="primary"
            size="default"
            // onClick={() => handleChoiceResult(true)}
          >
            {"Save"}
          </Button>
          <Button
            buttontype="secondary"
            size="default"
            // onClick={() => handleChoiceResult(false)}
          >
            {"Cancel"}
          </Button>
        </ButtonContainter> */}
      </ModalContainer>
    </Overflow>
  );
}
