import { useStyles } from "./styles";
import IconComponent from "../../../components/fields/icon";

export default function LeftNavBar(props) {
  const classes = useStyles();
  const { options, setOptions, setActiveOption, activeOption } = props;

  function changeOption(selectedOption) {
    options.forEach((option) => {
      option.isActive = false;
      if (option.subNavOption) {
        option.subNavOption.forEach((subOption) => {
          subOption.isActive = false;
        });
      }
    });

    // set isActive true for selected category
    options.forEach((option) => {
      if (option.name === selectedOption.name) {
        option.isActive = true;
      } else if (option.subNavOption) {
        option.subNavOption.forEach((subOption) => {
          if (subOption.name === selectedOption.name) {
            subOption.isActive = true;
            option.isActive = true;
          }
        });
      }
    });
    setOptions([...options]);
    setActiveOption(selectedOption.value);
  }

  function SubNavOptions({ list }) {
    return (
      <>
        {list.map((option, index) => (
          <div key={index}>
            <div
              key={index}
              className={`${
                (option.isActive && classes.active) ||
                (option.value === activeOption && classes.active)
              } ${classes.subMenu}`}
              onClick={() => changeOption(option)}
            >
              {option.name}
              {option.subNavOption && (
                <div className={classes.subOptionArrow}>
                  <IconComponent iconTitle={option.isActive ? "UpArrow" : "DownArrow"} />
                </div>
              )}
            </div>
            {option.subNavOption &&
              option.subNavOption.map((nestedOption, index) => (
                <div
                  className={`${option.isActive && classes.nestedActive} ${
                    nestedOption.isActive && classes.active
                  } ${classes.nestedSubOption}`}
                  key={index}
                  onClick={() => changeOption(nestedOption)}
                >
                  {nestedOption.name}
                </div>
              ))}
          </div>
        ))}
      </>
    );
  }

  return (
    <div className={classes.leftNavContainer}>
      <div className={classes.leftNavHeader}>OPTIONS</div>
      <div className={classes.subNavMenu}>
        <SubNavOptions list={options} />
      </div>
    </div>
  );
}
