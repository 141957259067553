import { makeStyles } from "@material-ui/styles";
import { mobileMediaStyle, tabMediaStyle } from "../../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  scanEmailBody: {
    width: "100%",
    padding: "40px 60px",
    boxSizing: "border-box",
    paddingBottom: "100px",
  },

  subHeader: {
    fontSize: "24px",
    fontFamily: "var(--primaryRegularFont)",
    paddingBottom: "40px",
  },

  mailDetails: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    height: "40px",
    paddingBottom: "5px",
  },

  mailDetailsSub: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    cursor: "pointer",
  },

  bottomLine: {
    boxSizing: "border-box",
    height: "2px",
    margin: "18px 0",
    borderBottom: "2px solid #c6c6c6",
  },

  avatar: {
    height: "40px",
    width: "40px",
    backgroundColor: "rgb(251, 91, 1)",
    borderRadius: "20px",
  },

  mailBody: {
    paddingLeft: "25px",
    fontFamily: "var(--primaryRegularFont)",
    fontSize: "20px",
    fontWeight: "bold",
    cursor: "pointer",
  },

  previousButton: {
    marginLeft: "20px",
    fontFamily: "var(--primaryRegularFont)",
    fontSize: "16px",
    cursor: "pointer",
    boxSizing: "border-box",
    padding: "15px",
    height: "100%",
    color: "#fb5b01",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    transition: "0.3s",
  },

  activePreviousButton: {
    "&:hover": {
      backgroundColor: "#fb5b01",
      color: "#fff",
    },
  },

  overflow: {
    position: "fixed",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    top: "0",
    left: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  modalContainer: {
    backgroundColor: "#ffffff",
    height: "550px",
    width: "550px",
    boxSizing: "border-box",
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },

  confirmAddItemContainer: {
    backgroundColor: "#ffffff",
    height: "428px",
    width: "478px",
    boxSizing: "border-box",
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },

  headerSvg: {
    background: "#f7f7f7",
    borderRadius: "40px",
    height: "80px",
    width: "80px",
    margin: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  mainText: {
    fontFamily: "var(--primaryRegularFont)",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#000000",
    marginBottom: "12px",
  },

  subText: {
    fontFamily: "var(--primaryRegularFont)",
    width: "100%",
  },

  inputTitle: {
    fontSize: "16px",
    fontWeight: "300",
    lineHeight: "20px",
    marginBottom: "6px",
  },

  inputValue: {
    width: "100%",
    height: "54px",
    fontSize: "18px",
    lineHeight: "24",
    fontWeight: "400",
    border: "1px solid #000000",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 18px",
    boxSizing: "border-box",
    marginBottom: "12px",
  },

  dateInput: {
    border: "none",
    borderBottom: "1px solid #000000",
    width: "100%",
    fontSize: "16px",
    "::-webkit-calendar-picker-indicator": { display: "none", "-webkit-appearance": "none" },
  },

  subInputValue: {
    width: "40%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  detailsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  buttonContainer: {
    display: "flex",
    columnGap: "24px",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "12px",
  },

  datePicker: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "& .MuiInputBase-input": {
      padding: "14.5px 14px !important",
    },
  },

  selectWrapper: {
    height: "54px",
    width: "100%",
    border: "1px solid #000000",
    borderRadius: "10px",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "12px",
  },

  addEmailWrapper: {
    marginBottom: "24px",
    width: "100%",
  },

  addEmailHeader: {
    width: "32%",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "10px",
  },

  addEmailLabel: {
    fontFamily: "var(--primaryRegularFont)",
    fontSize: "16px",
  },

  enabledAddButton: {
    fontFamily: "var(--primaryRegularFont)",
    fontSize: "16px",
    fontStyle: "italic",
    textDecoration: "underline",
    color: "#FB5B01",
    cursor: "pointer",
  },

  disabledAddButton: {
    fontFamily: "var(--primaryRegularFont)",
    fontSize: "16px",
    fontStyle: "italic",
    textDecoration: "underline",
    color: "#CACACA",
    cursor: "context-menu",
  },

  inputWrapper: {
    width: "32%",
  },

  ...tabMediaStyle({
    scanEmailBody: {
      padding: "30px 40px",
    },
    addEmailHeader: {
      width: "50%",
    },
    inputWrapper: {
      width: "50%",
    },
    modalContainer: {
      height: "auto",
      width: "430px",
    },
    headerSvg: {
      margin: "10px",
    },
    subInputValue: {
      width: "45%",
    },
    inputValue: {
      padding: "14px",
      "& > svg": {
        width: "14px",
        height: "14px",
      },
    },
    datePicker: {
      "& .MuiInputBase-input": {
        padding: "10px 0px 10px 10px !important",
      },
    },
  }),

  ...mobileMediaStyle({
    scanEmailBody: {
      padding: "24px 16px",
    },
    subHeader: {
      fontSize: "16px",
      paddingBottom: "24px",
    },
    mailDetails: {
      fontSize: "10px",
      alignItems: "start",
      flexDirection: "column",
    },
    bottomLine: {
      margin: "12px 0",
      borderBottom: "1px solid #c6c6c6",
    },
    avatar: {
      height: "24px",
      width: "24px",
      borderRadius: "12px",
    },
    mailBody: {
      fontSize: "14px",
      paddingLeft: "12px",
    },
    previousButton: {
      fontSize: "12px",
      marginLeft: "20px",
    },
    modalContainer: {
      height: "auto",
      width: "320px",
      padding: "18px",
    },
    confirmAddItemContainer: {
      height: "325px",
      width: "336px",
      padding: "18px",
    },
    headerSvg: {
      margin: "0",
      height: "48px",
      width: "48px",

      "& > svg": {
        height: "24px",
      },
    },
    mainText: {
      fontSize: "18px",
    },
    inputTitle: {
      fontSize: "14px",
    },
    inputValue: {
      height: "42px",
      margin: "6px 0 12px 0",
      fontSize: "14px",
      padding: "11px",

      "& > svg": {
        width: "12px",
        height: "12px",
      },
    },
    dateInput: {
      fontSize: "12px",
    },
    datePicker: {
      "& .MuiInputBase-input": {
        padding: "2px 0px 2px 10px",
        fontSize: "12px",
      },
    },
    selectWrapper: {
      height: "42px",
    },
    addEmailWrapper: {
      marginBottom: "0px",
    },
    addEmailHeader: {
      width: "100%",
    },
    addEmailLabel: {
      fontSize: "14px",
    },
    enabledAddButton: {
      fontSize: "10px",
    },
    disabledAddButton: {
      fontSize: "10px",
    },
    inputWrapper: {
      width: "100%",
    },
  }),
});
