import { makeStyles } from "@material-ui/styles";
import { mobileMediaStyle } from "../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  dropDownToggle: {
    display: "none",
    position: "relative",
  },

  headerWrapper: {
    display: "none",
    borderBottom:"0.5px solid #D9D9D9",
  },

  dropDownContainer: {
    display: "none",
    position: "relative",
  },

  ...mobileMediaStyle({
    headerWrapper: {
      display: "block",
    },

    dropDownItem: {
      fontFamily: "var(--primaryRegularFont)",
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "16px",
      padding: "18px 16px",
      cursor: "pointer",
      borderBottom: "1px solid #D9D9D9",
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
    nestedDropDownItem: {
      fontFamily: "var(--primaryRegularFont)",
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "15px",
      padding: "18px 16px 18px 36px",
      borderBottom: "1px solid #D9D9D9",
    },
    dropDownItemActive: {
      textDecorationLine: "underline",
      fontWeight: 600,
    },
    dropDownToggle: {
      display: "block",
      userSelect: "none",
    },
    dropDownContainer: {
      display: "block",
      userSelect: "none",
    },
    curationDropDown: {
      display: "block",
    },
    active: {
      fontWeight: "bold !important",
      textDecorationLine: "underline",
    },
    actionIcon: {
      "& > svg": {
        width: "16px",
        height: "16px",
      },
    },
  },"@media (max-width:600px)"),
});
