import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { useStyles } from "./styles";

import useGetWaitList from "./hooks/useGetWaitList";
import { gqlUpdateWaitList } from "../../apollo/operations/waitList";
import { openToast } from "../../components/toast";
import { ReactComponent as Arrow } from "../../../src/assets/svg/arrowRightLinear.svg";

function AdminDashboard() {
  const pageSize = 10;
  const [page, setPage] = useState(0);
  const { waitListData } = useGetWaitList({ pageSize, page });
  const [totalUserCount, setTotalUser] = useState(0);
  const [waitList, setWaitList] = useState([]);
  const [filterStatus, setFilterStatus] = useState("ALL");
  const [updateWaitListGql, { data: updateData, loading }] = useMutation(gqlUpdateWaitList);
  const classes = useStyles();

  const isAdmin = localStorage.getItem("admUser");
  const navigate = useNavigate();

  if (!isAdmin) {
    navigate("/");
  }

  useEffect(() => {
    const filterData =
      filterStatus !== "ALL"
        ? waitListData?.waitList?.filter((usr) => {
            if (usr.status === filterStatus) {
              return true;
            }
            return false;
          })
        : waitListData?.waitList;
    setWaitList(filterData);
    setTotalUser(waitListData?.count);
  }, [waitListData, filterStatus]);

  useEffect(() => {
    if (updateData?.updateWaitList?.statusCode === "200" && !loading) {
      openToast("success", "Successful!", "Updated successfully");
    }
  }, [updateData, loading]);

  const handleFilterChange = (event) => {
    setFilterStatus(event.target?.value);
  };

  const handleOffSet = (value) => {
    if (value === "back") {
      if (page !== 0) setPage(page - 1);
    } else {
      if (totalUserCount > (page + 1) * pageSize) setPage(page + 1);
    }
  };

  const handleUpdateWaitlist = (updateValue) => {
    if (updateValue?.userId) {
      updateWaitListGql({
        variables: {
          updateWaitListRes: {
            updateStatus: updateValue.updateStatus,
            userId: updateValue.userId,
          },
        },
      });
    }
    setFilterStatus(updateValue.updateStatus);
  };

  return (
    <div style={{ padding: "10px", overflowX: "auto", fontFamily: "var(--secondaryHeaderFont)" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3 style={{ padding: "10px 0px" }}>Waitlist Table</h3>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className={classes.paggination}>
            <p>{`Pagination:  ${totalUserCount ? page * pageSize + 1 : page} - ${
              (page + 1) * pageSize > totalUserCount ? totalUserCount : (page + 1) * pageSize
            } of ${totalUserCount}`}</p>
            <Arrow className={classes.arrowLeft} onClick={() => handleOffSet("back")} />
            <Arrow className={classes.arrowRight} onClick={() => handleOffSet("next")} />
          </div>
          {/* <div className={classes.statusFilter}>
            <p>Status Filter:</p>
            <select style={{ outline: "none" }} value={filterStatus} onChange={handleFilterChange}>
              <option value="ALL">ALL</option>
              <option value="APPROVED">APPROVED</option>
              <option value="DENIED">DENIED</option>
              <option value="PENDING">PENDING</option>
            </select>
          </div> */}
        </div>
      </div>
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.tdth}>SNO</th>
            <th className={classes.tdth}>ID</th>
            <th className={classes.tdth}>NAME</th>
            <th className={classes.tdth}>EMAIL</th>
            <th className={classes.tdth}>GENDER</th>
            <th className={classes.tdth}>IG</th>
            <th className={classes.tdth}>AGE</th>
            <th className={classes.tdth}>EMAIL CONFIRMED</th>
            <th className={classes.tdth}>CREATED AT</th>
            <th className={classes.tdth}>UPDATED AT</th>
            {/* <th className={classes.tdth}>STATUS</th> */}
            {/* <th className={classes.tdth}>ACTION</th> */}
          </tr>
        </thead>

        <tbody>
          {waitList?.map((user, index) => (
            <tr key={`user${index}`} className={index % 2 ? "" : classes.tr}>
              <td className={classes.tdth}>{page * pageSize + 1 + index}</td>
              <td className={classes.tdth}>{user.id}</td>
              <td className={classes.tdth}>{`${user.firstName} ${user.lastName}`}</td>
              <td className={classes.tdth}>{user.email}</td>
              <th className={classes.tdth}>{user.gender}</th>
              <th className={classes.tdth}>{user.igHandle}</th>
              <th className={classes.tdth}>{user.ageRange}</th>
              <th className={classes.tdth}>{user.isMailConfirmed ? "Confirmed" : "Pending"}</th>
              <th className={classes.tdth}>{user.createdAt}</th>
              <th className={classes.tdth}>{user.updatedAt}</th>
              {/* <td className={classes.tdth}>{user.status}</td> */}
              {/* <td
                className={classes.tdth}
                style={{
                  pointerEvents: user.status === "PENDING" ? "auto" : "none",
                  opacity: user.status === "PENDING" ? "100%" : "50%",
                }}
              >
                <button
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleUpdateWaitlist({
                      updateStatus: "APPROVED",
                      userId: user.id,
                    })
                  }
                >
                  {"APPROVE"}
                </button>
                <button
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleUpdateWaitlist({
                      updateStatus: "DENIED",
                      userId: user.id,
                    })
                  }
                >
                  {"DENIED"}
                </button>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AdminDashboard;
