import ActivewearMen from "./activewear_men.png";
import AccessoriesMen from "./accessories_men.png";
import BeautyMen from "./beauty_men.png";
import BeachwearMen from "./beachwear_men.png";
import DenimMen from "./denim_men.png";
import MiscellaneousMen from "./miscellaneous_men.png";
import OuterwearMen from "./outerwear_men.png";
import PantsMen from "./pants_men.png";
import ShoesMen from "./shoes_men.png";
import ShortsMen from "./shorts_men.png";
import ShirtsMen from "./shirts_men.png";
import SuitsMen from "./suits_men.png";
import HangerMen from "./hanger_men.png";
import HangerMenNew from "./hanger_men_new.png";

export default {
  AccessoriesMen,
  ActivewearMen,
  BeachwearMen,
  BeautyMen,
  DenimMen,
  HangerMen,
  HangerMenNew,
  MiscellaneousMen,
  OuterwearMen,
  PantsMen,
  ShirtsMen,
  ShoesMen,
  ShortsMen,
  SuitsMen,
};
