import styled from "styled-components";

import Icons from "../../assets/svg";

const Icon = styled.i`
  // z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > svg {
    ${({ height, width }) => `${height ? "height : " + height + ";" : ""}
  ${width ? "width: " + width + ";" : ""}
  `}
  }
`;

export default function IconComponent({ iconTitle, onClick, height, width, style, className }) {
  const FoundIcon = Icons[iconTitle];
  if (!FoundIcon) {
    return null;
  }
  return (
    <Icon
      className={className}
      onClick={onClick}
      height={height}
      width={width}
      style={{ ...style }}
    >
      <FoundIcon />
    </Icon>
  );
}
