import styled from "styled-components";

import { hanger } from "../../components/dashboard/constants";
import globalStyles from "../../styles/global";

const { colors, mediaQueries } = globalStyles;

export const Section = styled.section`
  position: relative;
  left: 0;
  transition: left 0.5s ease;
  height: 100dvh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.active {
    left: 290px;
    overflow-y: hidden;
  }
`;

export const OutletContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: inherit;
`;

export const DashboardParent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  // height: inherit;
`;

export const DashboardContainer = styled.div`
  width: 90%;
  max-width: 1440px;
  padding-top: 60px;

  @media screen and (${mediaQueries.tablet}) {
    width: unset;
    max-width: unset;
    padding-top: unset;
  }
`;

export const HeaderCont = styled.div`
  width: 100%;
  position: relative;
  display: flex;
`;

export const ImgCont = styled.div`
  width: 50%;
  height: inherit;
  background-image: url(${({ gender }) => hanger[gender]});
  background-position: bottom;
  background-size: cover;

  @media screen and (${mediaQueries.tablet}) {
    display: none;
  }
`;

export const HowitWorks = styled.div`
  width: 50%;
  height: 380px;
  background: ${({ gender }) =>
    gender === "female"
      ? "rgba(255, 243, 236, 0.5)"
      : gender === "male"
      ? "rgba(234, 254, 255, 0.64)"
      : gender === "neutral"
      ? "#EDFFDE"
      : "#ffffff"};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (${mediaQueries.tablet}) {
    height: 380px;
    background-image: url(${({ gender }) => hanger[gender]});
    background-position: center;
    background-size: cover;
    width: 100%;
    color: #000000;
  }

  @media screen and (${mediaQueries.mobile}) {
    height: 300px;
  }
`;

export const HowItWorkBackGround = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (${mediaQueries.tablet}) {
    background-color: rgba(0, 0, 0, 0.35);
  }
`;

export const CategoryTitle = styled.div`
  font-family: var(--primaryHeaderFont);
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  margin: 30px auto;
  color: #000000;

  @media screen and (${mediaQueries.tablet}) {
    font-size: 34px;
    line-height: 46px;
    margin: 28px auto;
  }

  @media screen and (${mediaQueries.mobile}) {
    font-size: 16px;
    line-height: 20px;
    margin: 36px auto 18px;
  }
`;

export const CategoryGrid = styled.div`
  // display: flex;
  // flex-wrap: wrap;

  ${({ hidden }) =>
    hidden
      ? `display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));`
      : `display: flex;
    flex-wrap: wrap;`}
  align-items: center;
  row-gap: 30px;
  column-gap: 4%;
  box-sizing: border-box;
  width: 100%;

  @media screen and (${mediaQueries.tablet}) {
    column-gap: 2%;
    ${({ hidden }) =>
      hidden
        ? `display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));`
        : `display: flex;
      flex-wrap: wrap;`}
  }

  @media screen and (${mediaQueries.mobile}) {
    row-gap: 18px;
    ${({ hidden }) =>
      hidden
        ? `display: grid;
      grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));`
        : `display: flex;
      flex-wrap: wrap;`}
  }
`;

export const CategoryCard = styled.div`
  display: flex;
  // width: 22%;
  ${({ hidden }) => (hidden ? "width: 100%;" : "width: 22%;")}
  // height: 340px;
  height: 22vw;
  max-height: 340px;
  background-image: url(${({ img }) => img});
  background-position: center;
  background-size: cover;
  position: relative;
  cursor: pointer;

  > div {
    height: 30%;
    width: 100%;
    background: rgba(0, 0, 0, 0.35);
    font-family: var(--primaryRegularFont);
    font-style: normal;
    font-weight: 500;
    font-size: 110%;
    line-height: 28px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.03em;
    margin-top: auto;
    margin-bottom: 40px;
    color: #fff;
    position: relative;

    > div:first-child {
      margin: auto;
      display: flex;
    }
    > div:nth-child(2) {
      right: 10px;

      > i > svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  @media screen and (${mediaQueries.tablet}) {
    // width: 32%;
    ${({ hidden }) =>
      hidden
        ? `width: 100%;
      height: 260px;`
        : `width: 32%;
      height: 24vw;
    `}

    > div {
      height: 40%;
      font-size: 110%;
      line-height: 24px;
      margin-bottom: 25px;

      > div:nth-child(2) {
        right: 10px;

        > i > svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  @media screen and (${mediaQueries.mobile}) {
    // width: 48%;
    ${({ hidden }) => (hidden ? "width: 100%;" : "width: 48%;")}
    height: 46vw;

    > div {
      height: 42px;
      font-size: 110%;
      line-height: 19px;
      margin-bottom: 20px;

      > div:nth-child(2) > i > svg {
        width: 16px;
        height: 16px;
      }
    }
  }
`;

export const CardOptions = styled.div`
  width: 220px;
  height: 104px;
  box-sizing: border-box;
  ${({ closetItem = false }) => `top: ${closetItem ? "25px;" : "68px;"}
    right: ${closetItem ? "0px;" : "15px;"}`}
  position: absolute;
  display: flex;
  background: #fafafa;
  box-shadow: 0px 4px 4px rgba(198, 198, 198, 0.25);
  font-family: var(--primaryRegularFont);
  font-weight: 300;
  padding: 15px 0px;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
  letter-spacing: 0.03em;
  color: #000000;

  > p {
    width: 100%;
    box-sizing: border-box;
    padding: 9px 18px;
    cursor: pointer;
    text-align: start;
    font-size: 16px;
    line-height: 19px;

    :hover {
      font-weight: 400;
      background-color: ${colors.blue.light};
    }
  }

  @media screen and (${mediaQueries.tablet}) {
    width: 160px;
    height: 88px;
    padding: 12px 0px;
    top: 46px;
    right: 12px;

    > p {
      font-size: 14px;
      line-height: 18px;
      padding: 7px 15px;
    }
  }

  @media screen and (${mediaQueries.mobile}) {
    top: 32px;
    right: 8px;
    width: 120px;
    height: 58px;
    padding: 5px 0px;

    > p {
      font-size: 10px;
      line-height: 14px;
      padding: 5px 12px;
    }
  }
`;

export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 60px 30px;

  @media screen and (${mediaQueries.tablet}) {
    margin: 0 26px 26px;
  }

  @media screen and (${mediaQueries.mobile}) {
    margin: 0 16px 20px;
  }
`;
