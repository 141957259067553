import styled from "styled-components";
import PropTypes from "prop-types";

import globalStyles from "../../styles/global";

const { mediaQueries } = globalStyles;

export const Section = styled.section`
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100dvh;

  @media screen and (${mediaQueries.tablet}) {
    justify-content: center;
  }
`;

export const SubSection = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
  max-width: 1000px;
  height: 75vh;
  max-height: 620px;

  &.signUpSubSection{
    height: 80vh;
    max-height: 720px;
  }

  @media screen and (${mediaQueries.tablet}) {
    width: 100%;
    height: 100vh;
    max-height: none;

    &.signUpSubSection{
      height: 100vh;
      max-height: unset;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 45%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0px 0 10px;
  // overflow: auto;

  &.signupContainer{
    padding: 0px
  }

  @media screen and (${mediaQueries.tablet}) {
    width: 100%;
    padding: 0px;
    background-image: url(${({ img }) => img});
    background-position: top;
    background-size: cover;
  }

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
  }
`;

export const BackContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--primaryRegularFont);

  @media screen and (${mediaQueries.tablet}) {
    background-color: rgba(255, 255, 255, 0.7);
  }
`;

export const SubContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;

  @media screen and (${mediaQueries.tablet}) {
    width: 80%;
    height: unset;
    min-width: 450px;
    max-width: 600px;
    padding: 30px 20px;
  }

  @media screen and (${mediaQueries.mobile}) {
    padding: 20px 15px;
    min-width: 100%;
  }
`;

export const ImgContainer = styled.div`
  width: 45%;
  height: 100%;
  box-shadow: 0 0 2px #000000;

  background-image: url(${({ img }) => img});
  background-position: center;
  background-size: cover;

  @media screen and (${mediaQueries.tablet}) {
    display: none;
  }
`;

const ImgProps = {
  img: PropTypes.any.isRequired,
};

ImgContainer.propTypes = ImgProps;
