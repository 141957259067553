import { useEffect, useState, useRef } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";

import { useNavigate } from "react-router-dom";
import ProductDetails from "./ProductDetails";
import { useStyles } from "./styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector, useDispatch } from "react-redux";

import { setisTempItemsLoaded } from "../../../../store/isTempItemsLoadedSlice";
import { setTempItems } from "../../../../store/tempItemsSlice";

import {
  getItemsFromEmail,
  getItemsFromTempTable,
  addItemToCloset,
  uploadArrayImages,
} from "../../../../apollo/operations/items";
import ModalPortal from "../../../../containers/common/modalPortal";
import Modal from "../../../../containers/common/modalPortal/modal";
import { addFutureScan } from "../../../../apollo/operations/futureScan";
import { openToast } from "../../../../components/toast";
import Loader from "../../../../components/loaderScreen";
import { Tooltip } from "react-tooltip";
import GallerySection from "./GallerySection";
import { checkSize } from "../../../../components/helpers/productHelper";
import MobileProductDetails from "./MobileProductDetails";
import { clearScannedEmail, setScannedEmail } from "../../../../store/scannedEmailSlice";
import SkeletonLoader from "./skeletonLoader";
import { setIsPreviousScan } from "../../../../store/isPreviousScanSlice";

export default function AddNewItem() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedItems, setSelectedItems] = useState(null);
  const scanPeriod = useSelector((state) => state.scanPeriod.scanPeriod);
  const scannedEmail = useSelector((state) => state.scannedEmail.scannedEmail);
  const tempItems = useSelector((state) => state.tempItems);
  const isPreviousScan = useSelector((state) => state.isPreviousScan);
  const isTempItemsLoaded = useSelector((state) => state.isTempItemsLoaded);
  const selectedItemsRef = useRef();
  selectedItemsRef.current = selectedItems;
  const dispatch = useDispatch();
  const [getItemsList] = useLazyQuery(getItemsFromEmail);
  const [itemsFromTempTable] = useLazyQuery(getItemsFromTempTable);
  const [setFutureScan, { data: setFutureScanData }] = useMutation(addFutureScan);
  const [productsList, setProductsList] = useState([]);
  const productsListRef = useRef();
  productsListRef.current = productsList;
  const [isLoading, setIsLoading] = useState(false);
  const [addItems, { data: addItemsData }] = useMutation(addItemToCloset);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [screen, setScreen] = useState(1);
  const [inputErrors, setInputErrors] = useState([]);
  const categoriesList = useSelector((state) => state.category.category);
  const [activeIndex, setActiveIndex] = useState(0);
  const [tempItemsList, settempItemsList] = useState([]);
  const [isItemsAreLoaded, setisItemsAreLoaded] = useState(false);

  const [GetUploadUrl, { data: uploadResponse }] = useLazyQuery(uploadArrayImages);
  const [arrayFileImages, setArrayFileImages] = useState([]);
  const [promiseResult, setPromiseResult] = useState(null);
  const [selectAll, setSelectAll] = useState(true);

  useEffect(
    () => () => {
      // storing filtered and processed product list to redux for freezing scan email
      if (selectedItemsRef.current !== null)
        dispatch(
          setScannedEmail({
            productsList: productsListRef.current,
            selectedItems: selectedItemsRef.current,
          }),
        );
    },
    [],
  );

  useEffect(() => {
    if (tempItemsList) {
      let productsList = [];
      let indexCounter = 1;
      tempItemsList.map((item)=>{
        if (item) {
          productsList.push({
            ...item,
            index: indexCounter++,
            itemName: item.itemName,
            datePurchased: item.datePurchased,
          });
        }
      });
      setIsLoading(false);
      if (productsList.length > 0) setProductsList(productsList);
    }
  }, [tempItemsList]);

  useEffect(() => {
    let interval;
    const fetchItems = async () => {
      const res = await itemsFromTempTable({ variables: {} });
      if(res.data.getItemsFromTempTable){
        // TODO: implement with socket.io
        // dispatch(setTempItems(res.data.getItemsFromTempTable.items));
        // dispatch(setisTempItemsLoaded(!res.data.getItemsFromTempTable.isPending));
        settempItemsList(res.data.getItemsFromTempTable.items);
        setisItemsAreLoaded(!res.data.getItemsFromTempTable.isPending);
      }
    };
    if (!isItemsAreLoaded) {
      interval = setInterval(fetchItems, 5000);
    } else {
      fetchItems().then(() => {
        console.log("Fetch items completed");
        // TODO: implement with socket.io
        // dispatch(setisTempItemsLoaded(true))
        setIsLoading(false);
        setisItemsAreLoaded(true);

      }).catch((error) => {
        console.error("Error fetching items", error);
        setIsLoading(false);
        setisItemsAreLoaded(true);
        // TODO: implement with socket.io
        // dispatch(setisTempItemsLoaded(true))
      });
      if (interval) clearInterval(interval);
    }
    // Cleanup function
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isItemsAreLoaded]);

  useEffect(() => {
    if (scannedEmail.productsList && scannedEmail.productsList.length > 0) {
      setProductsList(structuredClone(scannedEmail.productsList));
      if (scannedEmail.selectedItems && scannedEmail.selectedItems.length > 0) {
        setSelectedItems(structuredClone(scannedEmail.selectedItems));
      } else {
        setSelectedItems([]);
      }
      setIsLoading(false);
    } else if (!scanPeriod.selectedMail) {
      navigate("/addyourstyle/scanemail");
    } else {
      let toDate = new Date(scanPeriod.scanDates.toScanDate.format("YYYY/MM/DD"));
      toDate.setHours(23, 59, 59);
      toDate.toISOString();
      if(!isPreviousScan){
        // Empty initial arg for the Temp Items
        // TODO: implement with socket.io
        // dispatch(setTempItems([]))
        // dispatch(setisTempItemsLoaded(false))
        settempItemsList([])
        setisItemsAreLoaded(false)
        getItemsList({
          variables: {
            email: scanPeriod.selectedMail.email,
            to: toDate,
            from: new Date(scanPeriod.scanDates.fromScanDate.format("YYYY/MM/DD")).toISOString(),
          },
        });
        dispatch(setIsPreviousScan(true));
      } else {
        setIsLoading(true);
        setisItemsAreLoaded(true);
      }

      if (scanPeriod.futurePeriod !== "") {
        let requestObj = {
          scanEmail: scanPeriod.selectedMail.email,
          period: scanPeriod.futurePeriod,
        };
        if (scanPeriod.futurePeriod === "Custom") {
          requestObj.toDate = scanPeriod.futureScanDates.toScanDate.format("YYYY/MM/DD");
          requestObj.fromDate = scanPeriod.futureScanDates.fromScanDate.format("YYYY/MM/DD");
        }
        setFutureScan({
          variables: {
            emailList: [requestObj],
          },
        });
      }
      setSelectedItems([]);
    }
  }, [scannedEmail]);

  useEffect(() => {
    if (setFutureScanData?.addFutureScanDetails) {
      if (setFutureScanData.addFutureScanDetails.statusCode === "200") {
        openToast("success", "Successfully added", "Future scan is successfully added");
      } else {
        openToast("error", "Error", "There is an error, in adding future scan");
      }
    }
  }, [setFutureScanData]);

  useEffect(() => {
    if (addItemsData) {
      if (addItemsData.addItemToCloset.statusCode === "200") {
        setIsLoading(false);
        dispatch(clearScannedEmail());
        selectedItems.splice(0);
        productsList.splice(0);
        openToast("success", "Successfully added", "The items were added in closet.");
        navigate("/dashboard");
      } else {
        setIsLoading(false);
        openToast("error", "Failed", "Failed to add items in closet");
      }
    }
  }, [addItemsData]);

  function handleGoBack() {
    if (screen !== 1) {
      setScreen(1);
    } else {
      dispatch(clearScannedEmail());
      selectedItems?.splice(0);
      productsList?.splice(0);
      navigate("/addyourstyle/scanemail");
    }
  }

  function handleSelectItem(item, index) {
    if (screen === 2) {
      if (selectedItems.length === 1) {
        setScreen(1);
      } else if (activeIndex === selectedItems.length - 1) {
        setActiveIndex(activeIndex - 1);
      }
    }

    productsList[index].selected = !productsList[index].selected;
    setProductsList([...productsList]);

    const i = selectedItems.findIndex((ele) => ele.index === item.index);
    if (i === -1) {
      setSelectedItems([...selectedItems, item]);
    } else {
      selectedItems.splice(i, 1);
      setSelectedItems([...selectedItems]);
      if (inputErrors.length > 0) {
        inputErrors.splice(i, 1);
        setInputErrors([...inputErrors]);
      }
    }
    const allSelected = productsList.every(item => item.selected || item.isItemAdded);
    setSelectAll(!allSelected);
  }

  function handleAddButton() {
    if (selectedItems.length > 0) {
      if (screen === 1) {
        const inputErrorArr = [];
        const errorObj = {
          category: false,
        };
        selectedItems.forEach((item, index) => {
          selectedItems[index] = item;
          inputErrorArr.push({ ...errorObj });
        });
        setInputErrors(inputErrorArr);
        setSelectedItems([...selectedItems]);
        setScreen(2);
      } else {
        if (checkValidation()) {
          setShowConfirmationModal(true);
        } else {
          openToast("error", "Missing Fields", "Please fill the mandatory fields");
        }
      }
    }
  }

  function checkValidation() {
    let error = false;
    inputErrors.forEach((item, index) => {
      if (!selectedItems[index].category) {
        inputErrors[index]["category"] = true;
        if (!error) {
          setActiveIndex(index);
          error = true;
        }
      }
    });

    if (error) {
      setInputErrors(structuredClone(inputErrors));
      return false;
    }
    return true;
  }

  function addItemModalButton(value) {
    setShowConfirmationModal(false);
    if (value) {
      setIsLoading(true);
      const filterImage = selectedItems.filter((item) => typeof item.src !== "string");
      if (filterImage.length) {
        let arrayImageList = [];
        try {
          arrayImageList = filterImage.map((item) => {
            const [fileName, fileType] = item.src.name.split(".");
            return { fileName, fileType };
          });
        } catch (err) {
          setIsLoading(false);
          openToast("error", "Missing Uploaded Image", "Please Re-Select Images and Try Again");
          return;
        }
        if (arrayImageList.length) {
          GetUploadUrl({
            variables: {
              arrayFile: arrayImageList,
            },
          });
          setArrayFileImages(filterImage);
        }
      } else {
        handleAddItems();
      }
    }
  }

  useEffect(() => {
    if (uploadResponse) {
      getImageLinks();
    }
  }, [uploadResponse]);

  useEffect(() => {
    if (promiseResult?.length) {
      handleAddItems();
    }
  }, [promiseResult]);

  useEffect(() => {
      if(!selectAll)
        {
          const updatedProductsList = productsList.map((item, index) => {
            if (!item.isItemAdded) {
              return { ...item, selected: true };
            }
            return item;
          });
          setProductsList(updatedProductsList);

          const newSelectedItems = updatedProductsList.filter(item => item.selected);
          setSelectedItems(newSelectedItems);

          if (newSelectedItems.length > 0) {
            setActiveIndex(newSelectedItems.length - 1);
          }
      }else{
        const allSelected = productsList.every(item => item.selected || item.isItemAdded);
        if(allSelected){
          const updatedProductsList = productsList.map((item, index) => {
            delete item.selected
            return item
          });
          setProductsList(updatedProductsList);
          setSelectedItems([]);
        }
      }
  }, [selectAll]);

  async function getImageLinks() {
    let imageLinks = [];
    arrayFileImages.forEach((item) => {
      const [fileName, fileType] = item.src.name.split(".");
      const getImageUploadUrl = uploadResponse?.getArrayPresignedUploadURL.data?.find(
        (item) => item.fileName === fileName,
      ).url;
      imageLinks.push(imageUpload(getImageUploadUrl, item.src, fileType));
    });

    await Promise.allSettled(imageLinks).then((values) => {
      const valueList = values
        .filter((item) => item.status === "fulfilled")
        .map((item) => item.value);
      setPromiseResult(valueList);
    });
  }

  async function imageUpload(getImageUploadUrl, image, fileType) {
    try {
      const res = await fetch(getImageUploadUrl, {
        method: "PUT",
        headers: {
          "Content-Type": fileType,
        },
        body: image,
      });
      if (res.status === 200) {
        const imageUrl = res.url.split("?")[0];
        return { imageUrl: imageUrl, imageName: image.name };
      }
    } catch (error) {
      openToast("error", "Error Occured", "Something Went Wrong Tray Again Later");
      // Handle error as needed
    }
  }

  function handleAddItems() {
    if (!selectedItems.length) {
      return;
    }
    let selectedList = [];

    if (promiseResult?.length) {
      selectedList = selectedItems.map((ele) => {
        if (typeof ele.src !== "string") {
          return {
            ...ele,
            src: promiseResult.find((item) => ele.src.name?.includes(item?.imageName))?.imageUrl,
          };
        }
        return ele;
      });
    }

    const filteredSelectedItems = (selectedList.length ? selectedList : selectedItems).map(
      (ele) => ({
        subCategory: ele.subCategory?.trim() || null,
        fit: ele.fit?.trim() || null,
        material: ele.material?.trim() || null,
        pattern: ele.pattern?.trim() || null,
        details: ele.details?.trim() || null,
        design: ele.design?.trim() || null,
        tempTableId: `${ele.id}`,
        brand: ele.brand?.trim() || null,
        store: ele.store?.trim() || null,
        itemName: ele.itemName?.trim() || null,
        category: ele.category,
        color: ele.color?.trim() || null,
        size: ele.size?.trim() || "NA",
        tags: ele.tags?.trim() || null,
        src: ele.src,
        productUrl: ele.productLink || null,
      }),
    );

    if (selectedItems.length > 0) {
      addItems({
        variables: {
          addItems: {
            items: filteredSelectedItems,
          },
        },
      });
    }
    if (selectedList.length && selectedList.length < selectedItems.length) {
      openToast(
        "error",
        "Failed",
        `Failed to add ${selectedItems.length - selectedList.length} items in closet`,
      );
    }
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.header}>
        <div className={classes.title}>
          <ArrowBackIcon onClick={handleGoBack} style={{ cursor: "pointer" }} /> {"Add New Item"}
        </div>
       <div className={classes.headerRightBlock}>
       {
          screen === 1 && (
            <div className={classes.selectCheckboxContainer}>
              <input
                type="checkbox"
                checked={!selectAll}
                onChange={() => setSelectAll(!selectAll)}
                id="selectAllCheckbox"
                className={classes.selectCheckbox}
                disabled={productsList.every(item => item.isItemAdded)}
              />
              <label htmlFor="selectAllCheckbox" className={classes.selectCheckboxLabel}>
                Select all
              </label>
            </div>
          )
        }
        <div
          className={`${classes.button} ${
            (screen === 1 && selectedItems?.length > 0) || screen === 2
              ? classes.activeButton
              : classes.disableButton
          }`}
          onClick={handleAddButton}
          id={"AddButton"}
        >
          {screen === 1 ? "Next" : "Add"}
        </div>
      </div>
      </div>
      <div className={classes.itemContainer}>
        {
          (!isItemsAreLoaded || isLoading) && <Loader />
        }
        {
          productsList.length > 0 ?
           ( screen === 1 ? (
            <>
              <GallerySection
                productsList={productsList}
                setProductsList={setProductsList}
                handleSelectItem={handleSelectItem}
                isTempItemsLoaded={isItemsAreLoaded}
              />
            </>
              ) : (
                  selectedItems.length > 0 && (
                    <div className={classes.parentContainer}>
                      <MobileProductDetails
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}
                        handleSelectItem={handleSelectItem}
                        inputErrors={inputErrors}
                        setInputErrors={setInputErrors}
                        categoriesList={categoriesList}
                        activeIndex={selectedItems.length-1}
                        setActiveIndex={setActiveIndex}
                      />
                      <div className={classes.dItemContainer}>
                        {selectedItems.map((item, index) => (
                          <ProductDetails
                            key={index}
                            index={index}
                            item={item}
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                            inputErrors={inputErrors}
                            setInputErrors={setInputErrors}
                            categoriesList={categoriesList}
                            handleSelectItem={handleSelectItem}
                          />
                        ))}
                      </div>
                    </div>
                )
              )
            ): ( isItemsAreLoaded ? <div className={classes.subHeader}><p className="no-item-text">No Item Found !</p></div> : <div className={classes.imageGallery}><SkeletonLoader count={5} /></div>
          )
        }
        <Tooltip
          id="sizeInfo-tooltip"
          style={{
            backgroundColor: "#D1D1D1",
            color: "#000000",
            opacity: "1",
            fontFamily: "var(--primaryRegularFont)",
          }}
        />
      </div>
      {showConfirmationModal && (
        <ModalPortal>
          <Modal type={"confirmAddItem"} handleButtonClick={addItemModalButton} svgType="circle" />
        </ModalPortal>
      )}
    </div>
  );
}
