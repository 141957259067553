import ActivewearNeutral from "./activewear_neutral.png";
import AccessoriesNeutral from "./Accessories_Non_Binary.png";
import BeautyNeutral from "./beauty_neutral.png";
import BeachwearNeutral from "./beachwear_neutral.png";
import DenimNeutral from "../women/denim_women.png";
import DressesNeutral from "./dresses_neutral.png";
import LoungewearNeutral from "./Loungewear_Non_Binary.png";
import MiscellaneousNeutral from "./Micellaneous_Non_Binary.png";
import OuterwearNeutral from "./Outerwear_Non_Binary.png";
import PantsNeutral from "./pants_neutral.png";
import ShirtsNeutral from "../men/Shirts_MenAI.png";
import ShoesNeutral from "./Shoes_Non_Binary.png";
import ShortsNeutral from "./Shorts_Non_Binary.png";
import SkirtsNeutral from "../women/Skirts_WomenAI.png";
import SweatersNeutral from "./sweaters_neutral.png";
import TopsNeutral from "./tops_neutral.png";
import HangerNeutral from "./hanger_neutral.png";
import HomeNeutral from "./Home_Non_Binary.png";

export default {
  ActivewearNeutral,
  AccessoriesNeutral,
  BeachwearNeutral,
  BeautyNeutral,
  DenimNeutral,
  DressesNeutral,
  LoungewearNeutral,
  MiscellaneousNeutral,
  OuterwearNeutral,
  PantsNeutral,
  ShirtsNeutral,
  ShoesNeutral,
  ShortsNeutral,
  SkirtsNeutral,
  SweatersNeutral,
  TopsNeutral,
  HangerNeutral,
  HomeNeutral,
};
